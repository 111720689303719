import React, { useContext } from "react";
import "./AboutUsSection.scss";
import { Nav, Tab } from "react-bootstrap";
import Title from "../../components/Title/Title";
import AboutUsTabDetail from "../../components/AboutUsTabDetail/AboutUsTabDetail";
import
{
  AboutUsSectionDataList,
  AboutUsTabsList,
} from "../../Data/AboutUsTabsList";
import { useTranslation } from "react-i18next";
import Ads from "../../components/Ads/Ads";
import { useMediaQuery } from "react-responsive";
import { Context } from "../../context/ContextInfoProvider";
const AboutUsSection = () =>
{
  const { showBlurBackGround } = useContext(Context);
  const { t, i18n } = useTranslation("common");
  const aboutUsTabs = AboutUsTabsList;
  const aboutUsSectionsData = AboutUsSectionDataList;
  const isMediumLabScreen = useMediaQuery({ query: "(min-width: 1025px)" });
  return (
    <section
      className="about-us-section"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      id="section-1"
    >
      {showBlurBackGround && <div className="blurEffectCard" />}
      <div className="about-us-section-container">
        {isMediumLabScreen && (
          <div className="about-us-section-title">
            <Title title={t(`Pages.AboutUs.AboutUsSection.Title`)} size="big" />
          </div>
        )}
        <div className="about-us-section-list">
          <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
            <Nav variant="pills" className="flex-column about-tabs-list">
              {!isMediumLabScreen && (
                <div className="about-us-section-title">
                  <Title
                    title={t(`Pages.AboutUs.AboutUsSection.Title`)}
                    size="big"
                  />
                </div>
              )}
              {aboutUsTabs?.map((tab, index) =>
              {
                return (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={tab.id}>
                      {t(`Pages.AboutUs.AboutUsSection.Menu.${tab.title}`)}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
            <Tab.Content>
              {aboutUsSectionsData?.map((section, index) =>
              {
                return (
                  <Tab.Pane key={index} eventKey={section.id}>
                    <AboutUsTabDetail
                      key={index}
                      title={section.title}
                      desc={section.desc}
                      imgEN={section.imgEN}
                      imgAR={section.imgAR}
                    />
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Tab.Container>
          {isMediumLabScreen && (
            <div className="about-ads-cards-wrapper">
              <Ads />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
