import React, { useContext } from "react";
import "./ContactUsSection.scss";
import ContactForm from "../../components/ContactForm/ContactForm";
import ContactInfo from "../../components/ContactInfo/ContactInfo";
import { Context } from "../../context/ContextInfoProvider";
import Ads from "../../components/Ads/Ads";
import { useMediaQuery } from "react-responsive";
const ContactUsSection = () => {
  const { showBlurBackGround } = useContext(Context);
  const isSmallScreen = useMediaQuery({ maxWidth: "1024px" });

  return (
    <section className="contact-us-section">
      {showBlurBackGround && <div className="blurEffectCard" />}
      <ContactInfo />
      {!isSmallScreen ? (
        <div className="contact-us-form-wrapper">
          <ContactForm />
          {!isSmallScreen && <Ads isFooter />}
        </div>
      ) : (
        <ContactForm />
      )}
    </section>
  );
};

export default ContactUsSection;
