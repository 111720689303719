import React, { useEffect, useState } from 'react';
import './AyatLoader.scss';

/**
 * AyatLoader component displays a loading spinner.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.size] - The size of the loader (optional).
 * @returns {JSX.Element} - The rendered AyatLoader component.
 */
export const AyatLoader = ({ size }) => {
  const [containerClassName, setContainerClassName] = useState('ayat-loader-container');

  useEffect(() => {
    if (size) {
      setContainerClassName(`ayat-loader-container ayat-loader-container-${size}`);
    }
  }, [size]);

  return (
    <div className={containerClassName}>
      <img src={'/assets/loader.gif'} alt="Loader" />
    </div>
  );
};
