import React, { useContext } from "react";
import "./TermsOfUseSection.scss";
import { Nav, Tab } from "react-bootstrap";
import Title from "../../components/Title/Title";
import {
  TermsOfUseDataEn,
  TermsOfUseDataAr,
  TermsOfUsePanelTabs,
} from "../../Data/TermsOfUseData";
import MenuItem from "../../components/TermsOfUse/MenuItem/MenuItem";
import TermsOfUseContentCard from "../../components/TermsOfUse/ContentCard/TermsOfUseContentCard";
import { useState } from "react";
import { Context } from "../../context/ContextInfoProvider";
import Ads from "../../components/Ads/Ads";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
const TermsOfUseSection = () => {
  const { i18n } = useTranslation("common");
  const { showBlurBackGround } = useContext(Context);
  const [activeMenuItem, setActiveMenuItem] = useState(1);
  const isSmallScreen = useMediaQuery({ maxWidth: "850px" });
  const { t } = useTranslation("common");
  return (
    <section className="terms-of-use-section" id="section-1">
      {showBlurBackGround && <div className="blurEffectCard" />}
      <section className="terms-of-use-content-container">
        <div className="terms-of-use-content-title">
          <Title title={t(`Pages.TermsOfUse.Title`)} />
          <p>{t(`Pages.TermsOfUse.Message`)}</p>
        </div>
        <div className="terms-of-use-tabs-panel-wrapper">
          <Tab.Container id="terms-of-use-tabs-panel" defaultActiveKey={1}>
            <Nav variant="pills" className="flex-column">
              {TermsOfUsePanelTabs?.map((term, index) => {
                return (
                  <Nav.Item
                    className="terms-of-use-nav-item"
                    key={index}
                    onClick={() => setActiveMenuItem(term.id)}
                  >
                    <Nav.Link eventKey={term.id}>
                      <MenuItem
                        index={index}
                        id={term.id}
                        title={term.title}
                        activeMenuItem={activeMenuItem}
                        icon={term.icon}
                      />
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
            <Tab.Content>
              {i18n.language.slice(0, 2) === "en"
                ? TermsOfUseDataEn?.map((term, index) => {
                    return (
                      <Tab.Pane eventKey={term.id} key={index}>
                        <TermsOfUseContentCard term={term} />
                      </Tab.Pane>
                    );
                  })
                : TermsOfUseDataAr?.map((term, index) => {
                    return (
                      <Tab.Pane eventKey={term.id} key={index}>
                        <TermsOfUseContentCard term={term} />
                      </Tab.Pane>
                    );
                  })}
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
      {!isSmallScreen && (
        <div className="terms-of-use-ads-wrapper">
          <Ads  />
          
        </div>
      )}
    </section>
  );
};

export default TermsOfUseSection;
