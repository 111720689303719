import React from "react"; 
import "./AyatSectionTitle.scss"
function AyatSectionTitle({title,id}) {
  const styleDirection = localStorage.getItem("i18nextLng");
  return (
    <div id={id} className={`ayat-section-title ayat-section-title-${styleDirection.slice(0,2)} `} >
      <h2 id={id} className={`Ayat-header`}>{title}</h2>
    </div>
  );
}
export default AyatSectionTitle;
