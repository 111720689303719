import * as Constant  from './constant';
 

export const searchWebsite = async ( Name, Start, Count,callback) => {
	let queryString = '';
    
	if (Count != null) {
		queryString += 'Count=' + Count + '&';
	}
	if (Name != null) {
		queryString += 'Name=' + Name + '&';
	}
	if (Start != null) {
		queryString += 'Start=' + Start + '&';
	}
	return await fetchDataWebResponse(Constant.webSiteSearch_url, queryString, 'GET', callback);
};

const fetchDataWebResponse = async (url, queryString, method, callback, data, is_json) => {
	// document.getElementById('loader-gif').style.display = 'block';
	let header = {
		Authorization:
			localStorage.getItem('Token') != null
				? localStorage.getItem('Token')
				: sessionStorage.getItem('Token') != null
				? sessionStorage.getItem('Token')
				: null,
		'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
	};
	if (is_json) {
		header['Content-type'] = 'application/json';
	}
	return await fetch(url + '?' + queryString, {
		method: method,
		body: is_json ? JSON.stringify(data) : data,
		headers: header,
	})
		.then((res) => {
			// document.getElementById('loader-gif').style.display = 'none';
			if (res.status == 200) {
				return res.json();
			} else {
				if (res.status === 401) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.assign('/login');
				} else {
				}
			}
		})
		.then((response) => {
			if (response.Status === true) {
				callback(response);
			} else {
				callback(response);
			}
		})
		.catch((error) => {
			// document.getElementById('loader-gif').style.display = 'none';
		});
};

export const getToken = async (values, callback) => {
	return await fetch(Constant.token_url, {
		method: 'POST',
		body: JSON.stringify(values),
		headers: {
			'content-type': 'application/json',
			'X-Api-Version': Constant.api_version
		},
	})
		.then((res) => res.json())
		.then((response) => {
			callback(response);
		});
};

export const get_img = async (url, callback) => {
	if (url?.length !== 0) {
		return await fetch(Constant.baseUrl + url, {
			method: 'GET',
			headers: {
				Authorization:
					localStorage.getItem('Token') != null ?
						localStorage.getItem('Token') : null
			},
			responseType: 'arraybuffer',
		})
			.then((res) => res.arrayBuffer())
			.then((response) => {
				if (response.byteLength == 81) {
					callback(false);
				} else {
					const base64 = btoa(
						new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), '')
					);
					callback(base64);
				}
			});
	}
};