import React, { useEffect, useState } from "react";
import "./SearchCard.scss";
import { get_img } from "../../Shared/Services/api";
import { AyatLoader } from "../AyatLoader/AyatLoader";
import MoreButton from "../MoreButton/MoreButton";
import { useTranslation } from "react-i18next";
import { handleGoToDomeCare } from "../../functions/functions";

const SearchCard = ({ title, specialty, src, resSrc }) => {
  const { i18n } = useTranslation("common");
  const [imgState, setImgState] = useState([]);
  const [isLoadedImgState, setIsLoadedImgState] = useState(false);
  useEffect(() => {
    get_img(resSrc, function (data) {
      if (data) {
        setImgState("data:;base64," + data);
        setIsLoadedImgState(true);
      } else {
        setImgState(null);
      }
    });
  }, [resSrc]);

  return (
    <div className={`search-card ${i18n.language.slice(0, 2)}`}>
      {!isLoadedImgState && <AyatLoader />}
      {isLoadedImgState && (
        <img className="doctor-avatar" src={imgState || src} alt={title} />
      )}
      <div className="text">
        <h4>{title}</h4>
        <p>{specialty}</p>
      </div>
      <div onClick={handleGoToDomeCare}>
        <MoreButton />
      </div>
    </div>
  );
};

export default SearchCard;
