import React, { useState, useContext } from "react";
import "./LearnMoreArticles.scss";
import Title from "../../components/Title/Title";
import LearnMoreArticleHeadLineCard from "../../components/LearnMoreArticleHeadLineCard/LearnMoreArticleHeadLineCard";
import LearnMoreArticleContentCard from "../../components/LearnMoreArticleContentCard/LearnMoreArticleContentCard";
import { Articles } from "../../Data/Articles";
import { useTranslation } from "react-i18next";
import { Context } from "../../context/ContextInfoProvider";
import { useEffect } from "react";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import "swiper/css";
import Ads from "../../components/Ads/Ads";
import Pagination from "../../components/Pagination/Pagination";
const LearnMoreArticles = () =>
{
  const { showBlurBackGround, scrollableFlag } = useContext(Context);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 992px)" });
  const isTabletScreen = useMediaQuery({ query: "(max-width: 750px)" });
  const ArticleCardsDivRef = useRef(null);
  const { chosenArticle, setChosenArticle } = useContext(Context);
  const { t, i18n } = useTranslation("common");
  const [ readMode, setReadMode ] = useState(false);
  const [ articleId, setArticleId ] = useState(null);
  const [ currentPage, setCurrentPage ] = useState(1);
  const cardsPerPageReadMode = 3;

  let cardsPerPage = 6;
  if (isSmallScreen) {
    cardsPerPage = 4;
  }
  const handleClick = (article) =>
  {
    setArticleId(article.id);
    setChosenArticle(null);
    setReadMode(true);
  };

  const [ scrollPosition, setScrollPosition ] = useState(0);
  const cardsContainerRef = useRef(null);
  const handleScroll = () =>
  {
    if (cardsContainerRef.current) {
      setScrollPosition(cardsContainerRef.current.scrollLeft);
    }
  };

  useEffect(() =>
  {
    if (chosenArticle) {
      setReadMode(true);
      setArticleId(chosenArticle.index);
    }
  }, [ setChosenArticle ]);

  const handleArticlesCardsDivWheel = (e) =>
  {
    const div = ArticleCardsDivRef.current;
    const delta = e.deltaY;
    const scrollSpeed = 2;
    div.scrollBy({
      top: delta * scrollSpeed,
      behavior: "smooth",
    });
  };

  const handlePageChange = (page) =>
  {
    setCurrentPage(page);
  };

  useEffect(() =>
  {
    const cardsContainer = cardsContainerRef.current;

    if (cardsContainer) {
      cardsContainer.addEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() =>
  {
    const cardsContainer = cardsContainerRef.current;

    if (cardsContainer) {
      const maxScrollLeft =
        cardsContainer.scrollWidth - cardsContainer.clientWidth;

      const updateArrows = () =>
      {
        if (scrollPosition === 0) {
          leftArrow?.classList?.add("disabled");
        } else {
          leftArrow?.classList?.remove("disabled");
        }

        if (scrollPosition >= maxScrollLeft) {
          rightArrow?.classList?.add("disabled");
        } else {
          rightArrow?.classList?.remove("disabled");
        }
      };

      const leftArrow = document.querySelector(".left-arrow");
      const rightArrow = document.querySelector(".right-arrow");

      updateArrows();

      const observer = new MutationObserver(updateArrows);
      observer.observe(cardsContainer, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      return () =>
      {
        observer.disconnect();
      };
    }
  }, [ scrollPosition ]);

  //?============================================================================================================
  //?============================================================================================================
  // const [contentScrollPosition, setContentScrollPosition] = useState(0);
  // const [contentScrollHeight, setContentScrollHeight] = useState(0);
  // const [contentClientHeight, setContentClientHeight] = useState(0);
  // const [ setShowHideOverflowImage] = useState(false);
  const handleContentScroll = (event) =>
  {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    // setContentScrollPosition(scrollTop);
    // setContentScrollHeight(scrollHeight);
    // setContentClientHeight(clientHeight);
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      // setShowHideOverflowImage(true);
    } else {
      // setShowHideOverflowImage(false);
    }
  };
  //?============================================================================================================
  //?============================================================================================================

  //?================================Scroll Buttons Behavior====================================================

  // const isSmallScreen = useMediaQuery({ query: '(max-width: 1200px)' });
  const [ activeArrowNext, setActiveArrowNext ] = useState(true);
  const [ activeArrowPrev, setActiveArrowPrev ] = useState(false);
  const ServicesCardsContainerRef = useRef(null);

  const currentLanguage =
    localStorage.getItem("i18nextLng"
    );
  const handleScrollPrev = () =>
  {
    setActiveArrowNext(true);
    const divElement = ServicesCardsContainerRef.current;
    if (divElement) {
      divElement.scroll({
        behavior: "smooth",
      });
      const scrollPosition = divElement.scrollLeft;
      currentLanguage === "en"
        ? (divElement.scrollLeft -= 200)
        : (divElement.scrollLeft += 200);
      if (scrollPosition - 200 <= 0) {
        setActiveArrowPrev(false);
      }
    }
  };

  const handleScrollNext = () =>
  {
    setActiveArrowPrev(true);
    const divElement = ServicesCardsContainerRef.current;
    if (divElement) {
      divElement.scroll({
        behavior: "smooth",
      });
      const scrollPosition = Math.abs(divElement.scrollLeft);
      const totalScrollableWidth =
        divElement.scrollWidth - divElement.clientWidth;
      currentLanguage === "en"
        ? (divElement.scrollLeft += 200)
        : (divElement.scrollLeft -= 200);
      if (scrollPosition + 200 >= totalScrollableWidth) {
        setActiveArrowNext(false);
      }
    }
  };

  const handleCardsScrolling = () =>
  {
    const divElement = ServicesCardsContainerRef.current;
    if (divElement) {
      divElement.scroll({
        behavior: "smooth",
      });
      const scrollPosition = Math.abs(divElement.scrollLeft);
      const totalScrollableWidth =
        divElement.scrollWidth - divElement.clientWidth;
      if (scrollPosition === 0) {
        setActiveArrowPrev(false);
        setActiveArrowNext(true);
      } else if (scrollPosition + 1 === totalScrollableWidth) {
        setActiveArrowNext(false);
        setActiveArrowPrev(true);
      } else {
        setActiveArrowNext(true);
        setActiveArrowPrev(true);
      }
    }
  };

  useEffect(() =>
  {
    const divElement = ServicesCardsContainerRef.current;
    if (divElement) {
      setActiveArrowNext(divElement.scrollWidth > divElement.clientWidth);
    }
  }, []);

  //?============================================================================================================
  //?============================================================================================================

  return (
    <section
      className={"learn-more-articles-section"}
      id="section-1"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <div
        className={`learn-more-articles-wrapper ${scrollableFlag && "scrollable"
          }`}
      >
        {showBlurBackGround && <div className="blurEffectCard" />}
        {!readMode && (
          <div className="learn-more-articles-section-title">
            <Title
              size="big"
              title={t(`Pages.LearnMore.LearnMoreArticles.Title`)}
            />
            <Pagination
              currentPage={currentPage}
              articles={Articles}
              cardsPerPage={cardsPerPage}
              handlePageChange={handlePageChange}
              readMode={readMode}
            />
          </div>
        )}
        {readMode && isTabletScreen && (
          <div className="learn-more-articles-section-title mb-3">
            <Title
              size="big"
              title={t(`Pages.LearnMore.LearnMoreArticles.Title`)}
            />
          </div>
        )}
        {readMode === true && chosenArticle !== null ? (
          // ? ===============================================open card from the home page=================================================
          <div className="readMode-container">
            {isSmallScreen === true ? (
              <div
                className={`learn-more-articles-section-cards-container ho flex-column ${scrollableFlag && "scrollable"
                  }`}
              >
                <div className="title-with-pagination">
                  <h2 className="centered-title ">
                    {t("Pages.LearnMore.LearnMoreArticles.other")}
                  </h2>

                  {isTabletScreen && (
                    <div className="pagination-arrows">
                      <div
                        className={`pagination-arrow-left arrow left-arrow ${activeArrowPrev === false && "disabled"
                          }`}
                        onClick={() => handleScrollPrev()}
                      >
                        {activeArrowPrev ? (
                          <img src="/assets/ArrowLeft.svg" alt="ToRightArrow" />
                        ) : (
                          <img
                            src="/assets/ArrowunactiveLeft.svg"
                            alt="ToRightArrow"
                          />
                        )}
                      </div>
                      <div
                        className={`pagination-arrow-right learn arrow right-arrow ${activeArrowNext === false && "disabled"
                          }`}
                        onClick={() => handleScrollNext()}
                      >
                        {activeArrowNext ? (
                          <img
                            src="/assets/ArrowRight-1.svg"
                            alt="ToRightArrow"
                          />
                        ) : (
                          <img
                            src="/assets/ArrowunactiveRight.svg"
                            alt="ToRightArrow"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  ref={ServicesCardsContainerRef}
                  onScroll={handleCardsScrolling}
                  className="horizontal-scrolling"
                >
                  {Articles.map((article, index) =>
                  {
                    return (
                      <span key={index} onClick={() => handleClick(article)}>
                        <LearnMoreArticleHeadLineCard
                          key={article?.id}
                          descAR={article?.descAR}
                          descEN={article?.descEN}
                          index={index + (currentPage - 1) * cardsPerPage}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div
                className={`learn-more-articles-section-cards-container hihi ${scrollableFlag && "scrollable"
                  }`}
                onWheel={handleArticlesCardsDivWheel}
                ref={ArticleCardsDivRef}
              >
                {readMode && !isTabletScreen && (
                  <div className="learn-more-articles-section-title">
                    <Title
                      size="big"
                      title={t(`Pages.LearnMore.LearnMoreArticles.Title`)}
                    />
                    <Pagination
                      currentPage={currentPage}
                      articles={Articles}
                      cardsPerPage={cardsPerPageReadMode}
                      handlePageChange={handlePageChange}
                      readMode={readMode}
                    />
                  </div>
                )}

                <div
                  className="scrolling-content hihi "
                  onScroll={handleContentScroll}
                >
                  {Articles.slice(
                    (currentPage - 1) * cardsPerPageReadMode,
                    currentPage * cardsPerPageReadMode
                  ).map((article, index) =>
                  {
                    return (
                      <div
                        className={`learn-more-article-card-wrapper ${scrollableFlag && "scrollable"
                          }`}
                        onClick={() => handleClick(article)}
                      >
                        <LearnMoreArticleHeadLineCard
                          key={article.id}
                          index={
                            index + (currentPage - 1) * cardsPerPageReadMode
                          }
                          readMode={readMode}
                          isMobileScreen={isTabletScreen}
                          descAR={article?.descAR}
                          descEN={article?.descEN}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="learn-more-article-content-cards-container hihi">
              {Articles.map((article, index) =>
              {
                return (
                  chosenArticle.index === article.id && (
                    <>
                      <LearnMoreArticleContentCard
                        key={article.id}
                        descAR={article?.descAR}
                        descEN={article?.descEN}
                        index={index}
                        imgAR={article.imgAR}
                        imgEN={article.imgEN}
                      />
                    </>
                  )
                );
              })}
            </div>
          </div>
        ) : readMode === true && chosenArticle === null ? (
          // ? ===============================================open card from the same page=================================================

          <div className="readMode-container">
            {isTabletScreen === true ? (
              <div
                className={`learn-more-articles-section-cards-container ho flex-column ${scrollableFlag && "scrollable"
                  }`}
                style={{
                  width: "100vw",
                }}
              >
                <div className="title-with-pagination">
                  <h2 className="centered-title">
                    {t("Pages.LearnMore.LearnMoreArticles.other")}
                  </h2>
                  {isTabletScreen && (
                    <div className="pagination-arrows">
                      <div
                        className={`pagination-arrow-left arrow left-arrow ${activeArrowPrev === false && "disabled"
                          }`}
                        onClick={() => handleScrollPrev()}
                      >
                        {activeArrowPrev ? (
                          <img src="/assets/ArrowLeft.svg" alt="ToRightArrow" />
                        ) : (
                          <img
                            src="/assets/ArrowunactiveLeft.svg"
                            alt="ToRightArrow"
                          />
                        )}
                      </div>
                      <div
                        className={`pagination-arrow-right learn arrow right-arrow ${activeArrowNext === false && "disabled"
                          }`}
                        onClick={() => handleScrollNext()}
                      >
                        {activeArrowNext ? (
                          <img
                            src="/assets/ArrowRight-1.svg"
                            alt="ToRightArrow"
                          />
                        ) : (
                          <img
                            src="/assets/ArrowunactiveRight.svg"
                            alt="ToRightArrow"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  ref={ServicesCardsContainerRef}
                  onScroll={handleCardsScrolling}
                  className="horizontal-scrolling"
                >
                  {Articles?.map((article, index) =>
                  {
                    return (
                      <div
                        className={`learn-more-article-card-wrapper ${scrollableFlag && "scrollable"
                          }`}
                        onClick={() => handleClick(article)}
                      >
                        <LearnMoreArticleHeadLineCard
                          key={article.id}
                          index={
                            index + (currentPage - 1) * cardsPerPageReadMode
                          }
                          readMode={readMode}
                          isMobileScreen={isTabletScreen}
                          descAR={article?.descAR}
                          descEN={article?.descEN}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div
                className={`learn-more-articles-section-cards-container hihi ${scrollableFlag && "scrollable"
                  }`}
                onWheel={handleArticlesCardsDivWheel}
                ref={ArticleCardsDivRef}
              >
                {readMode && !isTabletScreen && (
                  <div className="learn-more-articles-section-title">
                    <Title
                      size="big"
                      title={t(`Pages.LearnMore.LearnMoreArticles.Title`)}
                    />
                    <Pagination
                      currentPage={currentPage}
                      articles={Articles}
                      cardsPerPage={cardsPerPageReadMode}
                      handlePageChange={handlePageChange}
                      readMode={readMode}
                    />
                  </div>
                )}

                <div
                  className="scrolling-content hoho"
                  onScroll={handleContentScroll}
                >
                  {Articles.slice(
                    (currentPage - 1) * cardsPerPageReadMode,
                    currentPage * cardsPerPageReadMode
                  ).map((article, index) =>
                  {
                    return (
                      <div
                        className={`learn-more-article-card-wrapper ${scrollableFlag && "scrollable"
                          }`}
                        onClick={() => handleClick(article)}
                      >
                        <LearnMoreArticleHeadLineCard
                          key={article.id}
                          index={
                            index + (currentPage - 1) * cardsPerPageReadMode
                          }
                          readMode={readMode}
                          isMobileScreen={isTabletScreen}
                          descAR={article?.descAR}
                          descEN={article?.descEN}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="learn-more-article-content-cards-container hihi">
              {Articles.map((article, index) =>
              {
                return (
                  articleId === article.id && (
                    <>
                      <LearnMoreArticleContentCard
                        key={article.id}
                        descAR={article?.descAR}
                        descEN={article?.descEN}
                        index={index}
                        imgAR={article.imgAR}
                        imgEN={article.imgEN}
                      />
                    </>
                  )
                );
              })}
            </div>
          </div>
        ) : (
          // ? ===============================================Normal Mode=================================================
          <div
            className={`learn-more-articles-section-cards-container hi ${scrollableFlag && "scrollable"
              }`}
          >
            {Articles.slice(
              (currentPage - 1) * cardsPerPage,
              currentPage * cardsPerPage
            ).map((article, index) =>
            {
              return (
                <div
                  className={`learn-more-article-card-wrapper ${scrollableFlag && "scrollable"
                    }`}
                  onClick={() => handleClick(article)}
                >
                  <LearnMoreArticleHeadLineCard
                    key={article.id}
                    descAR={article?.descAR}
                    descEN={article?.descEN}
                    index={index + (currentPage - 1) * cardsPerPage}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      {readMode !== true && !isSmallScreen && (
        <div className="learn-more-ads-warpper">
     
          <Ads/>
        </div>
      )}
    </section>
  );
};

export default LearnMoreArticles;
