import React from "react";
import "./LearnMore.scss";
import LearnMoreArticles from "../../sections/LearnMoreArticles/LearnMoreArticles";
import LayoutFooter from "../../layout/LayoutFooter/LayoutFooter";
import Scroller from "../../components/Scroller/Scroller";

const LearnMore = () => {
  return (
    <section className="learn-more-section">
      <Scroller />
      <LearnMoreArticles />
      <LayoutFooter />
    </section>
  );
};

export default LearnMore;
