import React from "react";
import "./AboutUs.scss";
// import { FullPage, Slide } from "react-full-page";
import AboutUsSection from "../../sections/AboutUsSection/AboutUsSection";
import LayoutFooter from "../../layout/LayoutFooter/LayoutFooter";
import Scroller from "../../components/Scroller/Scroller";
// import Scroller from "../../components/Scroller/Scroller";

const AboutUs = () => {
  return (
    <section className="about-us">
      <Scroller />
      <AboutUsSection />
      <LayoutFooter />
    </section>
  );
};

export default AboutUs;
