import React from "react";
import "./LanguageSelect.scss";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { useMediaQuery } from "react-responsive";
const LanguageSelect = ({ Languages }) => {
  const isMobile = useMediaQuery({ maxWidth: "875px" });
  const { i18n } = useTranslation("common");
  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
      backgroundColor: "transparent",
      borderColor: "none",
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: "none",
    }),
  };
  const options = Languages?.map((language) => ({
    value: language.code,
    label: (
      <div
        key={language.id}
        className="d-flex align-items-center justify-content-center w-100"
        style={{
          gap: "8px",
        }}
      >
        <img
        className="language-img"
          src={"assets/flag0.webp"}
          alt="language-img"
          width={isMobile ? 24 : 30}
          height={isMobile ? 24 : 30}
        />
        <span className="select-text">
          {isMobile
            ? i18n.language === "ar"
              ? language.nameAr
              : language.nameEn.slice(0, 2)
            : i18n.language === "en"
            ? language.nameEn
            : language.nameAr}
        </span>
      </div>
    ),
  }));
  const changeLanguage = (event) => {
    const selectedLanguage = event.value;
    i18n.changeLanguage(selectedLanguage, (err) => {
      if (err)
        return console.error(
          "Something went wrong loading the translation:",
          err
        );
    });
    window.location.reload();
  };
  return (
    <ReactSelect
      placeholder="language"
      styles={customStyles}
      options={options}
      aria-labelledby="language"
      aria-label="language"
      className={`select-container ${i18n.language.slice(0, 2)}`}
      isSearchable={false}
      onChange={(e) => changeLanguage(e)}
      defaultValue={options?.find((item) => {
        return item.value === i18n.language.slice(0, 2);
      })}
    />
  );
};

export default LanguageSelect;
