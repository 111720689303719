import React, { useContext, useEffect, useRef, useState } from "react";
import "./SearchResult.scss";
import { Nav, Tab } from "react-bootstrap";
import Title from "../../components/Title/Title";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Context } from "../../context/ContextInfoProvider";
import { useLocation } from "react-router-dom";
import SearchCard from "../../components/SearchCard/SearchCard";
import Pagination from "../../components/Pagination/Pagination";

const SearchResult = () =>
{
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { showBlurBackGround } = useContext(Context);
  const ArticlesContentCardRef = useRef(null);
  const tab = searchParams.get("tab");
  const activeTab = tab.split("/")[ 0 ];
  const searchInput = tab.split("/")[ 1 ];
  const [ searchResult ] = useState({});
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ startCount, setStartCount ] = useState(0);
  let cardsCount = 6;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  if (isSmallScreen) cardsCount = 4;
  useEffect(() =>
  {
   
  }, [ cardsCount, searchInput, startCount ]);

  const handlePageChange = (page) =>
  {
    setStartCount((page - 1) * cardsCount);
    setCurrentPage(page);
  };

  const { t, i18n } = useTranslation("common");

  console.log("searchResult?.Accounts", searchResult?.Accounts);
  return (
    <section
      className="about-us-section"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      id="section-1"
    >
      {showBlurBackGround && <div className="blurEffectCard" />}
      <div className="about-us-section-container">
        {isSmallScreen && (
          <div className="about-us-section-title">
            <Title title={t(`filterResults`)} size="big" />
          </div>
        )}
        <div className="about-us-section-list">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={
              activeTab === "facilities" ? "facilities" : "profiles"
            }
          >
            <Nav variant="pills" className="flex-column about-tabs-list">
              {!isSmallScreen && (
                <div className="about-us-section-title">
                  <Title title={t(`filterResults`)} size="big" />
                </div>
              )}

              <Nav.Item className="">
                {searchResult?.Accounts?.length > 0 && (
                  <Nav.Link eventKey="profiles">{t("Profile")}</Nav.Link>
                )}
              </Nav.Item>
              <Nav.Item>
                {searchResult?.Facilities?.length > 0 && (
                  <Nav.Link eventKey="facilities">{t("Facilities")}</Nav.Link>
                )}
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="profiles">
                {searchResult?.AccountsTotalCount && (
                  <Pagination
                    currentPage={currentPage}
                    cardsPerPage={cardsCount}
                    handlePageChange={handlePageChange}
                    articlesLength={searchResult?.AccountsTotalCount}
                  />
                )}
                {searchResult?.Accounts?.length > 0 && (
                  <div className="search-card-container">
                    {searchResult?.Accounts?.map((result) => (
                      <SearchCard
                        key={result?.Id}
                        title={result?.Name}
                        specialty={result?.Description}
                        resSrc={result?.Image}
                        src={"/assets/doctor.png"}
                      />
                    ))}
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="facilities">
                <Pagination
                  currentPage={currentPage}
                  cardsPerPage={cardsCount}
                  handlePageChange={handlePageChange}
                  articlesLength={searchResult?.FacilitiesTotalCount}
                />
                <div
                  className="search-card-container"
                  ref={ArticlesContentCardRef}
                >
                  {searchResult?.Facilities?.map((result) => (
                    <SearchCard
                      key={result?.Id}
                      title={result?.Name}
                      specialty={result?.Description}
                      resSrc={result?.Image}
                      src={"/assets/Institutions.png"}
                    />
                  ))}{" "}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </section>
  );
};

export default SearchResult;
