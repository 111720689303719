import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18nextCache from "i18next-localstorage-cache";
// import translationEn from '../public/locales/en/common.json'
// import translationAr from '../public/locales/ar/common.json'
// const resources = {
//   en: {
//     translation: translationEn,
//   },
//   ar: {
//     translation: translationAr,
//   },
// };
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(i18nextCache)
  .use(initReactI18next)
  .init({
    fallbackLng: "en", // Fallback language
    debug: false, // Enable debug mode for development

    interpolation: {
      escapeValue: false, // React already escapes values
    },

    backend: {
      loadPath: "./locales/{{lng}}/{{ns}}.json", // Path to translations files
    },
    ns:"locales",
    cache: {
      enabled: true, // Enable caching
      prefix: "i18next_res_", // Cache key prefix
      expirationTime: 7 * 24 * 60 * 60 * 1000, // Cache expiration time (7 days)
    },
  });

export default i18n;
