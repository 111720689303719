// //Local
// export const baseUrl = "/api/";
// export const serverUrl = "http://10.10.100.5:9090/";
// export const socketUrl = "/chatHub";
// export const privacyPolicyUrl = "http://10.10.100.5:9090";
// export const pushNotifications = "/notificationHub";

// Go Dady:
// export const baseUrl = "/api/";
// export const serverUrl = "https://api.dome-care.com/";
// export const socketUrl = "https://api.dome-care.com/chatHub";
// export const privacyPolicyUrl = "https://api.dome-care.com";
// export const pushNotifications = "https://api.dome-care.com/notificationHub";


// Go Dady: Test
// export const baseUrl = "/api/";
// export const serverUrl = "http://92.205.111.2:8081/";
// export const socketUrl = "http://92.205.111.2:8081/hatHub";
// export const privacyPolicyUrl = "http://92.205.111.2:8081/";
// export const pushNotifications = "http://92.205.111.2:8081/notificationHub";

//Hayea:
export const baseUrl = "https://api.domecare-syria.com/api/";
export const serverUrl = "https://api.domecare-syria.com/";
export const socketUrl = "/chatHub";
export const privacyPolicyUrl = "https://api.domecare-UAE.com";
export const pushNotifications = "/notificationHub";


export const webSiteSearch_url = baseUrl + "Search/webSiteSerach";
export const token_url = baseUrl + "Accounts/Guest";
export const api_version = '2.1.1';
export const appStoreApplicationLink = "https://apps.apple.com/in/app/domecare/id1621264599";
export const googlePlayApplicationLink = "https://play.google.com/store/apps/details?id=com.ayatapps.domecaree";
export const webApplicationLink = "https://web.domecare-syria.com/";
export const reCaptchaSiteKey = '6LfLNhEpAAAAAAAkXZFZWM8fb-IBz-ns1ApAEZ9l';
export const websiteSearch = true;

