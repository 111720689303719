import React from 'react';
import './ButtonWithArrow.scss';
import { useTranslation } from 'react-i18next';
const ButtonWithArrow = () => {
	const { t, i18n } = useTranslation('common');
	return (
		<button className='service-card-img-btn'>
			<span>{t(`Pages.Home.BriefAbout.RequestButton`)}</span>

			<img
				className={i18n.language === 'ar' && 'flip-image'}
				src={'assets/hemeleftarrow.svg'}
				alt='ToRightArrow'
			/>
		</button>
	);
};

export default ButtonWithArrow;
