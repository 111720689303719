import React from "react";
import "./LayoutFooter.scss";
import ContactUs from "../../pages/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";
const LayoutFooter = () => {
  const location = useLocation();
  return (
    <section
      className="layout-footer"
      id={`${location.pathname === "/" ? "section-3" : "section-2"}`}
    >
      <ContactUs />
      <Footer />
    </section>
  );
};

export default LayoutFooter;
