export const AccountTypeListData = [
  {
    Id: 1,
    icon: "assets/NormalAccount1.svg",
    iconWhite: "assets/NormalAccount.svg",
    title: "NormalAccount",
    desc: "NormalAccount",
    footerIcon: "assets/ArrowRight.svg",
  },
  {
    Id: 2,
    icon: "assets/BusinessAccount1.svg",
    iconWhite: "assets/BusinessAccount.svg",
    title: "BusinessAccount",
    desc: "BusinessAccount",
    footerIcon: "assets/ArrowRight.svg",
  },
  {
    Id: 3,
    icon: "assets/FacilitiesAccount1.svg",
    iconWhite: "assets/FacilitiesAccount.svg",
    title: "FacilitesAccount",
    desc: "FacilitesAccount",
    footerIcon: "assets/ArrowRight.svg",
  },
];
