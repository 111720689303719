import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";
import HomeArticleHeadLineCard from "../../components/HomeArticleHeadLineCard/HomeArticleHeadLineCard";
import "./VerticalSwiper.scss";
import { Articles } from "../../Data/Articles";
import { Context } from "../../context/ContextInfoProvider";
SwiperCore.use([Navigation, Pagination, EffectCoverflow]);
const VerticalSwiper = () => {
  const { setVerticalSwiperContainerHeight } = useContext(Context);
  return (
    <Swiper
      initialSlide={0}
      direction={"vertical"}
      navigation
      effect="coverflow"
      coverflowEffect={{
        rotate: -5,
        stretch: 100,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }}
      slidesPerView={"auto"}
      centeredSlides
      slideToClickedSlide
      loop={false}
      onReachEnd={() => setVerticalSwiperContainerHeight(true)}
      onSliderMove={() => setVerticalSwiperContainerHeight(false)}
    >
      {Articles.map((article, index) => {
        return (
          <SwiperSlide>
            <HomeArticleHeadLineCard
              key={index}
              descAR={article?.descAR}
              descEN={article?.descEN}
              index={index + 1}
              imgAR={article.imgAR}
              imgEN={article.imgEN}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default VerticalSwiper;
