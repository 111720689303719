import React, { useEffect, useRef, useState } from 'react';
import './Search.scss';
import { useTranslation } from 'react-i18next';
import AyatSectionTitle from '../AyatSectionTitle/AyatSectionTitle';
import { Link } from 'react-router-dom';
import SearchCard from '../SearchCard/SearchCard';
import { useMediaQuery } from 'react-responsive';
import { searchWebsite } from '../../Shared/Services/api';
import { AyatLoader } from '../AyatLoader/AyatLoader';
import * as Constant from '../../Shared/Services/constant';

const Search = () =>
{
	const { t, i18n } = useTranslation('common');
	const [ isPopupVisible, setIsPopupVisible ] = useState(false);
	const searchInputRef = useRef(null);
	const popupRef = useRef(null);
	const [ searchResult, setSearchResult ] = useState(null);
	const [ isLoadedSearchResult, setIsLoadedSearchResult ] = useState(false);

	const handleOutsideClick = (e) =>
	{
		if (popupRef.current && !popupRef.current.contains(e.target) && searchInputRef.current !== e.target) {
			setIsPopupVisible(false);
		}
	};

	useEffect(() =>
	{
		document.addEventListener('mousedown', handleOutsideClick);
		return () =>
		{
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const handleKeyPress = (e) =>
	{
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};
	const handleSearchChange = (e) =>
	{
		if (e.target.value !== "" && Constant.websiteSearch) {
			handleSearch()
			setIsPopupVisible(true)
		}
		else setIsPopupVisible(false)
	};

	const isMediumLabScreen = useMediaQuery({ query: '(max-width: 768px)' });

	const handleSearch = async () =>
	{
		try {

			let cardsCount = 3;
			if (isMediumLabScreen) cardsCount = 2;
			await searchWebsite(searchInputRef?.current?.value, 0, cardsCount, function (response)
			{
				setSearchResult(response?.Data)
				setIsLoadedSearchResult(true);
			});
			!Constant.websiteSearch && setSearchResult(null)
			setIsLoadedSearchResult(true);
		}
		catch (errr) {
			console.log('errr', errr)
		}
	}

	return (
		<div className='search-container' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
			<form autoComplete='off'>
				<img src='assets/Searc.svg' alt='SearchImg' width={'auto'} height={'auto'} />
				<input
					autoComplete='off'
					type='search'
					ref={searchInputRef}
					id='site-search'
					name='q'
					placeholder={!isMediumLabScreen ? t(`SearchDoctorOrFacilities`) : t(`Layout.Layout.Header.Search`)}
					onChange={handleSearchChange}
					onKeyDown={handleKeyPress}
				/>
			</form>

			{isPopupVisible && (
				<div className='search-menu' ref={popupRef}>
					<div className='header-container'>
						<AyatSectionTitle title={t('Profile')} />
						{searchResult?.Accounts.length !== 0 &&
							<Link
								onClick={() => setIsPopupVisible(false)}
								className={'r-p primary'}
								to={`/search-result?tab=profiles/${searchInputRef?.current?.value}`}>
								{t(`SeeAll`)}
							</Link>
						}
					</div>
					{!isLoadedSearchResult && <AyatLoader />}
					{isLoadedSearchResult && searchResult?.Accounts?.map((result) => (
						<SearchCard key={result?.Id} title={result?.Name} specialty={result?.Description} resSrc={result?.Image} src={'/assets/doctor.png'} />
					))}
					{searchResult?.Accounts.length === 0 ? <p className='m_align'>{t("noDataAvailable")}</p> : null}
					<div className='header-container'>
						<AyatSectionTitle title={t('Facilities')} />
						{searchResult?.Facilities.length !== 0 &&
							<Link
								onClick={() => setIsPopupVisible(false)}
								className={'r-p '}
								to={`/search-result?tab=facilities/${searchInputRef?.current?.value}`}>
								{t(`SeeAll`)}
							</Link>
						}
					</div>
					{!isLoadedSearchResult && <AyatLoader />}
					{isLoadedSearchResult && searchResult?.Facilities?.map((result) => (
						<SearchCard key={result?.Id} title={result?.Name} specialty={result?.Description} resSrc={result?.Image} src={'/assets/Institutions.png'} />
					))}
					{searchResult?.Facilities.length === 0 ? <p className='m_align'>{t("noDataAvailable")}</p> : null}
				</div>
			)}
		</div>
	);
};

export default Search;
