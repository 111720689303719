export const Languages = [
  {
    nameEn: "English",
    nameAr: "انكليزي",
    dir: "ltr",
    code: "en",
  },
  {
    nameEn: "Arabic",
    nameAr: "عربي",
    dir: "rtl",
    code: "ar",
  },
];
