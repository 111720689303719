import React from "react";
import "./FAQ.scss";
import LayoutFooter from "../../layout/LayoutFooter/LayoutFooter";
import FAQQuestionsSection from "../../sections/FAQQuestionsSection/FAQQuestionsSection";
import Scroller from "../../components/Scroller/Scroller";
const FAQ = () => {
  return (
    <section className="faq-container">
    <Scroller/>
     <FAQQuestionsSection />
     <LayoutFooter />
    </section>
  );
};

export default FAQ;
