import React from "react";
import "./MenuItem.scss";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
const MenuItem = (props) => {
  const isMobileScreen = useMediaQuery({ maxWidth: "575px" });
  const { t } = useTranslation("common");
  return (
    <div
      className={`terms-of-use-menu-item ${
        props.activeMenuItem === props.id ? "terms-of-use-menu-item-active" : ""
      }`}
    >
      <div className="terms-of-use-menu-item-icon">
        <img src={props.icon} alt={props.title} />
      </div>
      {!isMobileScreen && (
        <>
          <span>
            {t(`Pages.TermsOfUse.TermsOfUseItemsTitles.${props?.title}`)}
          </span>
          <div className="terms-of-use-menu-item-arrow">
            <img 
            // className="bg-BlueToLeftArrow"
             src="assets/BlueToLeftArrow.svg"
              alt="BlueToLeftArrow" />
          </div>
        </>
      )}
    </div>
  );
};

export default MenuItem;
