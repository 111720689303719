import React, { useContext } from "react";
import "./ContactUs.scss";
import ContactUsSection from "../../sections/ContactUsSection/ContactUsSection";
import Footer from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";
import { Context } from "../../context/ContextInfoProvider";
const ContactUs = () => {
  // const isMobileScreen = useMediaQuery({ query: "(max-width: 575px)" });
  const location = useLocation();
  const { showBlurBackGround } = useContext(Context);
  return (
    <section
      className="contact-us"
      style={{
        paddingTop: location.pathname === "/contact-us" ? "19vh" : null,
      }}
    >
      {showBlurBackGround && <div className="blurEffectCard" />}
      <ContactUsSection />
      <div
        className="footer-wrapper"
        style={{
          display: location.pathname === "/contact-us" ? "block" : "none",
        }}
      >
        <Footer />
      </div>
    </section>
  );
};

export default ContactUs;
