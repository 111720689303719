import React from "react";
import "./LearnMoreArticleContentCard.scss";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useContext } from "react";
import { Context } from "../../context/ContextInfoProvider";

const LearnMoreArticleContentCard = ({ descEN, descAR, imgAR, imgEN }) => {
  const { i18n } = useTranslation("common");
  const { scrollableFlag } = useContext(Context);
  const ArticlesContentCardRef = useRef(null);
  const handleArticlesContentCardsDivWheel = (e) => {
    const div = ArticlesContentCardRef.current;
    const delta = e.deltaY;
    const scrollSpeed = 2;
    div.scrollBy({
      top: delta * scrollSpeed,
      behavior: "smooth",
    });
  };

  return (
    <article className={`learn-more-article-content-card`}>
      <div className="learn-more-article-content-card-img">
        <img src={i18n.language === "ar" ? imgAR : imgEN} alt={"CardImg"} />
      </div>

      <div
        className={`learn-more-article-content-wrapper scrolling-content  ${
          scrollableFlag && "scrollable"
        }`}
      >
        <div
          className={`learn-more-article-content-desc  learn-more-article-content-wrapper`}
          ref={ArticlesContentCardRef}
          onWheel={handleArticlesContentCardsDivWheel}
        >
          <p className="r-p">{i18n.language === "ar" ? descAR : descEN}</p>
        </div>
      </div>
      <img className="gradient-effect" src={"assets/gradient.webp"} alt="" />
    </article>
  );
};

export default LearnMoreArticleContentCard;
