import React, { useContext, useEffect } from "react";
import "./Scroller.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Ads from "../Ads/Ads";
import { Context } from "../../context/ContextInfoProvider";
import { useLocation } from "react-router-dom";
const Scroller = () =>
{
  const location = useLocation();
  const { isReachedTheBottomState, isReachedTheTopState } = useContext(Context);
  const [ activeSection, setActiveSection ] = useState(0);
  const [ NumberOfScrollBullets, setNumberOfScrollBullets ] = useState(3);
  const [ showAd, setShowAd ] = useState(false);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 992px)" });
  const { i18n } = useTranslation("common");
  const currentScrollPosition = window.scrollY;

  const [ isMiddleScreen, setIsMiddleScreen ] = useState(false);
  const [ isHomeScreen, setIsHomeScreen ] = useState(false);
  useEffect(() =>
  {
    if (location.pathname === "/") {
      setIsHomeScreen(true);
      setNumberOfScrollBullets(3);
    } else {
      setIsHomeScreen(false);
      setNumberOfScrollBullets(2);
    }
  }, [ location.pathname ]);
  useEffect(() =>
  {
    if (isReachedTheBottomState === false && isReachedTheTopState === false) {
      setIsMiddleScreen(true);
    }
  }, [ isReachedTheBottomState, isReachedTheTopState ]);
  const scrollToNext = () =>
  {
    const currentScrollPosition = window.scrollY;
    const targetScrollPosition = currentScrollPosition + window.innerHeight;
    window.scrollTo({
      top: targetScrollPosition,
      behavior: "smooth",
    });
  };

  const scrollToPrev = () =>
  {
    const currentScrollPosition = window.scrollY;
    const targetScrollPosition = currentScrollPosition - window.innerHeight;
    window.scrollTo({
      top: targetScrollPosition,
      behavior: "smooth",
    });
  };
  useEffect(() =>
  {
    if (currentScrollPosition === 0) {
      setActiveSection(1);
    }
    if (
      currentScrollPosition !== 0 &&
      currentScrollPosition !== window.innerHeight
    ) {
      setActiveSection(2);
    }
    if (isReachedTheBottomState) {
      setActiveSection(3);
    }
  }, [ currentScrollPosition, isReachedTheBottomState ]);

  const ScrollToActiveSection = (sectionIndex) =>
  {
    const targetSection = document.getElementById(
      `section-${sectionIndex + 1}`
    );
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const SlidesCircles = () =>
  {
    const Circles = [];
    for (let i = 0; i < NumberOfScrollBullets; i++) {
      Circles.push(
        <button
          name="togglingScroll"
          aria-label="Aria Name"
          className={`${(activeSection === 1 && i === 0) ||
              (activeSection === 2 && i === 1) ||
              (activeSection === 3 && i === NumberOfScrollBullets - 1)
              ? "Active"
              : "InActive"
            }`}
          key={i}
          onClick={() =>
          {
            ScrollToActiveSection(i);
          }}
        ></button>
      );
    }
    return Circles;
  };

  const handleShowAd = () =>
  {
    setShowAd(true);
  };
  const CloseLightbox = () =>
  {
    setShowAd(false);
  };
  return (
    <>
      {isMobileScreen === true ? (
        <>
          {showAd && (
            <div className="ad-container">
              <div className="show-ad-container">
                <img className="ad-image" src="assets/Ad.webp" alt="Ad" />
                <img
                  className="ad-close-btn"
                  src="assets/Xbtn.svg"
                  alt="close"
                  onClick={CloseLightbox}
                />
              </div>
            </div>
          )}
          <div className="mobile-ad-wrapper">
         
              {/* <Ads
                isMobileScreen={isMobileScreen}
                Func={() => handleShowAd()}
              /> */}
            
          </div>
        </>
      ) : (
        <>
          {isReachedTheTopState === true && (
            <>
              <div
                className="scroller"
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                {
                  <div className="scroller-circles-wrapper">
                    <SlidesCircles />
                  </div>
                }
                <div className="scroller-wrapper">
                  <p className="scroller-wrapper-FirstScreen"></p>

                  <img
                    className="scrollArrow"
                    onClick={scrollToNext}
                    src="/assets/DownArrowA.svg"
                    alt="ToBottomArrow"
                  />
                </div>
              </div>
            </>
          )}
          {isReachedTheTopState === false &&
            isReachedTheBottomState === false && (
              <>
                <div
                  className="scroller"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {
                    <div className="scroller-circles-wrapper">
                      <SlidesCircles />
                    </div>
                  }
                  <div className="scroller-buttons-wrapper">
                    <div className={`scroller-wrapper scroller-wrapper-middle`}>
                      <img
                        className="scrollArrow flip one"
                        onClick={scrollToPrev}
                        src="/assets/DownArrowA.svg"
                        alt="ToUpArrow"
                      />
                    </div>

                    <div
                      className={`scroller-wrapper ${isMiddleScreen === true && "MiddleScreen"
                        }`}
                    >
                      <p className="scroller-wrapper-MiddleScreen"></p>

                      <img
                        className="scrollArrow flip two"
                        onClick={scrollToNext}
                        src="/assets/DownArrowA.svg"
                        alt="ToBottomArrow"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          {isReachedTheTopState === false &&
            isReachedTheBottomState === true && (
              <>
                <div
                  className="scroller"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {
                    <div className="scroller-circles-wrapper">
                      <SlidesCircles />
                    </div>
                  }
                  <div
                    className={`scroller-wrapper isReachedTheBottom ${activeSection === 3 && "LastScreen"
                      }`}
                  >
                    <p className="scroller-wrapper-LastScreen"></p>

                    <img
                      className="scrollArrow"
                      onClick={scrollToPrev}
                      src="/assets/UpArrowA.svg"
                      alt="ToUpArrow"
                    />
                  </div>
                </div>
              </>
            )}
        </>
      )}
    </>
  );
};

export default Scroller;
