import React from 'react';
import './HomeServiceCard.scss';
import { useTranslation } from 'react-i18next';
import ButtonWithArrow from '../ButtonWithArrow/ButtonWithArrow';
import { handleGoToDomeCare } from '../../functions/functions';

const ServiceCard = (props) => {
	const { t, i18n } = useTranslation('common');

	return (
		<article
			className='service-card'
			dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
			onClick={handleGoToDomeCare}>
			<div className='service-card-img-container'>
				<img src={props.service.icon} alt='IMG' />
			</div>
			<p className='service-card-title'>{t(`Pages.Home.BriefAbout.ServicesList.${props.service.title}`)}</p>
			<ButtonWithArrow />
		</article>
	);
};

export default ServiceCard;
