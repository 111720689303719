import React from "react";
import "./Title.scss";
import { useTranslation } from "react-i18next";
const Title = (props) => {
  const { i18n } = useTranslation("common");

  return (
    <h1
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      className={`title title-${props.size} ${props.extraClass}`}
    >
      {props.title}
    </h1>
  );
};

export default Title;
