import React, { useContext, useState } from "react";
import "./Header.scss";
import { Row } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { Languages } from "../../languages";
import Button from "../../components/Button/Button";
import Search from "../../components/search/Search";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Context } from "../../context/ContextInfoProvider";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import { handleGoToDomeCare } from "../../functions/functions";
const Header = () => {
  const styleDirection = localStorage.getItem("style-direction");
  const { setShowBlurBackGround, showBlurBackGround } = useContext(Context);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const { t, i18n } = useTranslation("common");
  const [showNavBar, setShowNavBar] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const ToggleNav = () => {
    setShowNavBar(!showNavBar);
    setShowBlurBackGround(!showBlurBackGround);
  };

  return (
    <section
      className={`header ${i18n.language}`}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      
        <div className="header-first-row">
          <div className="first-row-selects">
            {/* <CountriesSelect Countries={Countries} /> */}
            <LanguageSelect Languages={Languages} />
          </div>
          <div className="logo-container">
            <img
              // className="bg-LOGO"
              src={"assets/LOGO.webp"}
              alt="LOGO"
            />
          </div>
        </div>
      
     
        <div className="header-second-row">
          <div className="header-menu-container">
            <ul className={`header-menu-list ${styleDirection}`}>
              <li className={splitLocation[1] === "" ? "active-link" : ""}>
                <NavLink to="/">{t(`Layout.Layout.Header.Menu.Home`)}</NavLink>
              </li>
              <li
                className={splitLocation[1] === "our-app" ? "active-link" : ""}
              >
                <NavLink to="our-app">
                  {t(`Layout.Layout.Header.Menu.OurApp`)}
                </NavLink>
              </li>
              <li
                className={
                  splitLocation[1] === "learn-more" ? "active-link" : ""
                }
              >
                <NavLink to="learn-more">
                  {t(`Layout.Layout.Header.Menu.LearnMore`)}
                </NavLink>
              </li>
              <li
                className={splitLocation[1] === "about-us" ? "active-link" : ""}
              >
                <NavLink to="about-us">
                  {t(`Layout.Layout.Header.Menu.AboutUs`)}
                </NavLink>
              </li>
            </ul>
            {!isMobileScreen && (
              <div
                className="header-search-container"
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <Search />
              </div>
            )}
          </div>
          {/* //? =========================Mobile Menu====================*/}

          {isMobileScreen && showNavBar === true && (
            <div
              className={`responsive-menu-container responsive-menu-container-${showNavBar}`}
            >
              <ul className={`header-menu-list ${styleDirection}`}>
                <li className={splitLocation[1] === "" ? "active-link" : ""}>
                  <NavLink to="/" onClick={() => ToggleNav()}>
                    {t(`Layout.Layout.Header.Menu.Home`)}
                  </NavLink>
                </li>
                <li
                  className={
                    splitLocation[1] === "our-app" ? "active-link" : ""
                  }
                >
                  <NavLink to="our-app" onClick={() => ToggleNav()}>
                    {t(`Layout.Layout.Header.Menu.OurApp`)}
                  </NavLink>
                </li>
                <li
                  className={
                    splitLocation[1] === "learn-more" ? "active-link" : ""
                  }
                >
                  <NavLink to="learn-more" onClick={() => ToggleNav()}>
                    {t(`Layout.Layout.Header.Menu.LearnMore`)}
                  </NavLink>
                </li>
                <li
                  className={
                    splitLocation[1] === "about-us" ? "active-link" : ""
                  }
                >
                  <NavLink to="about-us" onClick={() => ToggleNav()}>
                    {t(`Layout.Layout.Header.Menu.AboutUs`)}
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
          {isMobileScreen && (
            <div className="responsive-menu-icon" onClick={() => ToggleNav()}>
              <img
                id="responsiveIcon"
                src={`${
                  showNavBar === true
                    ? "assets/MobileMenu.svg"
                    : "assets/MobileMenuBlue.svg"
                }`}
                alt="MobileMenu"
              />
            </div>
          )}
          {isMobileScreen && (
            <div
              className="header-search-container"
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <Search />
            </div>
          )}
          {/* //? ========================= ====================*/}
          <div className="header-btns-container">
            <Button
              title={t(`Layout.Layout.Header.SignIn`)}
              className={"secondary"}
              Func={handleGoToDomeCare}
            />
            <Button
              title={t(`Layout.Layout.Header.SignUp`)}
              className={"primary"}
              Func={handleGoToDomeCare}
            />
          </div>
        </div>
    
    </section>
  );
};

export default Header;
