import React from "react";
import "./TermsOfUse.scss";
import Scroller from "../../components/Scroller/Scroller";
import LayoutFooter from "../../layout/LayoutFooter/LayoutFooter";
import TermsOfUseSection from "../../sections/TermsOfUseSection/TermsOfUseSection";
const TermsOfUse = (props) => {
  return (
    <section className="terms-of-use">
      <TermsOfUseSection />
      <LayoutFooter />
      <Scroller />
    </section>
  );
};

export default TermsOfUse;
