import React, { useState } from 'react';
import './TutorialsCard.scss';
import ReactPlayer from 'react-player';

const TutorialsCard = ({ titleAr, titleEn, urlAr, urlEn }) =>
{
	const [ play, setPlay ] = useState(false);
	const styleDirection = localStorage.getItem('i18nextLng');

	return (
		<>
			<div className={`video-tutorials-card ${play && 'popup-video'}`}>
				{!play && (
					<img
						onClick={() => setPlay(true)}
						className='tutorial-card-gradient'
						src='assets/tutorial-card-gradiant.webp'
						alt='hovered tutorial'
					/>
				)}
				{play && <img src='assets/Xbtn.svg' onClick={() => setPlay(false)} className='Xbtn' />}
				<ReactPlayer
					width={play ? '88%' : '100%'}
					height={play ? '88%' : '100%'}
					playing={play}
					pip
					controls={true}
					config={{ file: { forceHLS: true } }}
					url={styleDirection.slice(0, 2) === 'ar' ? urlAr : urlEn}
				/>
				{styleDirection.slice(0, 2) === 'ar' ? (
					<p onClick={() => setPlay(true)} className='video-title'>
						{titleAr}
					</p>
				) : (
					<p onClick={() => setPlay(true)} className='video-title'>
						{titleEn}
					</p>
				)}
			</div>
			{play && <div className={`video-wrapper ${play && 'popup-video'}`}></div>}
		</>
	);
};

export default TutorialsCard;
