import React, { useState } from "react";
import "./OurAppServicesTabPanel.scss";
import { Tab, Tabs } from "react-bootstrap";
import OurAppServiceCard from "../../components/OurAppServiceCard/OurAppServiceCard";
import AccountTypeCard from "../../components/AccountTypeCard/AccountTypeCard";
import { OurAppServicesData } from "../../Data/OurAppServices";
import { AccountTypeListData } from "../../Data/AccountTypeListData";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Ads from "../Ads/Ads";
import TutorialsCard from "../TutorialsCard/TutorialsCard";
import { tutorialsSData } from "../../Data/TutorialsData";
const OurAppServicesTabPanel = () => {
  const isMobileScreen = useMediaQuery({ maxWidth: "768px" });
  const isSmallScreen = useMediaQuery({ maxWidth: "1000px" });
  const { t, i18n } = useTranslation("common");
  const [key, setKey] = useState("Our Services");
  const [activeCardIndex, setActiveCardIndex] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeAccountCardIndex, setActiveAccountCardIndex] = useState(1);
  let cardsPerPage = 6;
  if (isSmallScreen) {
    cardsPerPage = 3;
  }
  const totalPages = Math.ceil(tutorialsSData.length / cardsPerPage);

  const handleDotClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div
      className={`services-tab-panel ${i18n.language}`}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <Tab
          eventKey="Our Services"
          title={t(
            `Pages.OurApp.OurAppServices.OurAppServicesTabPanel.OurServicesTabTitle`
          )}
          className="firstTab"
        >
          <div className="our-services-tab-cards-title">
            <h5 className="our-app-title-label">
              {t(
                `Pages.OurApp.OurAppServices.OurAppServicesTabPanel.OurAppBadge`
              )}
            </h5>
            <h2 className="our-app-title">
              {t(`Pages.OurApp.OurAppServices.OurAppServicesTabPanel.Title`)}
            </h2>

            <div className="select-tabs-points">
              <span
                className={
                  key === "Our Services" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("Our Services")}
                id="circle1"
              ></span>
              <span
                className={
                  key === "Account Type" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("Account Type")}
                id="circle2"
              ></span>
              <span
                className={
                  key === "tutorials" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("tutorials")}
                id="circle3"
              ></span>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <div className="our-services-tab-cards-container">
              {isMobileScreen && <div className="tabs-background-mobile"></div>}
              {OurAppServicesData?.map((service, index) => {
                return (
                  <OurAppServiceCard
                    key={index}
                    Id={service.Id}
                    img={service.icon}
                    imgWhite={service.iconWhite}
                    title={service.title}
                    desc={service.desc}
                    footerIcon={service.footerIcon}
                    setActiveCardIndex={setActiveCardIndex}
                    isActive={index === activeCardIndex ? true : false}
                  />
                );
              })}
            </div>
            {!isSmallScreen && <Ads />}
          </div>
        </Tab>
        <Tab
          eventKey="Account Type"
          title={t(
            `Pages.OurApp.OurAppServices.OurAppServicesTabPanel.AccountTypeTabTitle`
          )}
          className="secondTab"
        >
          <div className="our-services-tab-cards-title">
            <h5>
              {t(
                `Pages.OurApp.OurAppServices.OurAppServicesTabPanel.OurAppBadge`
              )}
            </h5>
            <h2 className="our-app-title">
              {t(
                `Pages.OurApp.OurAppServices.OurAppServicesTabPanel.AccountTypeTabTitle`
              )}
            </h2>
            <div className="select-tabs-points">
              <span
                className={
                  key === "Our Services" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("Our Services")}
                id="circle1"
              ></span>
              <span
                className={
                  key === "Account Type" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("Account Type")}
                id="circle2"
              ></span>
              <span
                className={
                  key === "tutorials" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("tutorials")}
                id="circle3"
              ></span>
            </div>
          </div>
          <div className="d-flex gap-3">
            <div className="our-services-tab-cards-container">
              {isMobileScreen && <div className="tabs-background-mobile"></div>}
              {AccountTypeListData?.map((Type, index) => {
                return (
                  <AccountTypeCard
                    key={index}
                    Id={Type.Id}
                    img={Type.icon}
                    imgWhite={Type.iconWhite}
                    title={Type.title}
                    desc={Type.desc}
                    footerIcon={Type.footerIcon}
                    setActiveAccountCardIndex={setActiveAccountCardIndex}
                    isActive={index === activeAccountCardIndex ? true : false}
                  />
                );
              })}
            </div>
            {!isSmallScreen && <Ads />}
          </div>
        </Tab>
        <Tab eventKey="tutorials" title={t(`tutorials`)} className="third-tab">
          <div className="our-services-tab-cards-title">
            <h5 className="our-app-title-label">
              {t(
                `Pages.OurApp.OurAppServices.OurAppServicesTabPanel.OurAppBadge`
              )}
            </h5>
            <h2 className="our-app-title">{t(`tutorials`)}</h2>

            <div className="select-tabs-points">
              <span
                className={
                  key === "Our Services" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("Our Services")}
                id="circle1"
              ></span>
              <span
                className={
                  key === "Account Type" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("Account Type")}
                id="circle2"
              ></span>
              <span
                className={
                  key === "tutorials" ? "activeCircle" : "toggleCircle"
                }
                onClick={() => setKey("tutorials")}
                id="circle3"
              ></span>
            </div>
          </div>
          <div className="d-flex gap-3">
            <div className="our-services-tab-cards-container grid-tutorials">
              {tutorialsSData
                .slice(
                  (currentPage - 1) * cardsPerPage,
                  currentPage * cardsPerPage
                )
                .map((tutorialVideo, index) => {
                  return (
                    <TutorialsCard
                      key={index}
                      urlAr={tutorialVideo.urlAr}
                      urlEn={tutorialVideo.urlEn}
                      titleAr={tutorialVideo.titleAr}
                      titleEn={tutorialVideo.titleEn}
                    />
                  );
                })}
              <div className="pagination-dots">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <div
                    key={index}
                    className={`dot ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                    onClick={() => handleDotClick(index + 1)}
                  ></div>
                ))}
              </div>
            </div>
            {!isSmallScreen && <Ads />}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default OurAppServicesTabPanel;
