import React, { useContext, useRef } from "react";
import "./HomeArticles.scss";
import Title from "../../components/Title/Title";
import HomeArticleHeadLineCard from "../../components/HomeArticleHeadLineCard/HomeArticleHeadLineCard";
import { Articles } from "../../Data/Articles";
import { useTranslation } from "react-i18next";
import { Context } from "../../context/ContextInfoProvider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";
import { useMediaQuery } from "react-responsive";
import VerticalSwiper from "../../components/VerticalSwiper/VerticalSwiper";
const HomeArticles = () => {
  const { showBlurBackGround, VerticalSwiperContainerHeight } =
    useContext(Context);
  const { t, i18n } = useTranslation("common");
  const isMobileScreen = useMediaQuery({ maxWidth: "575px" });

  const breakpoints = {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 3,
    },
    1920: {
      slidesPerView: 3,
    },
  };
  const ArticlesContentCardRef = useRef(null);

  const handleArticlesContentCardsDivWheel = (e) => {
    const div = ArticlesContentCardRef.current;
    const delta = e.deltaY;
    const scrollSpeed = 2;
    div.scrollBy({
      top: delta * scrollSpeed,
      behavior: "smooth",
    });
  };

  return (
    <section
      className={`home-articles`}
      style={{
        height: VerticalSwiperContainerHeight === true && "40vh",
      }}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      id="section-2"
    >
      {showBlurBackGround && <div className="blurEffectCard" />}
      <div className="home-articles-container">
        <div className="home-articles-title">
          <Title title={t(`Pages.Home.HomeArticles.Title`)} />
        </div>
        <div
          className="swiper-container"
          ref={ArticlesContentCardRef}
          onWheel={handleArticlesContentCardsDivWheel}
        >
          {!isMobileScreen ? (
            <Swiper
              initialSlide={3}
              direction={"horizontal"}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              breakpoints={breakpoints}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
            >
              {Articles.map((article, index) => {
                return (
                  <SwiperSlide key={index}>
                    {({ isActive }) => {
                      return (
                        <HomeArticleHeadLineCard
                          key={article?.id}
                          descAR={article?.descAR}
                          descEN={article?.descEN}
                          index={article?.id}
                          imgAR={article.imgAR}
                          imgEN={article.imgEN}
                          isActive={isActive}
                        />
                      );
                    }}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <>
              <VerticalSwiper />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeArticles;
