import React from "react";
import "./OurAppServiceCard.scss";
import { useMediaQuery } from "react-responsive";
import { get_absolute_lenght_string } from "../../functions/functions";
import { useTranslation } from "react-i18next";
import { handleGoToDomeCare } from '../../functions/functions';

const OurApServiceCard = (props) =>
{
  const { t } = useTranslation("common");

  const isTabletScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const handleClick = () =>
  {
    props.setActiveCardIndex(props.Id - 1);
  };

  return (
    <article
      className={`${props.isActive ? "our-app-service-card-active" : "our-app-service-card "
        }`}
      onClick={handleClick}
    >
      {props.isActive && isMobile ? (
        <div className="our-app-service-card-active-mobile-wrapper">
          <div className="our-app-service-card-icon-container">
            {props.isActive ? (
              <img src={props.img} alt="Icon" />
            ) : (
              <img src={props.imgWhite} alt="Icon" />
            )}
          </div>
          <div className="our-app-service-card-info">
            <div className="our-app-service-card-title-container">
              <p className="r-p">
                {t(
                  `Pages.OurApp.OurAppServices.OurServicesList.${props.title}`
                )}
              </p>
            </div>
            <div className="our-app-service-card-body">
              {props.isActive && (
                <div className="our-app-service-card-desc-container">
                  <p className="our-app-service-card-desc r-p">
                    {t(
                      `Pages.OurApp.OurAppServices.OurServicesList.OurServicesListItemDesc.${isTabletScreen
                        ? get_absolute_lenght_string(props.desc, 30)
                        : props.desc
                      }`
                    )}
                  </p>
                </div>
              )}
              {props.isActive && (
                <div className="our-app-service-card-footer-container">
                  <div className="our-app-service-card-footer-icon-wrapper">
                    <img
                      src={props.footerIcon}
                      alt="Icon"
                      onClick={
                     handleGoToDomeCare
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="our-app-service-card-header">
            <div className="our-app-service-card-icon-container">
              {props.isActive ? (
                <img src={props.img} alt="Icon" />
              ) : (
                <img src={props.imgWhite} alt="Icon" />
              )}
            </div>
            <div className="our-app-service-card-title-container">
              <p className="r-p">
                {t(
                  `Pages.OurApp.OurAppServices.OurServicesList.${props.title}`
                )}
              </p>
            </div>
          </div>
          <div className="our-app-service-card-body">
            {props.isActive && (
              <div className="our-app-service-card-desc-container">
                <p className="our-app-service-card-desc r-p">
                  {t(
                    `Pages.OurApp.OurAppServices.OurServicesList.OurServicesListItemDesc.${isTabletScreen
                      ? get_absolute_lenght_string(props.desc, 30)
                      : props.desc
                    }`
                  )}
                </p>
              </div>
            )}
            {props.isActive && (
              <div className="our-app-service-card-footer-container">
                <div className="our-app-service-card-footer-icon-wrapper">
                  <img
                    src={props.footerIcon}
                    alt="Icon"
                    onClick={
                     handleGoToDomeCare
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </article>
  );
};

export default OurApServiceCard;
