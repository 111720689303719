import React from "react";
import "./Button.scss";

const Button = (props) => {
  return (
    <button
      className={`button button-${props.className}`}
      type={props.type}
      onClick={props.Func}
    >
      {props.title}
    </button>
  );
};

export default Button;
