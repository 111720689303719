import React, { useState } from 'react';
import './ContactInfo.scss';
import Title from '../Title/Title';
import MobileAppCard from '../MobileAppCard/MobileAppCard';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const ContactInfo = () =>
{
	const { t } = useTranslation('common');
	const isMobileScreen = useMediaQuery({ maxWidth: '575px' });
	const [ MobileCardData, setMobileCardData ] = useState([
		{
			id: 1,
			desc: t(`Pages.ContactUs.ContactInfo.AppleCard.Desc`),
			title: t(`Pages.ContactUs.ContactInfo.AppleCard.Title`),
			img: 'assets/applestore.svg',
			downloadLink: 'https://apps.apple.com/ae/app/domecare/id1621264599',
		},
		{
			id: 2,
			desc: t(`Pages.ContactUs.ContactInfo.GoogleCard.Desc`),
			title: t(`Pages.ContactUs.ContactInfo.GoogleCard.Title`),
			img: 'assets/playstore.svg',
			downloadLink: 'https://play.google.com/store/apps/details?id=com.ayatapps.domecaree',
		},
	]);
	return (
		<div className='contact-info'>
			<Title title={t(`Pages.ContactUs.Title`)} />
			<div className='contact-info-numbers'>

				<a href="tel:+971 507655005">4304</a>

				<a className={"r-p"} href='mailto:info@dome-care.com'>info@dome-care.com</a>
			</div>
			<div className='contact-info-icons'>
				<a className='contact-icon-container' target='_blank' href="https://www.facebook.com/profile.php?id=61555352964558">
					<img src='assets/Facebook-1.svg' alt='Facebook' />
				</a>
				<a className='contact-icon-container' target='_blank' href="https://www.youtube.com/@DomeCare">
					<img className='youtube' src='assets/tube.svg' alt='youtube' />
				</a>
				<a className='contact-icon-container' target='_blank' href="https://www.instagram.com/domecareapp?igsh=MTduNjI2eGc4MDBwaQ%3D%3D">
					<img src='assets/Instagram-1.svg' alt='Instagram' />
				</a>
			</div>
			{!isMobileScreen && (
				<>
					<div className='contact-info-msg'>
						<p className='r-p'>{t(`Pages.ContactUs.ContactInfo.Msg`)}</p>
					</div>
					<div className='contact-info-app-cards-container'>
						{MobileCardData.map((card, index) =>
						{
							return <MobileAppCard key={index} card={card} />;
						})}
					</div>
				</>
			)}
		</div>
	);
};

export default ContactInfo;
