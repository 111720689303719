import React from "react";
import "./AccountTypeCard.scss";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import {handleGoToDomeCare } from '../../functions/functions';

const AccountTypeCard = (props) =>
{
  const { t } = useTranslation("common");
  const isTabletScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMediumScreen = useMediaQuery({ query: "(max-width: 1440px)" });
  const handleClick = () =>
  {
    props.setActiveAccountCardIndex(props.Id - 1);
  };

  return (
    <article
      className={`${props.isActive
        ? "scrollable our-app-account-card-active"
        : "our-app-account-card "
        }`}
      onClick={handleClick}
    >
      <div className="our-app-account-card-header">
        <div className="our-app-account-card-icon-container">
          {props.isActBive ? (
            <img src={props.img} alt="Icon" />
          ) : (
            <img src={props.imgWhite} alt="Icon" />
          )}
        </div>
        <div className="our-app-account-card-title-container">
          <p className="r-p scrollable">
            {t(`Pages.OurApp.OurAppServices.AccountTypesList.${props.title}`)}
          </p>
        </div>
      </div>
      <div className="our-app-account-card-body scrollable">
        {props.isActive && (
          <div className="our-app-account-card-desc-container scrollable">
            <p className="scrollable our-app-account-card-keywords r-p">
              {t(
                `Pages.OurApp.OurAppServices.AccountTypesList.KeyWords.${props.title}`
              )}
            </p>

            {isTabletScreen ? (
              <p className="r-p scrollable">
                {t(
                  `Pages.OurApp.OurAppServices.AccountTypesList.AccountTypesListItemDesc.${props.desc}`
                )}
                ...
              </p>
            ) : isSmallScreen ? (
              <p className="r-p scrollable">
                {t(
                  `Pages.OurApp.OurAppServices.AccountTypesList.AccountTypesListItemDesc.${props.desc}`
                )}
                ...
              </p>
            ) : isMediumScreen ? (
              <p className="r-p scrollable">
                {t(
                  `Pages.OurApp.OurAppServices.AccountTypesList.AccountTypesListItemDesc.${props.desc}`
                )}
                ...
              </p>
            ) : (
              <p className="r-p scrollable">
                {t(
                  `Pages.OurApp.OurAppServices.AccountTypesList.AccountTypesListItemDesc.${props.desc}`
                )}
                ...
              </p>
            )}
          </div>
        )}
      </div>
      {props.isActive && (
        <div
          className="our-app-account-card-footer"
          onClick={handleGoToDomeCare}
        >
          <img src={props.footerIcon} alt="Icon" />
        </div>
      )}
    </article>
  );
};

export default AccountTypeCard;
