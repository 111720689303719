export const OurAppServicesData = [
  {
    Id: 1,
    icon: "assets/Digitalhealthcareguide-1.svg",
    iconWhite: "assets/Digitalhealthcareguide.svg",
    title: "Digitalhealthcareguide",
    desc: "Digitalhealthcareguide",
    footerIcon: "assets/ArrowRight.svg",
  },
  {
    Id: 2,
    icon: "assets/Onlineconsultations-1.svg",
    iconWhite: "assets/Onlineconsultations.svg",
    title: "Onlineconsultations",
    desc: "Onlineconsultations",
    footerIcon: "assets/ArrowRight.svg",
  },
  {
    Id: 3,
    icon: "assets/SearchforDrugs-1.svg",    
    iconWhite: "assets/SearchforDrugs.svg",
    title: "SearchforDrugs",
    desc: "SearchforDrugs",
    footerIcon: "assets/ArrowRight.svg",
  },
  {
    Id: 4,
    icon: "assets/UnifiedHealthFile-1.svg",
    iconWhite: "assets/UnifiedHealthFile.svg",
    title: "UnifiedHealthFile",
    desc: "UnifiedHealthFile",
    footerIcon: "assets/ArrowRight.svg",
  },
  {
    Id: 5,
    icon: "assets/healthcareprovider-1.svg",
    iconWhite: "assets/healthcareprovider.svg",
    title: "Healthcareproviderrequest",
    desc: "Healthcareproviderrequest",
    footerIcon: "assets/ArrowRight.svg",
  },
];
