export const Articles = [
	{
		id: 1,
		imgEN: 'assets/articles/CausesEN.webp',
		imgAR: 'assets/articles/CausesAR.webp',
		descAR: (
			<article>
				<h4>الصداع: أنواع، أعراض، ودلالات.</h4>
				<h5>مقدّمة:</h5>
				<p>
					قبل البدء بالتّمييز بين الأنواع المختلفة للصّداع، من المهمّ أن نشرح المصطلح بشكلٍ كافٍ!
					الصّداع هو الألم الذي يصيب أيّ جزء من رأسك، وهو ظرف شائع لا يدلّ في معظم حالاته على مرضٍ
					خطير، ذلك لأنّه ذو أنواع متعددة وينتج عن مجموعةٍ كبيرة من الأسباب المختلفة. على الرّغم من
					ذلك، قد يرتبط الصّداع بأمراض أو إصابات خطيرة وقد يقرّر عندها الطبيب حاجتك إلى بعض
					الفحوصات، أو العلاج الإسعافيّ.
				</p>
				<h5>ولكن، ما هي العوامل التي قد تؤدّي للصّداع؟</h5>
				<p>يعد الصّداع حالة شائعة تصيب معظم الأشخاص من وقتٍ لآخر، وتتعدّد الأسباب التي نذكر لك منها:</p>
				<ul>
					<li>
						الأسباب العاطفيّة:
						<span style={{ textDecoration: 'underline' }}>التوتّر، القلق، الاكتئاب.</span>
					</li>
					<li>
						الأسباب المرضيّة:
						<span style={{ textDecoration: 'underline' }}>
							الصّداع النّصفيّ (الشقيقة)، ارتفاع ضغط الدّم.
						</span>
					</li>
					<li>
						الأسباب الجسديّة: كالإصابات
						<span style={{ textDecoration: 'underline' }}> ورضوض الرّأس.</span>
					</li>
					<li>الأسباب البيئيّة: مثل تغيّر الطّقس.</li>
				</ul>
				<p>
					يؤثّر الصّداع الحاد أو المتكرّر على جودة الحياة، لذا من المهم تحديد سببه والعوامل المحرّضة
					لحدوثه للبدء بخطّةٍ علاجيّةٍ مناسبة.
				</p>
				<h5>ما أنواع الصّداع إذاً، وكيف يمكن تمييزها؟</h5>
				<p>
					لتصنيف الأنواع المختلفة بطريقةٍ سهلة، تمّ تقسيم أنواع الصّداع إلى مجموعة أساسيّة ومجموعة
					ثانويّة.
				</p>
				<h5>حول الصّداع الأساسيّ!</h5>
				<ul>
					<li>
						ينتج <span style={{ textDecoration: 'underline' }}>الصّداع الأساسيّ</span> عن عوامل
						تتوزّع بين فرط النّشاط إلى وجود مشكلات في البنى الدّماغيّة الحسّاسة للألم.
					</li>
					<li>
						قد يكون النّشاط الكيميائيّ للدّماغ، الأوعية الدّمويّة والأعصاب المحيطة بالجمجمة، أو
						عضلات الرأس والرّقبة، عاملاً مساهماً في هذا النّوع من الصّداع، إضافةً لبعض
						<span style={{ textDecoration: 'underline' }}>العوامل الجينية</span> المؤهبّة
						للإصابة.
					</li>
					<li>أكثر أنواع الصّداع الأساسيّ شيوعاً تتضمّن:</li>
					<ul>
						<li>
							<span style={{ textDecoration: 'underline' }}>الصّداع العنقوديّ.</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>
								الصداع النّصفيّ (الشّقيقة).
							</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>
								الصداع النّصفيّ مع الأورة.
							</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>الصداع التوتّريّ.</span>
						</li>
					</ul>
					<li>بعض العادات المتعلقة بنمط الحياة تعتبر محرّضات لهذا النوع من الصداع مثل:</li>
					<ul>
						<li>
							<span style={{ textDecoration: 'underline' }}>تناول الكحول.</span>
						</li>
						<li>
							تناول بعض الأطعمة،
							<span style={{ textDecoration: 'underline' }}>
								كاللّحومات المعالجة الحاوية على النّترات.
							</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>عدم انتظام النّوم.</span>
						</li>
						<li>عدم تناول وجبات الطعام.</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>التّوتّر.</span>
						</li>
					</ul>
				</ul>
				<h5>حول الصّداع الثّانويّ!</h5>
				<ul>
					<li>
						ينتج الصّداع الثّانويّ عن بعض الحالات أو الأمراض التي تنشّط الأعصاب في منطقة الرّأس
						مسبّبة صداعاً يختلف بشدّته حسب الحالة.
					</li>
					<li>العوامل والأمراض المسبّبة لهذه الحالة متعدّدة جدّاً، نذكر منها:</li>
					<ul>
						<li>
							<span style={{ textDecoration: 'underline' }}>الجلطة الدّماغيّة.</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>
								التهاب الجيوب الأنفيّة الحاد.
							</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>الغلوكوما.</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>مشاكل الأسنان.</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>نوبات الهلع.</span>
						</li>
						<li>
							<span style={{ textDecoration: 'underline' }}>ارتفاع ضغط الدّم.</span>
						</li>
					</ul>
				</ul>
				<p>
					من المهمّ العمل مع طبيبك والتّواصل معه بشكلٍ فعال للوصول إلى التّشخيص المناسب لحالتك
					والعمل على علاجها.ب
				</p>
{/* 
				<h5>المصادر:</h5>
				<p>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/symptoms/headache/basics/causes/sym-20050800 '
						rel='noopener noreferrer'>
						www.mayoclinic.org{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.healthdirect.gov.au/headaches#:~:text=A%20headache%20is%20usually%20felt,the%20head%20than%20the%20other'
						rel='noopener noreferrer'>
						www.healthdirect.gov.au
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.medicalnewstoday.com/divs/73936'
						rel='noopener noreferrer'>
						www.medicalnewstoday.com{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/symptoms/headache/basics/causes/sym-20050800 '
						rel='noopener noreferrer'>
						www.mayoclinic.org{' '}
					</a>
				</p> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Headaches: Types, Symptoms, and Indications</h4>
				

				
					<h5>Introduction</h5>
					<p>
						Before we start explaining the different types of headaches, it is important to
						explain the term sufficiently! A headache is pain experienced in any part of your
						head. It is a common condition, and in most cases, not a symptom of serious illness.
						There are many different types of headaches and they can be caused by a range of
						different things. Headaches can occasionally be associated with serious illness or
						injury, and your doctor may decide you need further testing or urgent treatment.
					</p>
				

				
					<h5>Common Causes of Headaches</h5>
					<p>Headaches are a common health problem that most people experience at some time.</p>
					<ul>
						<li>Emotional, such as stress, depression, or anxiety.</li>
						<li>Medical, such as migraine or high blood pressure.</li>
						<li>Physical, such as an injury.</li>
						<li>Environmental, such as the weather.</li>
					</ul>
					<p>
						Frequent or severe headaches can affect a person’s quality of life. Knowing how to
						recognize the type of headache and its cause can help a person take appropriate
						action.
					</p>
				

				
					<h5>Types of Headaches</h5>
					<p>So, what are the different kinds of headaches? And how are they classified?</p>
					<p>
						To simplify distinguishing different types of headaches they were divided into 2
						main groups: primary headaches and secondary headaches.
					</p>

					
						<h6>Primary Headaches</h6>
						<p>
							A primary headache is caused by overactivity of or problems with
							pain-sensitive structures in your head. A primary headache isn't a symptom of
							an underlying disease. Chemical activity in your brain, the nerves or blood
							vessels surrounding your skull, or the muscles of your head and neck (or some
							combination of these factors) can play a role in primary headaches. Some
							people may also carry genes that make them more likely to develop such
							headaches.
						</p>
						<p>The most common primary headaches are:</p>
						<ul>
							<li>Cluster headache.</li>
							<li>Migraine.</li>
							<li>Migraine with aura.</li>
							<li>Tension headache.</li>
						</ul>
						<p>Some primary headaches can be triggered by lifestyle factors, including:</p>
						<ul>
							<li>Alcohol, particularly red wine.</li>
							<li>Certain foods, such as processed meats that contain nitrates.</li>
							<li>Changes in sleep or lack of sleep.</li>
							<li>Poor posture.</li>
							<li>Skipped meals.</li>
							<li>Stress.</li>
						</ul>
					

					
						<h6>Secondary Headaches</h6>
						<p>
							A secondary headache is a symptom of a disease that can activate the
							pain-sensitive nerves of the head. Any number of conditions — varying greatly
							in severity — may cause secondary headaches.
						</p>
						<p>Possible causes of secondary headaches include:</p>
						<ul>
							<li>Brain aneurysm.</li>
							<li>Sinus headaches.</li>
							<li>Glaucoma.</li>
							<li>Stroke.</li>
							<li>Toxoplasmosis.</li>
						</ul>
						<p>
							Symptoms, causes and different types of headaches vary, so it is important to
							work with your doctors and communicate with them clearly to access the
							appropriate diagnosis of your condition and work to treat it.
						</p>
					
				

				{/* <h5>references:</h5>
				<p>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/symptoms/headache/basics/causes/sym-20050800 '
						rel='noopener noreferrer'>
						www.mayoclinic.org{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.healthdirect.gov.au/headaches#:~:text=A%20headache%20is%20usually%20felt,the%20head%20than%20the%20other'
						rel='noopener noreferrer'>
						www.healthdirect.gov.au
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.medicalnewstoday.com/divs/73936'
						rel='noopener noreferrer'>
						www.medicalnewstoday.com{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/symptoms/headache/basics/causes/sym-20050800 '
						rel='noopener noreferrer'>
						www.mayoclinic.org{' '}
					</a>
				</p> */}
			</article>
		),
	},

	{
		id: 2,
		imgAR: 'assets/articles/OralHealthAR.webp',
		imgEN: 'assets/articles/OralHealthEN.webp',
		descAR: (
			<article dir='rtl'>
				<h4>الصحة الفموية، مرآة صحتك الجسدية الكاملة! </h4>

				<h5>مقدمة: </h5>
				<p>
					التّجويف الفمويّ يحوي <span style={{ textDecoration: 'underline' }}>الأسنان</span>،{' '}
					<span style={{ textDecoration: 'underline' }}>اللّسان،</span>{' '}
					<span style={{ textDecoration: 'underline' }}>اللّثة،</span> وسقف الفمّ،ابتداءً من
					الشّفتين وانتهاءً بالحلق، يحدّد الفم بحالته الصّحّيّة الحالة الصّحّيّة العامّة لجسدك.{' '}
				</p>
				<p>
					للفمٍ دورٌ في عمليات التّنفّس، المضغ،{' '}
					<span style={{ textDecoration: 'underline' }}>الهضم،</span> ومنح النّاحية الجماليّة
					لابتساماتنا! وعلى الرّغم من كون حالة{' '}
					<span style={{ textDecoration: 'underline' }}>تسوّس الأسنان </span>سهلة المنع، إلّا أنّها
					الحالة الأكثر شيوعاً في العالم، وهو ما يعد دلالةً على قلّة الاهتمام والوعي بالصّحّة
					الفمويّة.{' '}
				</p>

				<h5>ماهي الصّحّة الفمويّة؟ </h5>
				<p>
					عند الحديث عن صحّة الفم فنحن نتحدّث عن صحّة جميع الأجزاء الفمويّة التي ذكرناها في
					المقدّمة، والأساليب المناسبة للحفاظ على صحّتها، إضافةً للإجراءات الدّوريّة لمراقبتها.{' '}
				</p>
				<h5>إذاً، ما هي المشاكل التي قد يرد ذكرها عند الحديث عن الصّحّة الفمويّة؟ </h5>
				<ul>
					<li>
						من المتوقّع أن يكون{' '}
						<span style={{ textDecoration: 'underline' }}>تسوّس الأسنان والنّخور</span> هو
						المشكلة الأولى والأشيع ذكراً نتيجة انتشارها الكبير.{' '}
					</li>
					<li>
						يضاف لها مشاكل اللّثة{' '}
						<span style={{ textDecoration: 'underline' }}>(تراجع اللّثة، التهاب اللّثة)</span>{' '}
						والتهاب دواعم السّنّ.
					</li>
					<li style={{ textDecoration: 'underline' }}>تصدّعات الأسنان، كسور الأسنان. </li>
					<li style={{ textDecoration: 'underline' }}>الأسنان الحسّاسة. </li>
					<li style={{ textDecoration: 'underline' }}>سرطان الفم.</li>
				</ul>
				<h5>هل تتوقّف المشاكل الصّحّيّة عند حدود الفم؟ </h5>
				<ul>
					<p>
						في الحقيقة، تمّ ربط المشاكل الصّحّيّة الفمويّة ببعض المشاكل الجسديّة، فعلى الرغم من
						عدم وضوح بعض الرّوابط بشكلٍ تامّ إلّا أنّ أشيع هذه الحالات تتضمّن:{' '}
					</p>
					<li>
						<span style={{ textDecoration: 'underline' }}>التهاب شغاف القلب:</span> قد تنتشر
						البكتيريا من الفم إلى المجرى الدمويّ وتلتصق بمناطق قلبيّة معيّنة مسبّبة التهاباً.{' '}
					</li>
					<li>
						أمراض القلب والأوعية الدّمويّة: الرّوابط ليست واضحةً تماماً، لكنّ بعض الدّراسات
						بيّنت وجود علاقةٍ بين الالتهاب الفمويّ والإنتانات التي تسببها الجراثيم الفموية وبين{' '}
						<span style={{ textDecoration: 'underline' }}>انسداد الشّرايين</span>،{' '}
						<span style={{ textDecoration: 'underline' }}>والسّكتة الدماغية</span> وغيرها.{' '}
					</li>
					<li>
						مضاعفات الحمل والولادة: التهاب دواعم السّنّ يرتبط{' '}
						<span style={{ textDecoration: 'underline' }}>بالولادة المبكّرة،</span> بالإضافة
						لانخفاض الوزن عند الولادة.{' '}
					</li>
					<li>
						الأمراض <span style={{ textDecoration: 'underline' }}>والالتهاب الرّئويّ</span>: قد
						تنتقل البكتيريا من الفم إلى الرّئة مسبّبة أمراضاً والتهابات.{' '}
					</li>
				</ul>
				<h5>ماهي أعراض الإصابة الفمويّة؟ </h5>
				<p>
					بدلاً من الانتظار لظهور الأعراض، جدوِل زيارتين سنويّتين لطبيب الأسنان للاطمئنان على صحّة
					فمك، ولكن راجعه بشكلٍ فوريّ عند ظهور إحدى الأعراض التّالية:{' '}
				</p>
				<ul>
					<li>
						<span style={{ textDecoration: 'underline' }}>تقرّحات فمويّة</span> تستمرّ لأسبوعٍ
						أو أكثر.{' '}
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>نزيف أو تورّم اللثّة،</span> خصوصاً
						عند التّفريش أو استخدام الخيط.{' '}
					</li>

					<li style={{ textDecoration: 'underline' }}>الرّائحة المزمنة الكريهة للفم. </li>

					<li>تحسّس غير معهود للمشروبات السّاخنة أو الباردة. </li>
					<li>آلام في الأسنان. </li>

					<li style={{ textDecoration: 'underline' }}>تخلخل الأسنان. </li>
					<li style={{ textDecoration: 'underline' }}>تراجع اللّثّة. </li>
					<li style={{ textDecoration: 'underline' }}>تصدّع الأسنان. </li>
					<li style={{ textDecoration: 'underline' }}>انكسار الأسنان.</li>

					<li>وذمة في الوجه والخدّين.</li>
				</ul>
				<h5>نصائح للحفاظ على الصّحّة الفمويّة: </h5>
				<ul>
					<li>
						<span style={{ textDecoration: 'underline' }}>تنظيف الأسنان</span> مرّتان يوميّاً،
						لمدة دقيقتين، وباستخدام فرشاة أسنانٍ ذات شعيرات ناعمة ومعجونٍ يحوي على الفلورايد.{' '}
					</li>
					<li>استخدام خيط التنظيف يوميّاً.</li>
					<li> استخدام غسول الفم بعد التّفريش واستخدام الخيط. </li>
					<li>الحدّ من المشروبات والأطعمة السّكّريّة، وتناول طعام متوازن. </li>
					<li>استبدال فرشاة الأسنان كلّ 3-4 أشهر. </li>
					<li>
						<span style={{ textDecoration: 'underline' }}>تجنّب</span> التّدخين.{' '}
					</li>
				</ul>
				{/* <h5>المصادر:</h5>
				<a
					target='_blank'
					href='https://www.healthline.com/health/dental-and-oral-health#types-of-diseases '
					rel='noopener noreferrer'>
					{' '}
					www.healthline.com
				</a>
				<a
					target='_blank'
					href='https://www.hsph.harvard.edu/nutritionsource/oral-health/ '
					rel='noopener noreferrer'>
					www.hsph.harvard.edu{' '}
				</a>
				<a
					target='_blank'
					href='https://www.mayoclinic.org/healthy-lifestyle/adult-health/in-depth/dental/art-20047475 '
					rel='noopener noreferrer'>
					mayoclinic{' '}
				</a>
				<a
					target='_blank'
					href='https://www.healthline.com/health/dental-and-oral-health#symptoms'
					rel='noopener noreferrer'>
					{' '}
					healthline
				</a>
				<a
					target='_blank'
					href='https://www.mayoclinic.org/healthy-lifestyle/adult-health/in-depth/dental/art-20047475'
					rel='noopener noreferrer'>
					{' '}
					mayoclinic
				</a> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Oral Health: The Gateway to Your Overall Physical Health!</h4>
				

				
					<h5>Introduction</h5>
					<p>
						The oral cavity contains teeth, tongue, gums, and supportive bones, from the lips to
						the throat, the mouth determines the general state of your body's health. The mouth
						plays a role in breathing, chewing, digesting, and the beauty of our smiles!
						Although dental decay is easy to prevent, it is the world's most common condition,
						which indicates the lack of attention and awareness of oral health.
					</p>
				

				
					<h5>What is Oral Health?</h5>
					<p>
						When we talk about oral health, we are talking about the health of all the oral
						parts we mentioned in the introduction, the appropriate methods to maintain their
						health, as well as periodic procedures to monitor them and their problems which are
						linked to diseases and other physical health problems.
					</p>
				

				
					<h5>Common Oral Health Problems</h5>
					<ul>
						<li>Cavities.</li>
						<li>Gum disease (gingivitis).</li>
						<li>Cracked or broken teeth.</li>
						<li>Sensitive teeth.</li>
						<li>Oral cancer.</li>
					</ul>
				

				
					<h5>Are Oral Health Conditions Limited to the Mouth?</h5>
					<p>Your oral health might contribute to various diseases and conditions, including:</p>
					<ul>
						<li>
							Endocarditis: This infection of the inner lining of your heart chambers or
							valves (endocardium) typically occurs when bacteria or other germs from
							another part of your body, such as your mouth, spread through your bloodstream
							and attach to certain areas in your heart.
						</li>
						<li>
							Cardiovascular disease: Although the connection is not fully understood, some
							research suggests that heart disease, clogged arteries, and stroke might be
							linked to the inflammation and infections that oral bacteria can cause.
						</li>
						<li>
							Pregnancy and birth complications: Periodontitis has been linked to premature
							birth and low birth weight.
						</li>
						<li>
							Pneumonia: Certain bacteria in your mouth can be pulled into your lungs,
							causing pneumonia and other respiratory diseases.
						</li>
					</ul>
				

				
					<h5>Symptoms of Dental and Oral Problems</h5>
					<p>
						You shouldn’t wait until you have symptoms to visit your dentist. Going to the
						dentist twice a year will usually allow them to catch a problem before you even
						notice any symptoms. If you experience any of the following warning signs of dental
						health issues, you should make an appointment to see your dentist as soon as
						possible:
					</p>
					<ul>
						<li>
							Ulcers, sores, or tender areas in the mouth that won’t heal after a week or
							two.
						</li>
						<li>Bleeding or swollen gums after brushing or flossing.</li>
						<li>Chronic bad breath.</li>
						<li>Sudden sensitivity to hot and cold temperatures or beverages.</li>
						<li>Pain or toothache.</li>
						<li>Loose teeth.</li>
						<li>Receding gums.</li>
						<li>Swelling of the face and cheek.</li>
					</ul>
				

				
					<h5>How to Protect Your Oral Health</h5>
					<p>To protect your oral health, practice good oral hygiene daily:</p>
					<ul>
						<li>Brush your teeth at least twice a day for two minutes each time.</li>
						<li>
							Floss daily, use mouthwash to remove food particles left after brushing and
							flossing.
						</li>
						<li>Eat a healthy diet and limit sugary food and drinks.</li>
						<li>Replace your toothbrush every three to four months.</li>
						<li>Avoid tobacco use.</li>
					</ul>
				

				{/* <h5>references:</h5>
				<a
					target='_blank'
					href='https://www.healthline.com/health/dental-and-oral-health#types-of-diseases '
					rel='noopener noreferrer'>
					{' '}
					www.healthline.com
				</a>
				<a
					target='_blank'
					href='https://www.hsph.harvard.edu/nutritionsource/oral-health/ '
					rel='noopener noreferrer'>
					www.hsph.harvard.edu{' '}
				</a>
				<a
					target='_blank'
					href='https://www.mayoclinic.org/healthy-lifestyle/adult-health/in-depth/dental/art-20047475 '
					rel='noopener noreferrer'>
					mayoclinic{' '}
				</a>
				<a
					target='_blank'
					href='https://www.healthline.com/health/dental-and-oral-health#symptoms'
					rel='noopener noreferrer'>
					{' '}
					healthline
				</a>
				<a
					target='_blank'
					href='https://www.mayoclinic.org/healthy-lifestyle/adult-health/in-depth/dental/art-20047475'
					rel='noopener noreferrer'>
					{' '}
					mayoclinic
				</a> */}
			</article>
		),
	},
	{
		id: 3,
		imgAR: 'assets/articles/seeDoctorAR.webp',
		imgEN: 'assets/articles/seeDoctorEN.webp',
		descAR: (
			<article dir='rtl'>
				<h4>الأعراض المرضيّة الشّائعة: متى يجب مراجعة الطّبيب؟ </h4>

				<h5>مقدّمة: </h5>
				<p>
					من الممكن أن نعتبر بعض الأعراض أو المشاكل الصّحّيّة عوامل عابرة وعرضيّة، ولكن من المهمّ
					معرفة الحدود الصّحّيّة لهذه الحالات، وتحديد الوقت والحالة المناسبة لمراجعة الطّبيب، لمعرفة
					السّبب الحقيقيّ لهذه الحالات، وكخطوةٍ استباقيّة تساعدنا على{' '}
					<span style={{ textDecoration: 'underline' }}>التّشخيص المبكّر</span> لأيّ حالاتٍ مرضيّةٍ
					خطيرة.{' '}
				</p>
				<h5>متى يصبح الزّكام حالةً تستدعي مراجعة الطّبيب؟ </h5>
				<p>
					الزّكام هو حالةٌ شائعة تنتج عن إصابةٍ فيروسيّة، تكون أعراضها مختلفةً من شخصٍ لآخر ولكن
					تتضمّن عموماً: ارتفاع طفيف بدرجة الحرارة، سيلان أو انسداد الأنف، ألم الحلق،{' '}
					<span style={{ textDecoration: 'underline' }}>السّعال،</span> العطاس، التّعب والوهن
					العامّ.{' '}
				</p>

				<p>يجب مراجعة الطّبيب عندما تظهر الأعراض التّالية: </p>
				<ul>
					<li>عدم تحسّن الأعراض بعد 7 إلى 1- أيام من بدئها. </li>
					<li>ارتفاع درجة الحرارة فوق 38.5 درجة مئوية.</li>
					<li>عودة الحمّى بعد فترة من زوالها. </li>
					<li>صعوبة التّنفّس. </li>
					<li>صوت الصفير الخفيف المرافق لتضيق القصبات.</li>
					<li>ألم شديد في الحلق، الرّأس، أو الجيوب الأنفيّة. </li>
				</ul>

				<h5>متى يصبح الإسهال حالة تستدعي مراجعة الطّبيب؟ </h5>

				<ul>
					<li>
						تتعدّد العوامل المسبّبة{' '}
						<span style={{ textDecoration: 'underline' }}>للإسهال،</span> فقد ينتج عن إصابة
						فيروسيّةٍ، أو جرثوميّة، أو الحساسية تجاه بعض الأطعمة، أو بعض الأمراض كداء كرون.
					</li>
					<li>
						عادةً، يزول الإسهال بشكلٍ تلقائيّ مع مرور وقتٍ قصيرٍ يحتاجه الجسم للتّعافي، ولكن من
						المهمّ التّواصل مع الطّبيب في الحالات التّالية:{' '}
					</li>
					<li>استمرار الإسهال أكثر من يومين. </li>
					<li>ارتفاع درجة الحرارة أكثر من 38.5 درجة مئوية.</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>الإسهال الدّمويّ،</span> أو{' '}
						<span style={{ textDecoration: 'underline' }}>البراز أسود اللون</span>.{' '}
					</li>
					<li>الغثيان أو التقيّؤ الذي يمنع من شرب السّوائل لتعويض السّوائل المفقودة. </li>
					<li>الإسهال بعد العودة من دولةٍ أجنبيّة. </li>
				</ul>

				<h5>تى يصبح الصدّاع حالةً تستدعي مراجعة الطّبيب؟ </h5>
				<ul>
					<li>
						يوجد حوالي 300 نوع من مسببات الصّداع، وتختلف هذه الأنواع بنمط الألم والعلاج، وقد تمّ
						تصنيف أنواع <span style={{ textDecoration: 'underline' }}>الصّداع</span> في مجموعتين
						رئيسيّتين هما{' '}
						<span style={{ textDecoration: 'underline' }}>
							الصّداع الأساسيّ والصّداع الثّانويّ
						</span>
						.{' '}
					</li>
					<li>من المهمّ التّواصل مع الطّبيب بخصوص الصدّاع في الحالات التّالية: </li>
					<li>إذا كان الصّداع شديداً جدّاً، أكثر ألماً من أيّ صداعٍ سابق. </li>
					<li>صداع شديد مترافق بالغثيان، الإقياء، والحمّى. </li>
					<li>صداع مترافق بفقدان الإحساس أو الضعف في أي جزء من الجسم</li>
					<li>
						صداعٌ مستمرّ بعد{' '}
						<span style={{ textDecoration: 'underline' }}>إصابة حديثة للرّأس</span>.{' '}
					</li>
					<li>
						تغيّر الأعراض المعتادة{' '}
						<span style={{ textDecoration: 'underline' }}>لصداع الشّقيقة</span>.{' '}
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>الصداع الصباحيّ المتكرّر</span>.{' '}
					</li>
				</ul>

				<h5>متى يصبح ألم الظّهر حالة تستدعي مراجعة الطّبيب؟ </h5>
				<p>
					يعدّ ألم الظّهر حالةً شائعة، تزداد مع التّقدّم بالعمر وتختلف حدّتها بين شخصٍ وآخر حسب نمط
					الحياة ونوع الأعمال اليوميّة المتعلّقة بالمريض.{' '}
				</p>
				<p>
					إضافةً إلى كون هذه الحالة مؤثّرة بشكلٍ كبير على جودة الحياة اليوميّة للفرد، فإنّها قد تكون
					مؤشّراً لإصاباتٍ أخرى مهدّدة للصّحّة.{' '}
				</p>
				<p>من المهم التّواصل مع الطّبيب عند ملاحظة أيّ من العلامات التاليّة: </p>
				<ul>
					<li>استمرار الألم وعدم زواله. </li>
					<li>
						انتشار الألم إلى الأطراف السّفليّة، فقد يدلّ ذلك على إصابة{' '}
						<span style={{ textDecoration: 'underline' }}>القرص الفقريّ</span> في العمود
						الفقريّ.{' '}
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>
							الخدر، التنميل، وفقدان الإحساس بالأطراف.
						</span>{' '}
					</li>
					<li>الألم في الظّهر بعد حادثٍ ما. </li>
					<li>الألم في الظّهر الذي يسوء في أوقاتٍ معيّنة كالمساء، ووضعيّات معيّنة كالاستلقاء. </li>
					<li>الحمّى المترافقة مع ألم الظّهر. </li>
					<li>ألم الظّهر المترافق مع مشاكل في التّغوّط أو التّبوّل. </li>
					<li>ألم الظّهر المترافق مع فقدان الوزن غير المفسر. </li>
				</ul>
				<p>
					قد تعتبر بعض الأعراض مشاكلاً بسيطةً عابرة، ولكنّ درهمَ وقايةٍ خيرٌ من قنطار علاج، ويجب
					التّواصل مع الطّبيب فور ملاحظة أيّ عرضٍ غير معتاد للحفاظ على الصّحّة الجسديّة بأفضل صورة
					ممكنة.{' '}
				</p>
				{/* <h5>المصادر:</h5>
				<a
					target='_blank'
					href='https://www.mayoclinic.org/diseases-conditions/common-cold/symptoms-causes/syc-20351605#:~:text=However%2C%20seek%20medical%20attention%20if,after%20a%20fever%2Dfree%20period'
					rel='noopener noreferrer'>
					mayoclinic
				</a>
				<a
					target='_blank'
					href='https://www.webmd.com/digestive-disorders/digestive-diseases-diarrhea '
					rel='noopener noreferrer'>
					webmd
				</a>
				<a
					target='_blank'
					href='https://www.premierhealth.com/your-health/articles/women-wisdom-wellness-/should-i-call-the-doctor-for-my-headache- '
					rel='noopener noreferrer'>
					{' '}
					premierhealth
				</a>
				<a
					target='_blank'
					href='https://www.everydayhealth.com/back-pain/back-pain-treatment-doctor.aspx'
					rel='noopener noreferrer'>
					{' '}
					everydayhealth
				</a> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Common Pathological Symptoms: When Should You Check with Your Doctor?</h4>
				

				
					<h5>Introduction</h5>
					<p>
						Some symptoms or health problems can be considered normal or effectless, but it is
						important to know the health limits of these conditions, and to determine the
						appropriate time to visit the doctor, to find out the true cause of these
						conditions, and as a proactive step that helps with the early diagnosis of any
						serious illness.
					</p>
				

				
					<h5>Consultation for a Cold</h5>
					<p>
						The common cold is a condition that’s caused by a virus exposure, symptoms vary, but
						they can generally include: runny or stuffy nose, sore or scratchy throat, cough,
						sneezing, generally feeling unwell, slight body aches or a mild headache, low-grade
						fever.
					</p>
					<h6>When to See a Doctor</h6>
					<p>Consult your doctor if you have:</p>
					<ul>
						<li>Symptoms that get worse or do not get better.</li>
						<li>Fever greater than 38.5 degrees Celsius that lasts more than three days.</li>
						<li>Fever returning after a fever-free period.</li>
						<li>Shortness of breath.</li>
						<li>Wheezing.</li>
						<li>Intense sore throat, headache or sinus pain.</li>
					</ul>
				

				
					<h5>Consultation for Diarrhea</h5>
					<p>
						Diarrhea may result from multiple agents like: viral infections, microbial
						infections or certain diseases such as irritable bowels syndrome (ibs).
					</p>
					<h6>When to See a Doctor</h6>
					<p>
						Usually, diarrhea automatically goes away after a short while which the body needs
						to recover, but it is important to see the doctor in the following cases:
					</p>
					<ul>
						<li>Blood in your diarrhea or black, tarry stools.</li>
						<li>A fever that is above 38 C or that lasts more than 24 hours.</li>
						<li>Diarrhea lasting longer than 2 days.</li>
						<li>
							Nausea or throwing up that prevents you from drinking liquids to replace lost
							fluids.
						</li>
						<li>Diarrhea after coming back from a foreign country.</li>
					</ul>
				

				
					<h5>Consultation for Headaches</h5>
					<p>
						There are about 300 types of headaches. These vary in causes, pain patterns and
						treatments. Headache types have been categorized in two main groups: primary
						headaches and secondary headaches. Primary headaches are caused by external factors
						such as fatigue and stress, and secondary headaches are caused by internal factors
						such as other diseases.
					</p>
					<h6>When to See a Doctor</h6>
					<p>In the following cases, it is important to consult your doctor about your headache:</p>
					<ul>
						<li>If the headache is too severe, more painful than any previous headache.</li>
						<li>Severe headaches accompanied by nausea, vomiting, and fever.</li>
						<li>
							A headache associated with numbness, weakness, or loss of sensation in a part
							of the body may indicate a stroke.
						</li>
						<li>A constant headache after a head injury.</li>
						<li>Unfamiliar migraine symptoms.</li>
						<li>Morning headaches.</li>
					</ul>
				

				
					<h5>Consultation for Back Pain</h5>
					<p>
						Back pain is a common condition, it increases with age and varies in severity from
						person to person depending on their lifestyle and job. In addition to having a
						significant impact on an individual's quality of life, this can be an indicator of
						other health-threatening injuries.
					</p>
					<h6>When to See a Doctor</h6>
					<p>It is important to consult the doctor if you notice any of the following signs:</p>
					<ul>
						<li>Pain that won't go away.</li>
						<li>
							Severe back pain that extends beyond the back, especially all the way to the
							bottom of your leg. This could indicate something more serious than a strained
							muscle, such as a damaged disk in your back.
						</li>
						<li>Numbness, tingling, or weakness.</li>
						<li>Pain after an accident.</li>
						<li>
							Pain that is worse at certain times, such as lying down. Increased pain at
							night is also a warning sign of something more serious.
						</li>
						<li>Fever.</li>
						<li>Unexplained weight loss.</li>
						<li>Problems with your bowels or urination.</li>
					</ul>
				

					<p>
						Some symptoms may be considered minor or unimportant, but it's better being safe
						than sorry, and the doctor should be consulted as soon as you notice unusual signs
						to maintain the best physical health possible.
					</p>
				
				{/* <h5>references:</h5>
				<a
					target='_blank'
					href='https://www.mayoclinic.org/diseases-conditions/common-cold/symptoms-causes/syc-20351605#:~:text=However%2C%20seek%20medical%20attention%20if,after%20a%20fever%2Dfree%20period'
					rel='noopener noreferrer'>
					mayoclinic
				</a>
				<a
					target='_blank'
					href='https://www.webmd.com/digestive-disorders/digestive-diseases-diarrhea '
					rel='noopener noreferrer'>
					webmd
				</a>
				<a
					target='_blank'
					href='https://www.premierhealth.com/your-health/articles/women-wisdom-wellness-/should-i-call-the-doctor-for-my-headache- '
					rel='noopener noreferrer'>
					{' '}
					premierhealth
				</a>
				<a
					target='_blank'
					href='https://www.everydayhealth.com/back-pain/back-pain-treatment-doctor.aspx'
					rel='noopener noreferrer'>
					{' '}
					everydayhealth
				</a> */}
			</article>
		),
	},
	{
		id: 4,
		imgEN: 'assets/articles/04-medications1.webp',
		imgAR: 'assets/articles/04-medications2.webp',
		descAR: (
			<article dir='rtl'>
				<h4>الأدوية وأنواعها، ماهي المصطلحات التي يجب أن تعرفها عنها؟</h4>
				<h5>مقدّمة:</h5>
				<p>
					بتطوّر العلم، وتعدّد الأنواع المكتشفة والمصنّعة من الأدوية ظهرت الحاجة لتصنيف هذه الأدوية
					بشكلٍ علميّ ٍدقيق، ووضع القوانين والأساليب المنظّمة لاستخدامها.
				</p>
				<h5>من الممكن تصنيف الأدوية حسب عدّة معايير نذكر منها:</h5>
				<ul>
					<li>التّصنيف حسب آليّة العمل، أو التّأثير الفيزيولوجي، أو البنية الكيميائيّة.</li>
					<li>التّصنيف حسب التّسمية الكيميائيّة العلاجيّة التّشريحيّة (ATC).</li>
				</ul>
				<h5>ولكنّ المصطلحات العامّة التي تُهمّك هي المصطلحات المذكورة في المقال:</h5>
				<ul>
					<li>أدوية OTC.</li>
					<li>أدوية RX.</li>
					<li>تصنيف الأدوية حسب أمانها على الحامل.</li>
				</ul>

				<h5>ما هي أدوية OTC؟</h5>
				<p>تسمّى بالأدوية التي لا تستلزم وصفة طبّيّة، ويمكن بيعها مباشرةً للمرضى من قبل الصيدلاني.</p>
				<p>
					تعالج هذه الأدوية مجموعة من الأمراض والأعراض نذكر منها: الألم، السّعال، نزلات البرد،
					الإسهال، الإمساك، وحبّ الشّباب.
				</p>
				<p>
					كون هذه الأدوية لا تستلزم وصفة طبّيّة لا يعني أنّ إساءة استخدامها لا يحمل مخاطر صحّيّة، من
					الممكن أن تسبّب هذه الأدوية الإدمان، التّسمّم بزيادة الجرعة، والتّداخل مع الأنواع الأخرى
					من الأدوية.
				</p>
				<p>
					لذلك من المهمّ الالتزام بتعليمات الصّيدلانيّ والتحقّق من الجرعة الدّوائيّة قبل تناول
					الدّواء.
				</p>
				<h5>ماهي أدوية RX؟</h5>
				<p>
					هي الأدوية التي لا يمكن الحصول عليها إلّا بوصفة طبّيّة يحدّد فيها الطّبيب الدّواء وعياره
					والجرعة ومدّة تناول الدّواء والشكل الدوائيّ وطريقة الاستعمال.
				</p>
				<p>يجب أخذ هذه الادوية من قِبل المريض الذي وصفت له فقط.</p>
				<h5>كيف كان يتم تصنيف الأدوية حسب أمانها على الحامل؟</h5>
				<p>وُجدت 5 تصنيفات أساسيّة للأدوية حسب أمانها على الحوامل نوضحها لك تالياً:</p>
				<ul>
					<li>
						الفئة A: لا توجد تأثيرات ضارّة حسب الدّراسات على البشر (لم تثبت الدّراسات التي أجريت
						على النّساء الحوامل وجود خطر على الجنين خلال الأشهر الثّلاثة الأولى من الحمل).
					</li>
					<li>
						الفئة B: لا توجد تأثيرات ضارّة حسب الدّراسات على الحيوانات (لا توجد دراسات كافية على
						البشر، كما أن الدراسات على الحيوانات لم توضح خطر على الجنين).
					</li>
					<li>
						الفئة C: لا يمكن استبعاد الخطر، لا توجد دراسات كافية على النّساء الحوامل، لكن
						الدّراسات على الحيوانات أوضحت وجود خطرٍ على الجنين، قد تفوق الفوائد المحتملة للدّواء
						مخاطره.
					</li>
					<li>
						الفئة D: توجد أدلّة على الخطر، أوضحت الدّراسات التي أجريت على النّساء الحوامل وجود
						خطرٍ على الجنين، وقد تفوق الفوائد المحتملة للدّواء مخاطره.
					</li>
					<li>
						الفئة X: الأدوية الممنوعة، أثبتت الدّراسات التي أجريت على النّساء الحوامل وجود خطر
						على الجنين، و\أو أظهرت الدّراسات التي أجريت على الإنسان أو الحيوان وجود شذوذات
						جنينيّة، مخاطر الدواء تفوق الفوائد المحتملة.
					</li>
				</ul>
				{/* <h5>المصادر:</h5>
				<p>
					<a
						target='_blank'
						href='https://www.verywellhealth.com/drug-classes-1123991 '
						rel='noopener noreferrer'>
						verywellhealth{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://nida.nih.gov/publications/drugfacts/over-counter-medicines#:~:text=What%20are%20over%2Dthe%2Dcounter,constipation%2C%20acne%2C%20and%20others'
						rel='noopener noreferrer'>
						nida.nih.gov{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.healthinaging.org/tools-and-tips/ask-expert-over-counter-and-prescription-drugs#:~:text=To%20obtain%20these%20medications%2C%20a,medications%20available%20without%20a%20prescription'
						rel='noopener noreferrer'>
						healthinaging{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.ncbi.nlm.nih.gov/books/NBK507858/#:~:text=Category%20A%3A%20No%20risk%20in,Risk%20cannot%20be%20ruled%20out'
						rel='noopener noreferrer'>
						ncbi{' '}
					</a>
				</p> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Medications and Their Types: What Are the Terms You Should Know?</h4>
				

				
					<h5>Introduction</h5>
					<p>
						With the evolution of science, the number of discovered and manufactured types of
						medications has emerged the need to classify these medications in an accurate
						scientific manner, and to develop laws and methods governing their use. Medications
						can be classified according to several criteria, including:
					</p>
					<ul>
						<li>Classification by mechanism of action, impact, and chemical structure.</li>
						<li>Classification by Anatomical Therapeutic Chemical (ATC).</li>
					</ul>
					<p>But the general terms that matter to you are those mentioned in the article:</p>
					<ul>
						<li>OTC drugs.</li>
						<li>RX drugs.</li>
						<li>Drug classification based on safety to use by pregnant women.</li>
					</ul>
				

				
					<h5>What are OTC Drugs?</h5>
					<p>
						Over-the-counter (OTC) medicines are those that can be sold directly to people
						without a prescription. OTC medicines treat a variety of illnesses and their
						symptoms including pain, coughs and colds, diarrhea, constipation, acne, and others.
						Some OTC medicines have active ingredients with the potential for misuse at
						higher-than-recommended dosages which can cause addiction, overdoses, and unwanted
						effects from mixing medications. It is therefore important to follow the
						pharmacist's instructions and double check the dose before taking it.
					</p>
				

				
					<h5>What are Rx Drugs?</h5>
					<p>
						Medications that can only be obtained with a prescription in which the doctor
						determines the drug, dose and duration treatment, they have serious effects on
						people who take them without the need to do so. These medications should only be
						taken by the patient to whom they were prescribed.
					</p>
				

				
					<h5>Classification of Medications Based on Their Safety on Pregnant Women</h5>
					<p>
						There are 5 basic classifications of medications according to their safety on
						pregnant women:
					</p>
					<ul>
						<li>
							Category A: No risk in human studies (studies in pregnant women have not
							demonstrated a risk to the fetus during the first trimester).
						</li>
						<li>
							Category B: No risk in animal studies (there are no adequate studies in
							humans, but animal studies did not demonstrate a risk to the fetus).
						</li>
						<li>
							Category C: Risk cannot be ruled out. There are no satisfactory studies in
							pregnant women, but animal studies demonstrated a risk to the fetus; potential
							benefits of the drug may outweigh the risks.
						</li>
						<li>
							Category D: Evidence of risk (studies in pregnant women have demonstrated a
							risk to the fetus; potential benefits of the drug may outweigh the risks).
						</li>
						<li>
							Category X: Contraindicated (studies in pregnant women have demonstrated a
							risk to the fetus, and/or human or animal studies have shown fetal
							abnormalities; risks of the drug outweigh the potential benefits).
						</li>
					</ul>
				
{/* 
				<h5>references:</h5>
				<p>
					<a
						target='_blank'
						href='https://www.verywellhealth.com/drug-classes-1123991 '
						rel='noopener noreferrer'>
						verywellhealth{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://nida.nih.gov/publications/drugfacts/over-counter-medicines#:~:text=What%20are%20over%2Dthe%2Dcounter,constipation%2C%20acne%2C%20and%20others'
						rel='noopener noreferrer'>
						nida.nih.gov{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.healthinaging.org/tools-and-tips/ask-expert-over-counter-and-prescription-drugs#:~:text=To%20obtain%20these%20medications%2C%20a,medications%20available%20without%20a%20prescription'
						rel='noopener noreferrer'>
						healthinaging{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.ncbi.nlm.nih.gov/books/NBK507858/#:~:text=Category%20A%3A%20No%20risk%20in,Risk%20cannot%20be%20ruled%20out'
						rel='noopener noreferrer'>
						ncbi{' '}
					</a>
				</p> */}
			</article>
		),
	},
	{
		id: 5,
		imgEN: 'assets/articles/05-pregnancy.webp',
		imgAR: 'assets/articles/05-pregnancy2.webp',
		descAR: (
			<article dir='rtl'>
				<h4>رحلة الحمل المميّزة، نظرة عامّة على الرّحلة قبل الخوض بغمارها..</h4>

				<h5>مقدمة:</h5>
				<p>رحلة الحمل هي إحدى أجمل الرّحلات التي تمرّ بها العائلة ككلّ، والأمّهات بشكلٍ خاصٍّ.</p>
				<p>
					تحدث الكثير من التّغيّرات الجسديّة والهرمونيّة والنّفسيّة خلال فترة الحمل، وترتبط كلّ منها
					بالمرحلة التي بلغتها الأمّ من حملها.
				</p>
				<p>
					مع تقدّم الحمل، تحدث التّغيّرات أيضاً على الجنين، إذ تتم عمليّات التّشكّل بترتيبٍ محدّد
					يرتبط بأوقات ومواعيد معيّنة خلال الحمل، والتي من المهم معرفتها للحصول على العناصر
					الغذائيّة التي يحتاجها الجنين بكمّيّات مناسبة.
				</p>

				<h5>يقسم الحمل إلى فتراتٍ زمنيّة هي:</h5>
				<ul>
					<li>الثّلث الأوّل (من حدوث الحمل إلى 12 أسبوع).</li>
					<li>الثّلث الثّاني (من 12 أسبوع حتى 24 أسبوع).</li>
					<li>الثّلث الثّالث (من 24 أسبوع حتى 40 أسبوع).</li>
				</ul>

				<h5>نظرة عامّة على الثّلث الأوّل:</h5>
				<p>
					يشمل الثّلث الأوّل الأشهر الثّلاثة الأولى من الحمل، وفي هذا الثّلث ينمو جسمك ويتغير بسرعة،
					بالإضافة للنّمو السّريع للجنين أيضاً، إذ في نهاية هذه المرحلة يصل حجمه إلى حجم مماثل لحجم
					ليمونة.
				</p>
				<p>قد تبدأ أعراض الحمل بالظّهور مثل الغثيان، ألم الثّدي، زيادة الحاجة للتّبول.</p>
				<h5>نظرة عامة على الثّلث الثّاني:</h5>
				<p>يشمل الثّلث الثّاني من الحمل الأشهر 4 5 6 من الحمل، ويعتبر نقطة تحوّل للأمّ والجنين.</p>
				<p>
					غالباً ما يُصاحب الثلث الثاني من الحمل بتراجع أعراض الغثيان وآلام الثدي وأعراض الحمل
					الأخرى بشكل مقبول. كما يظهر الحمل على الأم بشكلٍ أوضح.
				</p>
				<p>يطوّر الجنين في هذه المرحلة جميع أعضائه، ويبدأ بعدها بالنّمو من ناحيتيّ الوزن والطّول.</p>
				<p>
					يزداد الحبل السّريّ ثخانةً في هذا الثّلث، فهو يحمل المغذّيات المختلفة للجنين، ولكن الموادّ
					الضّارّة قد تمرّ أيضاً عبره، وهذا ما يستدعي الحذر من التّدخين، الكحول، تناول الأدوية بشكلٍ
					عشوائيّ، وغيرها من المخاطر المعروفة.
				</p>
				<h5>نظرة عامّة على الثّلث الثّالث:</h5>
				<p>
					يشمل الثّلث الثّالث من الحمل الأشهر الثّلاثة الأخيرة، يستمرّ الجنين بالنّموّ من حيث الطّول
					والوزن، وتنهي أجهزة الجسم نضوجها. قد تكون هذه المرحلة غير مريحة للأم خصوصاً مع زيادة الوزن
					وبدء تّقلّصات المخاض الكاذبة (تقلّصات براكستون هيكس).
				</p>
				<p>
					لكن الرّحلة تقارب الوصول لنهايتها الآن، من المهمّ أن تكون الأم جاهزة من حيث المعلومات ومن
					حيث الإمكانيّات.
				</p>
				<p>
					قومي بالاطلاع على مصادر المعلومات الموثوقة وتجهيز الأغراض اللّازمة قبل وقتٍ كافٍ من موعد
					الولادة.
				</p>
{/* 
				<h5>المصادر:</h5>
				<p>
					<a
						target='_blank'
						href='https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-week-by-week'
						rel='noopener noreferrer'>
						betterhealth
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/articles/9699-first-trimester#:~:text=During%20the%20first%20trimester%20of%20pregnancy%2C%20your%20body%20is%20growing,needing%20to%20pee%20more%20often'
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.hopkinsmedicine.org/health/wellness-and-prevention/the-second-trimester#:~:text=The%20second%20trimester%20marks%20a,growing%20in%20length%20and%20weight'
						rel='noopener noreferrer'>
						hopkinsmedicine
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.hopkinsmedicine.org/health/wellness-and-prevention/the-third-trimester'
						rel='noopener noreferrer'>
						hopkinsmedicine
					</a>
				</p> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>The Amazing Pregnancy Journey: An Overview of the Trip Before Going Through It</h4>
				

				
					<h5>Introduction</h5>
					<p>
						The pregnancy journey is one of the most beautiful trips the whole family
						(particularly the mother) can go through. Many physical, hormonal, and psychological
						changes occur to the mother during pregnancy, each associated with the stage of
						pregnancy. As pregnancy progresses, changes also occur in the fetus. Formation
						processes take place in a specific order associated with certain times and dates
						during pregnancy, which is important to know about to obtain the nutrients the fetus
						needs in appropriate quantities. Pregnancy is divided into three trimesters:
					</p>
					<ul>
						<li>First trimester – conception to 12 weeks.</li>
						<li>Second trimester – 12 to 24 weeks.</li>
						<li>Third trimester – 24 to 40 weeks.</li>
					</ul>
				

				
					<h5>Overview of the First Trimester</h5>
					<p>
						The first trimester includes the first three months of pregnancy. During the first
						trimester of pregnancy, your body is growing and changing rapidly. The fetus is
						growing and developing, too. In fact, by the end of the first trimester, the fetus
						is the size of a lemon. You may begin having symptoms of pregnancy like nausea, sore
						breasts, or needing to pee more often.
					</p>
				

				
					<h5>Overview of the Second Trimester</h5>
					<p>
						This trimester includes the months 4-5-6 of the pregnancy. The second trimester
						marks a turning point for the mother and fetus. You will usually begin to feel
						better and start showing the pregnancy more. Your fetus has now developed all its
						organs and systems and will now begin growing in length and weight. During the
						second trimester, the umbilical cord continues to thicken as it carries nourishment
						to the fetus. However, harmful substances also pass through the umbilical cord to
						the fetus, so care should be taken to avoid alcohol, tobacco, and other known
						hazards.
					</p>
				

				
					<h5>Overview of the Third Trimester</h5>
					<p>
						This trimester includes the last 3 months of the pregnancy. The fetus is continuing
						to grow in weight and size, and the body systems finish maturing. You may feel more
						uncomfortable now as you continue to gain weight and begin to have false labor
						contractions (called Braxton-Hick’s contractions). During the third trimester, it is
						a good idea to start taking childbirth classes in preparation for the big day.
					</p>
				
				{/* <h5>references:</h5>
				<p>
					<a
						target='_blank'
						href='https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-week-by-week'
						rel='noopener noreferrer'>
						betterhealth
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/articles/9699-first-trimester#:~:text=During%20the%20first%20trimester%20of%20pregnancy%2C%20your%20body%20is%20growing,needing%20to%20pee%20more%20often'
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.hopkinsmedicine.org/health/wellness-and-prevention/the-second-trimester#:~:text=The%20second%20trimester%20marks%20a,growing%20in%20length%20and%20weight'
						rel='noopener noreferrer'>
						hopkinsmedicine
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.hopkinsmedicine.org/health/wellness-and-prevention/the-third-trimester'
						rel='noopener noreferrer'>
						hopkinsmedicine
					</a>
				</p> */}
			</article>
		),
	},
	{
		id: 6,
		imgEN: 'assets/articles/06-cholestrol.webp',
		imgAR: 'assets/articles/06-cholestrol2.webp',
		descAR: (
			<article dir='rtl'>
				<h4>الكوليسترول، ماهو؟ وما أنواعه المختلفة؟</h4>
				<h5>مقدّمة:</h5>
				<p>
					من المهمّ فهم المصطلحات الأساسيّة المتعلّقة بالصّحّة، لذلك من المهم فهم معنى مصطلح
					"الكولّيسترول"، والتّمييز بين أنواعه المختلفة.
				</p>
				<p>
					عموماً، الكوليسترول هو مادة شمعيّة دهنيّة، موجودة في جميع خلايا الجسم، والذي يستخدمها في
					صناعة الهرمونات، فيتامين D، والمواد التي تساعد على الهضم.
				</p>
				<p>
					يستطيع الجسم صناعة حاجته من الكولّيسترول، ويمكنه الحصول عليه من مصادر حيوانيّة، كصفار
					البيض، اللّحوم، والأجبان.
				</p>

				<h5>ما هي أنواع الكولّيسترول؟</h5>
				<p>
					ينتقل الكولّيسترول عبر الدّم مرتبطاً ببروتينات تسمى (البروتينات الدهنيّة). يحمل نوعان من
					البروتينات الدّهنيّة الكولّيسترول في جميع أنحاء الجسم:
				</p>
				<ul>
					<li>
						LDL : يشكل كوليسترول (البروتين الدّهنيّ منخفض الكثافة) ، والذي يسمّى أحياناً
						الكولّيسترول (الضّارّ)، معظم الكولّيسترول في الجسم. وتزيد المستويات العالية من
						الكولّيسترول الضّارّ من خطر الإصابة بأمراض القلب والسكتة الدّماغيّة.
					</li>
					<li>
						HDL : يمتصّ للكوليسترول (البروتين الدّهنيّ عالي الكثافة) ، الذي يسمّى أحياناً
						الكولّيسترول (الجيّد). الكولّيسترول في الدّم ويعيده إلى الكبد، ثم يطرحه الكبد من
						الجسم. يمكن أن تقلّل المستويات العالية من الكولّيسترول الجيد من خطر الإصابة بأمراض
						القلب والسّكتة الدّماغيّة.
					</li>
				</ul>
				<p>
					عندما تزداد كميّاّت كولّيسترول LDL في الجسم يمكن أن يتراكم على جدران الأوعية الدّمويّة.
					يسمّى هذا التّراكم (اللويحة)، ويمكن أن يسبّب مشاكل صحّيّة، مثل أمراض القلب والسّكتة
					الدّماغيّة.
				</p>

				<h5>ما هو التريغليسريد؟</h5>
				<p>
					هي نوع من الدّهون، وتشكّل النّسبة الأكبر من الدّهون في الجسم، تأتي من الأطعمة كالزّبدة
					والزّيوت، بالإضافة إلى السّعرات الحراريّة التي لا يحتاجها الجسم على الفور والتي يتمّ
					تخزينها على شكل تريغليسيريد في الخلايا الدّهنيّة.
				</p>
				<p>
					عندما يحتاج الجسم إلى الطّاقة فإنّه يطلق هذه الدّهون، يعدّ بعضها ضروريّاً لصحّة جيّدة،
					ولكنّ ارتفاع مستوياتها في الدّم قد يزيد من خطر الإصابة بأمراض القلب والسّكتة الدّماغيّة.
				</p>
				<h5>ما هي أفضل النّصائح لخفض مستويات الكولّيسترول والتريغليسيريد؟</h5>

				<ul>
					<li>توقّف عن تناول الكحول، أو قلّل من تناول الكحول حتّى 1 أو 2 مشروب في اليوم.</li>
					<li>
						توقّف عن التّدخين، يزيد التّدخين من قدرة كولّيسترول LDL على دخول خلايا الشّرايين
						وإحداث ضرر وتلف فيها.
					</li>
					<li>
						مارس الرّياضة بانتظام، تزيد التّمارين من مستويات HDL مع تقليل مستويات LDL
						والتريغليسيريد في الجسم، على سبيل المثال: قم بالمشي لمدّة 30 دقيقة يوميّاً.
					</li>
					<li>
						حاول التّخلّص من الوزن الزّائد، إذ أنّ السّمنة قد تساهم في ارتفاع مستويات
						التريعليسيريد وLDL في الدم.
					</li>
					<li>
						لدى مرضى السّكّري، قم بالتّأكّد من ضبط مستويات السّكر في دمك، إذ يرتبط ارتفاع نسبة
						السّكر في الدّم بزيادة خطر الإصابة بالتّصلّب الصّعيديّ، النّوبات القلبيّة، والسّكتات
						الدّماغيّة.
					</li>
				</ul>
				{/* <h5>المصادر:</h5>
				<p>
					<a
						target='_blank'
						href='https://medlineplus.gov/cholesterol.html'
						rel='noopener noreferrer'>
						https://medlineplus.gov/cholesterol.html
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.cdc.gov/cholesterol/ldl_hdl.htm#:~:text=LDL%20(low%2Ddensity%20lipoprotein),for%20heart%20disease%20and%20stroke'
						rel='noopener noreferrer'>
						cdc.gov{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.nhlbi.nih.gov/health/high-blood-triglycerides#:~:text=Triglycerides%20are%20a%20type%20of,does%20not%20need%20right%20away'
						rel='noopener noreferrer'>
						nhlbi.nih.gov{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/cholesterol'
						rel='noopener noreferrer'>
						betterhealth{' '}
					</a>
				</p> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Cholesterol: What Is It? What Are Its Different Types?</h4>
				

				
					<h5>Introduction</h5>
					<p>
						It is important to understand the basic terms related to our health, so it is
						important to understand the meaning of the term "cholesterol", and to understand its
						different types. Cholesterol is a waxy, fat-like substance that's found in all the
						cells in your body. Your body needs some cholesterol to make hormones, vitamin D,
						and substances that help you digest foods. Your body makes all the cholesterol it
						needs. Cholesterol is also found in foods from animal sources, such as egg yolks,
						meat, and cheese.
					</p>
				

				
					<h5>Types of Cholesterol</h5>
					<p>
						Cholesterol travels through the blood on proteins called “lipoproteins.” Two types
						of lipoproteins carry cholesterol throughout the body:
					</p>
					<ul>
						<li>
							LDL (low-density lipoprotein) cholesterol, sometimes called “bad” cholesterol,
							makes up most of your body’s cholesterol. High levels of LDL cholesterol raise
							your risk for heart disease and stroke.
						</li>
						<li>
							HDL (high-density lipoprotein) cholesterol, sometimes called “good”
							cholesterol, absorbs cholesterol in the blood and carries it back to the
							liver. The liver then flushes it from the body. High levels of HDL cholesterol
							can lower your risk for heart disease and stroke.
						</li>
					</ul>
					<p>
						When your body has too much LDL cholesterol, the LDL cholesterol can build up on the
						walls of your blood vessels. This buildup is called “plaque,” and it can cause
						health problems, such as heart disease and stroke.
					</p>
				

				
					<h5>What Are Triglycerides?</h5>
					<p>
						Triglycerides are a type of fat, called lipid, that circulate in your blood, they
						are the most common type of fat in your body. Triglycerides come from foods,
						especially butter, oils, and other fats you eat. Triglycerides also come from extra
						calories your body does not need right away. Unused calories are stored as
						triglycerides in fat cells. When your body needs energy, it releases the
						triglycerides. Some triglycerides are important for good health. However, high
						triglyceride levels in your blood can raise your risk of heart disease and stroke.
					</p>
				

				
					<h5>How to Lower Cholesterol and Triglyceride Levels?</h5>
					<p>What are the best tips for lowering cholesterol and triglyceride levels?</p>
					<ul>
						<li>
							Stop alcohol consumption or reduce your alcohol intake to no more than one or
							2 drinks a day.
						</li>
						<li>
							Don’t smoke. Smoking increases the ability of LDL cholesterol to get into
							artery cells and cause damage.
						</li>
						<li>
							Exercise regularly (for example, at least 30 minutes of brisk walking daily).
							Exercise increases HDL levels while reducing LDL and triglyceride levels in
							the body.
						</li>
						<li>
							Reduce excess body fat. Being overweight may contribute to raised blood
							triglyceride and LDL levels.
						</li>
						<li>
							Manage your blood sugar levels within your target range if you have diabetes.
							High blood sugars are linked to an increased risk of atherosclerosis
							(‘hardening of the arteries’), heart attacks and strokes.
						</li>
					</ul>

					{/* <h5>references:</h5>
					<p>
						<a
							target='_blank'
							href='https://medlineplus.gov/cholesterol.html'
							rel='noopener noreferrer'>
							https://medlineplus.gov/cholesterol.html
						</a>
					</p>
					<p>
						<a
							target='_blank'
							href='https://www.cdc.gov/cholesterol/ldl_hdl.htm#:~:text=LDL%20(low%2Ddensity%20lipoprotein),for%20heart%20disease%20and%20stroke'
							rel='noopener noreferrer'>
							cdc.gov{' '}
						</a>
					</p>
					<p>
						<a
							target='_blank'
							href='https://www.nhlbi.nih.gov/health/high-blood-triglycerides#:~:text=Triglycerides%20are%20a%20type%20of,does%20not%20need%20right%20away'
							rel='noopener noreferrer'>
							nhlbi.nih.gov{' '}
						</a>
					</p>
					<p>
						<a
							target='_blank'
							href='https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/cholesterol'
							rel='noopener noreferrer'>
							betterhealth{' '}
						</a>
					</p> */}
				
			</article>
		),
	},
	{
		id: 7,
		imgEN: 'assets/articles/07-MentalHealth1.webp',
		imgAR: 'assets/articles/07-MentalHealth2.webp',
		descAR: (
			<article dir='rtl'>
				<h4>الصّحّة النّفسيّة، ماهي؟ وكيف تحافظ عليها في أفضل حالاتها؟ </h4>

				<h5>مقدّمة: </h5>
				<p>
					تعرّف منظّمة الصّحة العالميّة مصطلح الصّحّة النّفسيّة على أنّه حالة من العافية النّفسيّة
					تمكّن النّاس من التّعامل مع ضغوط الحياة، توظيف قدراتهم، العمل والدّراسة بشكلٍ جيّد،
					والمساهمة في المجتمع.
				</p>
				<p>
					وتشير إلى كونها جزءاً لا يتجزّأ من الصّحة والعافية التي تدعم قدراتنا كأفرادٍ وكمجتمعات على
					اتّخاذ القرارات ، بناء العلاقات، والنّهوض بالعالم الذي نعيش فيه..{' '}
				</p>
				<p>فما خي الأعراض التي يجب أن تسندعي انتباهك؟ وما طرق تحسين صحّتك النّفسيّة والحفاظ عليها؟ </p>
				<p>يمكنك قراءة المزيد حول الصّحّة النّفسيّة في مقالنا التّالي..</p>
				<h5>ما هي الأعراض التي يجب تستدعي الانتباه للصّحة النّفسيّة؟ </h5>
				<p>الشّعور بالقلق والتّوتّر: </p>
				<p>
					جميعنا نشعر بنوعٍ من التّوتّر أو الهموم في الحياة اليوميّة ولكن من الممكن أن \يكون الشّعور
					بالقلق علامةً على مشاكل أكبر وأعمق، قد يترافق هذا القلق مع خفقان القلب، الصّداع، ضيق
					التّنفس، التعرّق وغيرها من الأعراض.{' '}
				</p>

				<p>الشّعور بالاكتئاب وعدم السّعادة: </p>
				<p>
					قد تكون علامات الاكتئاب على شكل الشّعور بالحزن وسرعة الانفعال لمدّة عدّة أسابيع، مع فقدان
					الحافزيّة ونقص الطّاقة، وفقدان الاهتمام بالهوايات.{' '}
				</p>
				<p>التغيّرات المفاجئة في المزاج: </p>
				<p>
					تختلف الحالة المزاجيّة لكل شخص، ولكنّ التّغيرات المفاجئة والكبيرة في المزاج، مثل الضّيق
					الشّديد أو الغضب، يمكن أن تكون عرضاً لعلّة نفسيّة.
				</p>
				<p>مشاكل وصعوبات النّوم: </p>
				<p>
					قد تؤثّر مشاكل النّوم على جودة الحياة وتدلّ على مشاكل أخرى، قد يكون الأرق دلالةً على
					القلق، وقد تكون زيادة النّوم دلالةً على الاكتئاب.{' '}
				</p>
				<p>تغيّرات الوزن المفاجئة: </p>
				<p>
					زيادة الوزن بشكلٍ مفاجىء أو خسارته بسرعة وبشكل كبير قد تكون دلالة على الإصابة باضطرابات
					نفسيّة (كاضطراب الأكل).
				</p>
				<p>الهدوء والانسحاب: </p>
				<p>
					إذا لاحظت على صديقٍ أو شخصٍ مقرّبٍ نوعاً من الانسحاب ومحاولات انعزال متكرّرة، خصوصاً بعد
					تغيّرات حياتيّة كبيرة ومفاجئة، فقد تكون دلالةً على الاكتئاب أو الإصابة باضطراب ثنائيّ
					القطب.{' '}
				</p>
				<p>الشّعور بالذّنب أو لوم النّفس: </p>
				<p>
					تتظاهر بعض المشاكل النّفسيّة على شكل انتقاداتٍ يوجّهها الشّخص لنفسه، أفكار كـ (أنا فاشل)،
					(أنا عديم الفائدة)، وغيرها من الأساليب الانتقاديّة للذّات قد تكون خطيرةً وقد تؤدّي -حال
					كونها شديدة- إلى الرّغبات والميول الانتحاريّة.{' '}
				</p>

				<h5>ما هي دراهم الوقاية التي يمكنك إنفاقها على صحّتك النّفسيّة؟ </h5>
				<p>ننصحك ببعض الممارسات والعادات للحفاظ على صحّتك النّفسيّة ونذكر لك منها: </p>
				<p>
					ممارسة التّمرينات الرّياضيّة لمدّة 45 دقيقة حوالي 3 – 5 مرّات أسبوعيّاً له دورٌ إيجابيّ
					كبير في الحفاظ على الصّحّة النّفسيّة.{' '}
				</p>
				<p>
					الحصول على غذاءٍ متوازن مناسب، وكمّيّة كافية من السّوائل، لإمداد الجسم بالطّاقة اللّازمة
					على مدار اليوم.{' '}
				</p>
				<p>
					الحصول على نومٍ عالي الجودة، فقد أثبتت الدّراسات التّأثير الإيجابيّ لجودة النّوم على
					الصّحّة النّفسيّة.{' '}
				</p>
				<p>
					السّعي لبناء علاقاتٍ اجتماعيّة جيّدة للحفاظ على الصّحّة النّفسيّة، والحصول على الدّعم عند
					الحاجة.{' '}
				</p>
				<p>ممارسة أنشطة الاسترخاء، وتمارين التّنفس، كلّها عوامل تساعدك على تحسين صحّتك النّفسيّة. </p>
				{/* <h5>المصادر:</h5>
				<a
					target='_blank'
					href='https://www.healthdirect.gov.au/signs-mental-health-issue'
					rel='noopener noreferrer'>
					healthdirect
				</a>
				<a
					target='_blank'
					href='https://www.medicalnewstoday.com/articles/154543#maintaining-mental-health   '
					rel='noopener noreferrer'>
					{' '}
					medicalnewstoday
				</a> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Mental Health: What Is It? How Do You Keep It at Its Best?</h4>
				

				
					<h5>Introduction</h5>
					<p>
						WHO defines mental health as a state of mental well-being that enables people to
						cope with the stresses of life, realize their abilities, learn well and work well,
						and contribute to their community. It is an integral component of health and
						well-being that underpins our individual and collective abilities to make decisions,
						build relationships, and shape the world we live in. It is crucial to personal,
						community, and socioeconomic development.
					</p>
				

				
					<h5>Signs of Mental Issues</h5>
					<ol>
						<li>
							<strong>Feeling anxious or worried:</strong>
							We all get worried or stressed from time to time. But anxiety could be the
							sign of a mental health disorder if the worry is constant and interferes all
							the time. Other symptoms of anxiety may include heart palpitations, shortness
							of breath, headache, sweating...
						</li>
						<li>
							<strong>Feeling depressed or unhappy:</strong>
							Signs of depression include being sad or irritable for the last few weeks or
							more, lacking in motivation and energy, and losing interest in a hobby.
						</li>
						<li>
							<strong>Emotional outbursts:</strong>
							Everyone has different moods, but sudden and dramatic changes in mood, such as
							extreme distress or anger, can be a symptom of mental illness.
						</li>
						<li>
							<strong>Sleep problems:</strong>
							Lasting changes to a person’s sleep patterns could be a symptom of a mental
							health disorder. For example, insomnia could be a sign of anxiety or substance
							abuse. Sleeping too much could indicate depression.
						</li>
						<li>
							<strong>Weight or appetite changes:</strong>
							For some people, fluctuating weight or rapid weight loss could be one of the
							warning signs of a mental health disorder, such as depression or an eating
							disorder.
						</li>
						<li>
							<strong>Quiet or withdrawn:</strong>
							If a friend or loved one is regularly isolating themselves, especially after
							traumatic life events, they may have depression or bipolar disorder.
						</li>
						<li>
							<strong>Feeling guilty or worthless:</strong>
							Thoughts like ‘I’m a failure’, ‘It’s my fault’ or ‘I’m worthless’ are all
							possible signs of a mental health disorder, such as depression. When severe, a
							person may express a feeling to hurt or kill themselves. This feeling could
							mean the person is suicidal and urgent help is needed.
						</li>
					</ol>
				

				
					<h5>Tips for Maintaining Mental Health</h5>
					<p>
						We advise you to implement some habits in your daily life to maintain your mental
						health such as:
					</p>
					<ul>
						<li>
							<strong>Regular exercise:</strong> Exercising for 45 minutes, three to five
							times a week, can significantly improve mental health.
						</li>
						<li>
							<strong>Eat a balanced diet and stay hydrated:</strong> Eating a nourishing,
							balanced diet and staying hydrated can give a constant supply of energy
							throughout the day.
						</li>
						<li>
							<strong>Aim for good-quality sleep:</strong> Significant improvements in sleep
							quality lead to greater improvements in a person’s mental health.
						</li>
						<li>
							<strong>Perform relaxing activities:</strong> Meditation, practicing
							gratefulness, and journaling can help reduce stress and improve overall health
							and well-being.
						</li>
					</ul>
				
				{/* <h5>references:</h5>
				<a
					target='_blank'
					href='https://www.healthdirect.gov.au/signs-mental-health-issue'
					rel='noopener noreferrer'>
					healthdirect
				</a>
				<a
					target='_blank'
					href='https://www.medicalnewstoday.com/articles/154543#maintaining-mental-health   '
					rel='noopener noreferrer'>
					{' '}
					medicalnewstoday
				</a> */}
			</article>
		),
	},

	{
		id: 8,
		imgEN: 'assets/articles/8-Thecausesoflaziness1.webp',
		imgAR: 'assets/articles/8-Thecausesoflaziness1.webp',
		descAR: (
			<article dir='rtl'>
				<h4>لماذا نشعر بالكسل؟ وكيف يمكننا تجنّبه؟ </h4>

				<h5>مقدّمة: </h5>
				<p>
					إن كنت تشعر بالكسل، وعدم الرّغبة بفعل أيّ شيء سوى الجلوس مع هاتفك المحمول للتّصفح والتّنقل
					من تطبيقٍ لآخر دون التّفكير بأيّ شيء، فهذا المقال موجّه لك.
				</p>
				<h5>ما هو الكسل؟ </h5>
				<p>
					يمكن تعريف الكسل على الفشل في التّصرّف أو أداء الأعمال المترتّبة على الشّخص لأسبابٍ يستطيع
					الفرد معرفتها والتّحكّم بها.{' '}
				</p>

				<h5>كيف نصاب بالكسل؟ </h5>
				<p>
					هناك العديد من الأسباب التي تؤدّي للكسل، لكن من الجدير بالذّكر أنّ الحكم على نمط الحياة
					بالكسل هو أمرٌ نسبيّ يختلف من شخصٍ لآخر وحالةٍ لأخرى، ونذكر لك من هذه الأسباب:{' '}
				</p>
				<ul>
					<li>
						الفئة العمرّية: يميل الأطفال إلى الكسل من النّاحية الدّراسية أو ناحية الالتزام لعدم
						قدرتهم على تنظيم المشاعر تجاه النّشاطات التي تؤتي أُكُلها على المدى الطّويل ممّا
						يؤدّي لميلهم للأعمال التي تؤدّي للسّعادة أو الرّبح بشكلٍ سريع كاللّعب وألعاب
						الفيديو.{' '}
					</li>
					<li>
						التّعوّد على المماطلة: إذا تعوّد دماغك على التّعامل مع الأعمال المملّة أو الصّعبة
						بالتّجاهل، فالقيام بهذه الأعمال سيصبح مهمّة صعبة جداً، وستصاب بالكسل في كلّ مرةٍ
						تتّجه للقيام بهذه الأعمال.{' '}
					</li>
					<li>
						تأثير المحيط: إذا كان زملاؤك من النّوع الذي يلهو ويضع العمل جانباً، فالاحتمال الأكبر
						هو أنّك ستصاب بالكسل مثلهم وتتأثّر بأساليبهم.{' '}
					</li>
					<li>
						الطاقة وقوة الإرادة: عندما لا تحصل على الكمّ الكافي من النّوم والرّاحة فأنت لا تحصل
						على كمّ الطّاقة اللّازمة للقيام بأعمالك، بالإضافة لأنّ نقص الطّاقة سيؤدّي بقوّة
						إرادتك للانخفاض والضّعف.{' '}
					</li>
					<li>
						التّشتّت: يعد التّشتّت مشكلة العصر الأساسيّة، وتعلّقك بوسائل التّواصل الاجتماعيّ
						وبالأجهزة الالكترونية بأنواعها واحدٌ من أكبر أسباب الكسل..{' '}
					</li>
				</ul>
				<h5>كيف أتخطّى الكسل؟ </h5>
				<p>
					لا يعتبر الكسل حالةً طبّيّة تحتاج للعلاج، بل يحتاج التّعامل معه إلى الوعي لسببه والتّغلّب
					عليه، لا توجد طريقةٌ مؤكّدة النّجاح للتّخلّص من الكسل ولكن هناك بعض الممارسات التي يمكن
					الاستعانة بها للتّخلص منه:{' '}
				</p>

				<ul>
					<li>
						تحديد أهداف ممكنة التّحقيق: جعل قائمة الأهداف الخاصّة بك طويلة وصعبة التّحقيق في
						الوقت المحدّد لها سيدفع بك إلى الإصابة بالإحباط ، لذا من المهم تحديد أهدافك بشكلٍ
						واضحٍ ومحدّد.{' '}
					</li>
					<li>
						الحديث الإيجابيّ مع النّفس: لا تعامل نفسك باستحقارٍ أو بقسوةٍ، وتجنّب تأنيب نفسك على
						التّفاصيل الصّغيرة، وحاول دفع نفسك بالحديث الإيجابيّ والاعتراف بالإنجازات الخاصّة بك
						مهما كانت صغيرةً وبسيطة.{' '}
					</li>
					<li>
						تخلّص من المشتّتات: جميعنا نملك أنواعنا المفضّلة من المشتّتات التي نلجأ لها عندما لا
						نرغب في القيام بمهمّاتنا، لذلك من المهم إبعاد هذه المشتّتات عنّك أثناء العمل
						والمحافظة على التركيز لأطول فترة ممكنة.{' '}
					</li>
					<li>
						اجعل المهمّات المملّة مسليّةً أكثر: من الطّبيعي تجنّب الأعمال المملّة والصّعبة ولكنّ
						إضافة لمسةٍ من التّسلية إليها من الممكن أن يجعلها أسهل، يمكنك الاستماع للموسيقا
						الكلاسيكيّة عند القيام بالأعمال التي تحتاج إلى درجةٍ من التّركيز، والاستماع
						للتّسجيلات الصّوتيّة الرّقميّة عند القيام بالأعمال التكرارّية مثل كيّ الملابس أو
						التّنظيف أو غيرها.{' '}
					</li>
					<li>
						اسعَ للحصول على حياةٍ صحّيّة: نمط الحياة الصّحّيّ يساعدك على الحصول على كمٍّ كبيرٍ
						من الطّاقة وهو ما يجعل القيام بالمهمّات أسهل.{' '}
					</li>
				</ul>
				{/* <h5>المصادر:</h5>
				<a
					target='_blank'
					href='https://www.everydayhealth.com/emotional-health/all-about-laziness-what-causes-it-and-what-to-do-about-it/ '
					rel='noopener noreferrer'>
					{' '}
					everydayhealth
				</a>
				<a
					target='_blank'
					href='https://www.everydayhealth.com/emotional-health/all-about-laziness-what-causes-it-and-what-to-do-about-it/'
					rel='noopener noreferrer'>
					everydayhealth
				</a>
				<a
					target='_blank'
					href='https://www.healthline.com/health/how-to-stop-being-lazy#health-strategies '
					rel='noopener noreferrer'>
					healthline{' '}
				</a> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Why Do We Feel Lazy? And How Do We Avoid It?</h4>
				

				
					<h5>Introduction</h5>
					<p>
						If you feel lazy, unwilling to do anything but sit down with your mobile phone to
						browse and move from one app to another without thinking about anything, this
						article is for you!
					</p>
				

				
					<h5>What is Laziness?</h5>
					<p>
						Laziness can be regarded as a failure to act or perform as expected due to
						conscious, controllable factors.
					</p>
				

				
					<h5>What Causes Laziness?</h5>
					<p>
						Laziness is a lay expression, not a formal term, and it’s also a matter of
						subjective opinion. And there are multiple reasons that cause it such as:
					</p>
					<ul>
						<li>
							Age: Kids operate much more out of a pleasure principle. For an adolescent
							brain, it’s hard to prioritize school work which is a form of toil that may
							not provide any immediate benefits or incentives over playing video games.
						</li>
						<li>
							The Habitual Behavior Factor: If your brain learns to cope with unpleasant
							tasks by avoiding them, it can be hard to shake this response.
						</li>
						<li>
							Environment: If your colleagues mess around and blow off work, you’re more
							likely to do the same.
						</li>
						<li>
							Energy and Willpower: When you are sleep deprived, your willpower drops and
							you become more likely to procrastinate.
						</li>
						<li>
							Distraction: Distraction is a major driver of procrastination, social media
							and high smartphone use are both predictive of some forms of academic
							procrastination.
						</li>
					</ul>
				

				
					<h5>How Do You Overcome Laziness?</h5>
					<ol>
						<li>
							Make your goals manageable: Setting unrealistic goals and taking on too much
							can lead to burnout. Avoid overloading by setting smaller, attainable goals
							that will get you where you want to be without overwhelming you along the way.
						</li>
						<li>
							Use positive instead of negative self-talk: Negative self-talk can derail your
							efforts to get things done in every aspect of your life. Telling yourself that
							you’re a lazy person is a form of negative self-talk. You can stop your
							negative internal voice by practicing positive self-talk.
						</li>
						<li>
							Avoid distraction: We all have our favorite distractions we turn to when we’re
							just not feeling like doing a task. Find ways to make your distractions less
							accessible to maintain focus for as long as possible.
						</li>
						<li>
							Make tedious tasks fun: It is natural to avoid boring and difficult work but
							adding a touch of amusement to it can make it easier. You can listen to
							classical music when doing work that requires focus, and listen to songs or
							podcasts when doing repetitive work such as ironing, cleaning, or other
							chores...
						</li>
						<li>
							Strive for a healthy life: A healthy lifestyle provides you with a lot of
							energy, making it easier to do your tasks.
						</li>
					</ol>
				

				{/* <h5>المصادر:</h5>
				<a
					target='_blank'
					href='https://www.everydayhealth.com/emotional-health/all-about-laziness-what-causes-it-and-what-to-do-about-it/ '
					rel='noopener noreferrer'>
					{' '}
					everydayhealth
				</a>
				<a
					target='_blank'
					href='https://www.everydayhealth.com/emotional-health/all-about-laziness-what-causes-it-and-what-to-do-about-it/'
					rel='noopener noreferrer'>
					everydayhealth
				</a>
				<a
					target='_blank'
					href='https://www.healthline.com/health/how-to-stop-being-lazy#health-strategies '
					rel='noopener noreferrer'>
					healthline{' '}
				</a> */}
			</article>
		),
	},

	{
		id: 9,
		imgEN: 'assets/articles/03-colonspasm1.webp',
		imgAR: 'assets/articles/03-colonspasm2.webp',
		descAR: (
			<article dir='rtl'>
				<h4>تشنج القولون، ماهو؟ ما أسبابه؟ وكيف يمكننا التخلص منه؟ </h4>

				<h5>مقدّمة:</h5>
				<p>
					متلازمة القولون العصبيّ هي اضطرابٌ شائع يصيب الأنبوب الهضميّ ويؤدّي إلى التّشنّج، الألم
					البطنيّ، الانتفاخ، الغازات، الإمساك أو الإسهال، وغيرها من الإزعاجات.{' '}
				</p>
				<p>تعتبر هذه المتلازمة حالةً مزمنة يجب تدبيرها على المدى الطّويل. </p>

				<h5>من هم الأشخاص المعرضون لخطر الإصابة بمرض القولون المُتهيّج؟ </h5>

				<ul>
					<li>
						تحدث الإصابة غالباً لدى الأشخاص في أواخر سنّ المراهقة حتّى أوائل الأربعينيّات، تكون
						النّساء أكثر عرضةً للإصابة.
					</li>
					<li>تتضمّن بعض عوامل الخطورة: </li>
					<li>تاريخ عائلي من الإصابة بالقولون المُتهيّج. </li>
					<li>التوتّر والقلق. </li>
					<li>إنتانات السّبيل الهضميّ الشّديدة. </li>
					<li>عدم التّحمل الغذائيّ. </li>
				</ul>
				<h5>ما هي الأطعمة التي تُحرّض القولون المُتهيّج؟</h5>

				<ul>
					<li>
						تختلف الأطعمة المحرّضة لنوبات القولون المُتهيّج بين شخصٍ وآخر ولكن يمكننا ذكر بعض
						الأطعمة التي يجب التّخفيف منها أو إيقافها:{' '}
					</li>
					<li>بعض منتجات الحليب. </li>
					<li>الفول. </li>
					<li>الفواكهز</li>
					<li>
						وقد ظهرت بعض الآثار الإيجابيّة لاستخدام الزّنجبيل، النّعنع، والبابونج لدى بعض
						الأشخاص.{' '}
					</li>
				</ul>
				<h5>ما هو علاج متلازمة القولون المُتهيّج؟ </h5>
				<ul>
					<li>
						لا يوجد علاجٌ نهائيّ حاليّاً للمتلازمة، وتقتصر العلاجات على تخفيف الأعراض وتجنّب
						المحرّضات.{' '}
					</li>
					<li>قد ينصح الطّبيب ببعض التّغييرات بنمط الحياة نذكر لك منها: </li>
					<li>ممارسة التّمارين الرياضيّة بشكلٍ منتظم. </li>
					<li>تجنّب المشروبات الحاوية على الكافيين. </li>
					<li>تناول المكمّلات الحاوية على الألياف. </li>
					<li>تقليل التوتّر وتجنّبه قدر الإمكان.</li>
				</ul>

				{/* <h5>المصادر:</h5>
				<a
					target='_blank'
					href='https://my.clevelandclinic.org/health/diseases/4342-irritable-bowel-syndrome-ibs'
					rel='noopener noreferrer'>
					clevelandclinic
				</a>
				<a
					target='_blank'
					href='https://www.healthline.com/health/irritable-bowel-syndrome#treatment'
					rel='noopener noreferrer'>
					healthline
				</a>
				<a
					target='_blank'
					href='https://www.mayoclinic.org/diseases-conditions/irritable-bowel-syndrome/symptoms-causes/syc-20360016 '
					rel='noopener noreferrer'>
					{' '}
					mayoclinic
				</a>
				<a
					target='_blank'
					href='https://www.healthline.com/health/irritable-bowel-syndrome#_noHeaderPrefixedContent'
					rel='noopener noreferrer'>
					healthline
				</a> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Irritable Bowel Syndrome: What Is It, Its Causes, and Management</h4>
				

				
					<h5>Introduction</h5>
					<p>
						Irritable bowel syndrome (IBS) is a common disorder that affects the stomach and
						intestines. Symptoms include cramping, abdominal pain, bloating, gas, and diarrhea
						or constipation, or both. IBS is a chronic condition that you'll need to manage long
						term.
					</p>
				

				
					<h5>Who is at Risk for Developing IBS?</h5>
					<p>
						The condition most often occurs in people in their late teens to early 40s. Women
						can be twice as likely than men to get IBS. IBS may happen to multiple family
						members. You may be at higher risk if you have:
					</p>
					<ul>
						<li>Family history of IBS.</li>
						<li>Emotional stress, tension, or anxiety.</li>
						<li>Food intolerance.</li>
						<li>History of physical or sexual abuse.</li>
						<li>Severe digestive tract infection.</li>
					</ul>
				

				
					<h5>What Foods Usually Trigger IBS?</h5>
					<p>
						More often, certain foods can be common triggers for many people with IBS. However,
						some of these foods may have a greater effect on you than on others. These foods
						include:
					</p>
					<ul>
						<li>Milk and dairy products.</li>
						<li>Beans.</li>
						<li>Fried foods.</li>
					</ul>
					<p>
						There have been some positive effects of the use of ginger, mint, and chamomile by
						some people.
					</p>
				

				
					<h5>What is the Best Treatment for IBS?</h5>
					<p>
						There is currently no cure for IBS. Treatment is aimed at symptom relief and
						prevention. Your doctor may have you make certain lifestyle changes such as:
					</p>
					<ul>
						<li>Participating in regular physical activity.</li>
						<li>Limiting consuming caffeinated beverages.</li>
						<li>Eating small-sized meals.</li>
						<li>Avoiding fried and spicy foods.</li>
						<li>Reducing stress and avoiding it as much as possible.</li>
					</ul>

					{/* <h5>references:</h5>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/diseases/4342-irritable-bowel-syndrome-ibs'
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
					<a
						target='_blank'
						href='https://www.healthline.com/health/irritable-bowel-syndrome#treatment'
						rel='noopener noreferrer'>
						healthline
					</a>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/diseases-conditions/irritable-bowel-syndrome/symptoms-causes/syc-20360016 '
						rel='noopener noreferrer'>
						{' '}
						mayoclinic
					</a>
					<a
						target='_blank'
						href='https://www.healthline.com/health/irritable-bowel-syndrome#_noHeaderPrefixedContent'
						rel='noopener noreferrer'>
						healthline
					</a>
				 */}
			</article>
		),
	},

	{
		id: 10,
		imgEN: 'assets/articles/10-yourphysicalcondition1.webp',
		imgAR: 'assets/articles/10-yourphysicalcondition2.webp',
		descAR: (
			<article dir='rtl'>
				<h4>التّحاليل والاختبارات، أساليب الكشف عن حالتك الجسديّة.</h4>

				<h5>مقدّمة:</h5>
				<p>
					يمكن للأطبّاء ومقدّمي الرّعاية الصّحيّة الحصول على صورة عن الحالة الصّحيّة لجسدك عن طريق
					إجراء بعض التّحاليل والاختبارات على العيّنات المختلفة التي يتم الحصول عليها منك، والتّي
					تقدّم لهم معطيات ومعلومات عن نسب المواد المختلفة في جسمك، بالإضافة إلى الكشف عن تواجد بعض
					الأحياء الدّقيقة (جراثيم، فيروسات، فطور..).
				</p>

				<h5>ولكن ما هي الأنواع المختلفة للتّحاليل؟ ومتى يطلب كلٌ منها؟</h5>
				<ul>
					<li>
						تعدّ
						<span style={{ textDecoration: 'underline' }}>التّحاليل الدّمويّة</span>
						إحدى أكثر أنواع الاختبارات شيوعاً، يستخدمها مقدّمو الرّعاية الصّحيّة لمراقبة الصّحّة
						العامّة أو للمساعدة في تشخيص الحالات الطبيّة، قد تطلب هذه التّحاليل كجزء من
						<span style={{ textDecoration: 'underline' }}>الفحص الجسديّ</span>
						الروتينيّ، أو لأنّ المريض يعاني من أعراضٍ معيّنة.
					</li>
					<li>
						ويُعدّ
						<span style={{ textDecoration: 'underline' }}>تحليل البول</span> أيضاً من التّحاليل
						الشّائعة، ويتضمّن فحص مظهر البول وتركيزه ومحتوياته، يطلب هذا التّحليل لمراقبة الحالة
						الجسديّة العامّة، للمساعدة في تشخيص بعض الأمراض، ومراقبة تطوّرها.
					</li>
					<li>
						يطلُب مقدّم الرّعاية الصّحيّة
						<span style={{ textDecoration: 'underline' }}>عيّنة البراز</span>، لإجراء
						<span style={{ textDecoration: 'underline' }}>اختبار الزّرع</span> بهدف الكشف عن
						وجود الجراثيم أو الفيروسات في البراز أو غيرهما، مما قد يكون سبباً للمشاكل الهضمية
						لدى المريض.
					</li>
					<li>
						قد تُطلب
						<span style={{ textDecoration: 'underline' }}>عيّنة السّائل المنويّ</span>
						في حالة
						<span style={{ textDecoration: 'underline' }}>الصّعوبات الإنجابيّة،</span>
						إذ يتم فحص تعداد الحيوانات المنويّة، أو الكشف عن عدم قدرتها على التّحرّك بشكلٍ صحيح
						وهو ما قد يؤدّي لحدوث
						<span style={{ textDecoration: 'underline' }}>العقم،</span> بالإضافة إلى إجراء هذا
						التّحليل للتّأكد من نجاح عمليّة
						<span style={{ textDecoration: 'underline' }}>
							قطع القناة الناقلة للمني (الأسهر).
						</span>
					</li>
					<li>
						أمّا <span style={{ textDecoration: 'underline' }}>الخزعة،</span> فهي الإجراء
						المستخدم للحصول على قطعة من الأنسجة أو عيّنة من خلايا الجسم حتّى يتم اختبارها، قد
						يَطلب مقدّم الرّعاية الصّحيّة الخزعة إذا كان المريض يعاني من أعراضٍ معيّنة، أو إذا
						وجدت منطقة مثيرة للقلق في جسمه، تختلف
						<span style={{ textDecoration: 'underline' }}>أنواع الخزعات</span> باختلاف الغرض من
						إجراءها، وقد تستخدم لتأكيد أو نفي
						<span style={{ textDecoration: 'underline' }}>الإصابة بالسرطان</span>.
					</li>
					<li>
						بالإضافة لكلّ ما سبق، يمكن لمقدّم الرّعاية الصّحيّة طلب عيّنة من
						<span style={{ textDecoration: 'underline' }}>السّائل الدماغيّ الشّوكيّ</span>،
						يشكّل هذا السّائل وسادة حماية تحيط بالدّماغ والنّخاع الشّوكيّ، ويتم الفحص للكشف عن
						محتوياته، بالإضافة لقياس ضغط السّائل النّخاعيّ.
					</li>
				</ul>
				{/* <p>
					<a
						target='_blank'
						href='https://medlineplus.gov/ency/article/003428.htm#:~:text=Cerebrospinal%20fluid%20(CSF)%20collection%20is,pressure%20in%20the%20spinal%20fluid'
						rel='noopener noreferrer'>
						medlineplus{' '}
					</a>
				</p>
				<p>
					بناء على نتائج التّحاليل المختلفة، بالإضافة
					<span style={{ textDecoration: 'underline' }}>للفحوصات السريريّة والصور الشّعاعيّة</span>
					يستطيع مقدّمو الرّعاية الصّحيّة الوصول إلى تشخيصٍ لحالتك، ووضع خطّةٍ علاجيّةٍ مناسبةٍ لك.
				</p>

				<h5>المصادر:</h5>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/diagnostics/24508-blood-tests '
						rel='noopener noreferrer'>
						clevelandclinic{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/tests-procedures/urinalysis/about/pac-20384907'
						rel='noopener noreferrer'>
						mayoclinic{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.webmd.com/a-to-z-guides/what-is-a-stool-culture'
						rel='noopener noreferrer'>
						webmd
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://medlineplus.gov/lab-tests/semen-analysis/ '
						rel='noopener noreferrer'>
						medlineplus{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/diseases-conditions/cancer/in-depth/biopsy/art-20043922'
						rel='noopener noreferrer'>
						mayoclinic{' '}
					</a>
				</p> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Medical Tests: How to Check Your Physical Condition</h4>
				

				
					<h5>Introduction</h5>
					<p>
						Doctors and health care providers can get a clear view on your body’s physical state
						by ordering some tests that are done on different kinds of samples obtained from
						your body, which provides them with data and information on the concentrations and
						amounts of different substances in your body, as well as detecting the presence of
						some microbiota (germs, viruses, fungi...).
					</p>
				

				
					<h5>Types of Medical Tests and Their Purposes</h5>
					<p>But what are the different types of tests? And when is each one done?</p>
					<ul>
						<li>
							<strong>Blood test:</strong> One of the most common tests healthcare providers
							use to monitor your overall health or help diagnose medical conditions. You
							may have a blood test as part of a routine physical examination or because you
							have certain symptoms.
						</li>
						<li>
							<strong>Urinalysis:</strong> A test of your urine. It's used to detect and
							manage a wide range of disorders, it involves checking the appearance,
							concentration, and content of urine. It’s used to check your overall health,
							diagnose, and manage medical conditions.
						</li>
						<li>
							<strong>Stool culture:</strong> If you’ve been having stomach problems, your
							doctor might order this test or ask for a stool sample. This test can look in
							your poop for bacteria, a virus, or other germs that might be making you sick.
						</li>
						<li>
							<strong>Semen analysis:</strong> Used to find out if a problem with semen or
							sperm may be causing infertility. The test may also be used to see if a
							vasectomy has been successful.
						</li>
						<li>
							<strong>Cerebrospinal fluid (CSF) collection:</strong> A test to look at the
							fluid that surrounds the brain and spinal cord. CSF acts as a cushion,
							protecting the brain and spine from injury. The fluid is normally clear. It
							has the same consistency as water. The test is also used to measure pressure
							in the spinal fluid.
						</li>
					</ul>
					<p>
						Based on the results of various tests, as well as physical exams and x-rays, health
						care providers can diagnose your conditions and set a treatment plan that is
						suitable for you.
					</p>
				
				{/* <h5>المصادر:</h5>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/diagnostics/24508-blood-tests '
						rel='noopener noreferrer'>
						clevelandclinic{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/tests-procedures/urinalysis/about/pac-20384907'
						rel='noopener noreferrer'>
						mayoclinic{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.webmd.com/a-to-z-guides/what-is-a-stool-culture'
						rel='noopener noreferrer'>
						webmd
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://medlineplus.gov/lab-tests/semen-analysis/ '
						rel='noopener noreferrer'>
						medlineplus{' '}
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.mayoclinic.org/diseases-conditions/cancer/in-depth/biopsy/art-20043922'
						rel='noopener noreferrer'>
						mayoclinic{' '}
					</a>
				</p> */}
			</article>
		),
	},
	{
		id: 11,
		imgEN: 'assets/articles/011-skin1.webp',
		imgAR: 'assets/articles/11-skin2.webp',
		descAR: (
			<article dir='rtl'>
				<h4>الأعراض الجلديّة الشائعة، متى يجب عليّ استشارة الطّبيب؟</h4>

				<h5>مقدّمة:</h5>
				<p>
					<span style={{ textDecoration: 'underline' }}>الأمراض الجلديّة</span> هي الحالات التي تصيب
					الجلد، وقد تشمل الالتهاب، الحكّة، الطّفح وبعض التغيّرات الجلديّة الأخرى.
				</p>
				<p>
					بعض الأمراض الجلديّة تحدث لأسباب وراثيّة، والبعض الآخر يحدث بسبب عوامل معيّنة متعلّقة بنمط
					الحياة.
				</p>
				<p>تتضمّن علاجات الحالات الجلديّة الأدوية، المراهم أو الكريمات، أو تغييرات نمط الحياة.</p>

				<h5>ولكن ماهي الأعراض الجلديّة الأكثر شيوعاً؟ وبأيّ الأمراض ترتبط؟</h5>
				<ul>
					<li>
						<p>
							تعدّ <span style={{ textDecoration: 'underline' }}>البثور</span> التّي تظهر
							على الوجه، الظّهر، أو الصّدر بالإضافة
							<span style={{ textDecoration: 'underline' }}>للرّؤوس السّوداء</span>،
							التكيّسات والعقد دلالات على الإصابة
							<span style={{ textDecoration: 'underline' }}>بحبّ الشّباب</span>، تحدث الحالة
							بسبب انسداد جريبات الشعر والغدد الدّهنية في الجلد، وغالباً ما تحفّزها
							<span style={{ textDecoration: 'underline' }}>التّغيّرات الهرمونيّة</span>،
							تشكّل هذه الحالة مصدراً
							<span style={{ textDecoration: 'underline' }}>للقلق</span> والاضطراب لدى
							المراهقين، كما قد تظهر لدى البالغين أيضاً.
						</p>
						<p>
							يمكن مراجعة الطبيب في أيّ مرحلة من مراحل الحالة، مع التّأكيد على ضرورة مراجعته
							في حالات الحبوب المتقيّحة أو المتجمّعة بشكلٍ واضح.
						</p>
					</li>
					<li>
						<p>
							خلال ساعات بعد التّواجد في الشّمس لفتراتٍ طويلة، قد تظهر مناطق ملتهبة مؤلمة،
							تسبّب الشّعور بالألم والسّخونة عند لمسها، وتسمى
							<span style={{ textDecoration: 'underline' }}>بالحروق الشمسيّة</span>.
						</p>
						<p>
							قد تكون طفيفة يمكن التّخفيف منها باستعمال بعض تدابير الرعاية الذاتيّة كتناول
							<span style={{ textDecoration: 'underline' }}>مسكّنات</span> الألم وتبريد
							المنطقة، وقد تحتاج عدّة أيام لتشفى بالكامل.
						</p>
						<p>
							أو قد تتطوّر بعض البثور أو الكيسات الحاوية على سائل في المنطقة، وتترافق ببعض
							الأعراض كالغثيان، الألم المتفاقم، التورّم، الصّداع، ومشاكل الرّؤية.. ويجدر
							عندها اللجوء لمقدمي الرّعاية الصّحيّة للحصول على العلاج المناسب.
						</p>
					</li>

					<li>
						<p>
							تظهر بعض الأعراض الجلدية كالجفاف والسّخونة والحكّة في حالات
							<span style={{ textDecoration: 'underline' }}>الأكزيما الطّفيفة</span>،
							وتتطوّر إلى التّشقّقات، السّحجات والنّزف في حالات
							<span style={{ textDecoration: 'underline' }}>الأكزيما الشّديدة</span>.
						</p>
						<p>
							تختلف شدّة الحالة من مريضٍ لآخر، وتظهر لدى مختلف الفئات العمريّة، لكنّها ليست
							مُعدية وتتوفّر العديد من العلاجات لها.
						</p>
						<p>
							ومن الجدير بالذكر أن الالتهابات النّاجمة عنها قد تنكس لذا من المهم العناية بها
							وإجراء التّدبير المناسب لها.
						</p>
					</li>
					<li>
						<p>
							يمكن أن يظهر احمرار على الوجه، وتحديداً على الأنف، الذّقن، الوجنتين، والجبهة،
							وبشكلٍ أقلّ على الأذنين، الرّقبة وفروة الرّأس، في حالة الإصابة
							<span style={{ textDecoration: 'underline' }}>بالعدّ الورديّ</span>.
						</p>
						<p>
							قد تتطوّر هذه الحالة مع مرور الوقت، فيزداد الاحمرار ويصبح المظهر سيّئاً نتيجة
							لتوسّع الشعيرات الدّموية التي تصبح مرئيّة على سطح الجلد.
						</p>
						<p>
							من المهم الحصول على العلاج بالشّكل والوقت المناسب وإلّا قد تتطوّر الحالة إلى
							تحدّبات حمراء صلبة، وبثور متقيّحة، بالإضافة لظهور الأنف بمظهر منتفخ، وبعض
							التأثيرات على العينين (التهيج، الاحمرار..).
						</p>
					</li>
				</ul>
				<h5>بعض النّصائح لتجنّب الإصابة بالأمراض الجلديّة الالتهابيّة أو المُعدية:</h5>
				<ul>
					<li>تجنّب مشاركة الأغراض الشّخصيّة ومستحضرات التّجميل.</li>
					<li>تعقيم الأشياء قبل استعمالها في الأماكن العامّة، كمعدّات النّوادي الرّياضية.</li>
					<li>
						شرب كمّيات كافية من
						<span style={{ textDecoration: 'underline' }}>الماء</span> والحصول على
						<span style={{ textDecoration: 'underline' }}>غذاء متوازن</span>.
					</li>
					<li>
						الحصول على الكمّ الكافي من
						<span style={{ textDecoration: 'underline' }}>النّوم</span>.
					</li>
					<li>غسل اليدين بانتظام بالماء والصّابون.</li>
					<li>الوقاية من الشمس لتجنب الإصابة بالحروق الشّمسيّة.</li>
				</ul>

				{/* <h5>المصادر:</h5>
				<p>
					<a
						target='_blank'
						href='https://www.drugs.com/mcd/sunburnhttps://my.clevelandclinic.org/health/diseases/21573-skin-diseases#:~:text=Some%20of%20the%20most%20common,to%20swelling%2C%20cracking%20or%20scaliness'
						rel='noopener noreferrer'>
						www.drugs.com
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.drugs.com/slideshow/most-common-skin-conditions-1086 '
						rel='noopener noreferrer'>
						www.drugs.com
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.drugs.com/condition/eczema.html'
						rel='noopener noreferrer'>
						www.drugs.com
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/diseases/12174-rosacea-adult-acne '
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/diseases/21573-skin-diseases#:~:text=Some%20of%20the%20most%20common,to%20swelling%2C%20cracking%20or%20scaliness'
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
				</p> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Common Skin Conditions: When Should I See a Doctor?</h4>
				

				
					<h5>Introduction</h5>
					<p>
						Skin diseases are conditions that affect your skin. These diseases may cause rashes,
						inflammation, itchiness or other skin changes. Some skin conditions may be genetic,
						while lifestyle factors may cause others. Skin disease treatment may include
						medications, creams or ointments, or lifestyle changes.
					</p>
				

				
					<h5>Common Skin Conditions and Their Causes</h5>
					<p>
						But what are the most common skin conditions? And which diseases are they related
						to?
					</p>
					<ul>
						<li>
							<strong>Acne:</strong> Caused by blocked hair follicles and oil (sebaceous)
							glands of the skin, often triggered by hormonal changes. The term acne refers
							to not only pimples on the face, but blackheads, cysts, and nodules as well.
							Some people get acne on other parts of their body too, such as the back and
							chest. Acne treatment by a dermatologist is important because acne left
							unchecked can often lead to permanent scars and dark facial spots.
						</li>
						<li>
							<strong>Sunburn:</strong> Inflamed, painful skin that feels hot to the touch.
							It often appears within a few hours of being in the sun too long. Some pimples
							or cysts may develop in the area along with symptoms such as nausea,
							aggravated pain, swelling, headaches, and vision problems. In which case you
							should seek medical attention as soon as possible.
						</li>
						<li>
							<strong>Eczema:</strong> Also known as dermatitis, it is a group of skin
							conditions which can affect all age groups. The severity of the disease can
							vary. In mild forms the skin is dry, hot and itchy, whilst in more severe
							forms the skin can become broken, raw and bleeding. Although it can sometimes
							look unpleasant, eczema is not contagious.
						</li>
						<li>
							<strong>Rosacea:</strong> A skin condition that causes redness on your face.
							The most common places to find symptoms of rosacea include your nose, cheeks
							and forehead. This is a lifelong condition without a cure.
						</li>
					</ul>
				

				
					<h5>Tips to Avoid Inflammatory or Contagious Skin Diseases</h5>
					<ul>
						<li>Avoid sharing utensils, personal items or cosmetics.</li>
						<li>Disinfect objects you use in public spaces, such as gym equipment.</li>
						<li>Drink plenty of water and eat a nutritious diet.</li>
						<li>Limit contact with irritants or harsh chemicals.</li>
						<li>Sleep seven to eight hours per night.</li>
						<li>Use sun protection to prevent sunburn and other sun damage.</li>
						<li>Wash your hands regularly with soap and water.</li>
					</ul>
				
				{/* <h5>references:</h5>
				<p>
					<a
						target='_blank'
						href='https://www.drugs.com/mcd/sunburnhttps://my.clevelandclinic.org/health/diseases/21573-skin-diseases#:~:text=Some%20of%20the%20most%20common,to%20swelling%2C%20cracking%20or%20scaliness'
						rel='noopener noreferrer'>
						www.drugs.com
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.drugs.com/slideshow/most-common-skin-conditions-1086 '
						rel='noopener noreferrer'>
						www.drugs.com
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.drugs.com/condition/eczema.html'
						rel='noopener noreferrer'>
						www.drugs.com
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/diseases/12174-rosacea-adult-acne '
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/diseases/21573-skin-diseases#:~:text=Some%20of%20the%20most%20common,to%20swelling%2C%20cracking%20or%20scaliness'
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
				</p> */}
			</article>
		),
	},
	{
		id: 12,
		imgEN: 'assets/articles/12-digestivesystem1.webp',
		imgAR: 'assets/articles/12-digestivesystem2.webp',
		descAR: (
			<article dir='rtl'>
				<h4>الأعراض الهضميّة، كيف يخبرنا الجهاز الهضميّ أنّه ليس بخير؟</h4>

				<h5>مقدّمة:</h5>
				<p>
					تتعدّد
					<span style={{ textDecoration: 'underline' }}>الأمراض الهضميّة،</span>
					وتتعدّد معها أسبابها وأعراضها، تؤثّر هذه الأمراض في أي جزء من
					<span style={{ textDecoration: 'underline' }}>الجهاز الهضميّ</span> من الفم لفتحة الشّرج.
				</p>
				<p>تقسم الأمراض الهضميّة لنوعين:</p>
				<ul>
					<li>
						<span style={{ textDecoration: 'underline' }}>أمراض هضميّة وظيفيّة</span> : تتمثّل
						بالأعراض الهضميّة دون وجود خلل فيزيائيّ أو تغيير في الجهاز الهضميّ (مثل: انتفاخ
						البطن بالغازات).
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>أمراض هضميّة بنيويّة</span> : تترافق
						الأعراض الهضميّة بوجود تغييرات بالأمعاء ويمكن ألا تعمل بشكل صحيح، وقد تحتاج للعلاجات
						الجراحية (مثل: البواسير).
					</li>
				</ul>

				<h5>ما هي الأعراض والعلامات العامة التي تستدعي الانتباه لصحة جهازك الهضمي؟</h5>
				<p>غالباً ما تتضمّن العلامة الأولى لمشاكل الجهاز الهضميّ واحداً أو أكثر من الأعراض التّالية:</p>
				<ul>
					<li>
						<span style={{ textDecoration: 'underline' }}>نزيف.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>انتفاخ.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>إمساك.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>إسهال.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>حرقة المعدة.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>سلس البول.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>الغثيان والقيء.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>ألم في البطن.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>مشاكل البلع.</span>
					</li>
					<li>
						<span style={{ textDecoration: 'underline' }}>زيادة الوزن أو فقدانه.</span>
					</li>
				</ul>

				<h5>نصائح الخبراء للحفاظ على صحّة جهازك الهضميّ:</h5>
				<ol>
					<h5>حدّد مواعيد الوجبات:</h5>
					<li>
						إنّ تناول وجباتك الأساسيّة بالإضافة للوجبات الخفيفة الصّحيّة في الأوقات نفسها كلّ
						يوم يساعد على تنظيم عمليّة الهضم.
					</li>
					<li>
						<h5>احصل على الكثير من الألياف:</h5>
						ترتبط
						<span style={{ textDecoration: 'underline' }}>الحمية عالية الألياف</span>
						بخطر مخفّض من الإصابة بالحالات الهضميّة كالإمساك والالتهابات الهضميّة.
					</li>
					<li>
						<h5>مارس الرّياضة:</h5>
						القيام بالتّمارين الرياضيّة بشكلً دوريّ يساعد في حركة الطّعام عبر جهازك الهضميّ،
						ويدفع بوزنك نحو الحدّ الصّحيّ الذي يعني بدوره الحصول على جهازً هضميّ صحيّ.
					</li>

					<li>
						<h5>احصل على الكمّ الكافي من السّوائل:</h5>
						حصول جسمك على الكمّ الكافي من السوائل سواء عن طريق شرب الماء، شرب المشروبات الأخرى،
						أو تناول الخضراوات والفواكه الغنيّة بالسوائل يساعد على التّخلص من الإمساك وخفض
						احتمال الإصابة به.
					</li>
					<li>
						<h5>انتبه لعاداتك اليومية:</h5>
						بعض
						<span style={{ textDecoration: 'underline' }}>العادات (غير الصّحيّة)</span>
						كالتّدخين، الاستهلاك العالي للكافيين، وشرب الكحول قد تؤدّي للكثير من الاضطرابات
						الهضميّة غير المرغوب فيها.
					</li>
					<li>
						<h5>قلّل من التّوتّر:</h5>
						ارتفاع مستويات
						<span style={{ textDecoration: 'underline' }}>التّوتّر</span> بشكلٍ كبير يرتبط
						بازدياد الأمراض والمشاكل الجسديّة التي تشمل الأمراض الهضميّة، حاول التّحكّم بمستويات
						التّوتر لديك عن طريق ممارسة بعض نشاطاتك المفضّلة.
					</li>
				</ol>

				{/* <h5>المصادر:</h5>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/articles/7040-gastrointestinal-diseases'
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://medlineplus.gov/ency/article/007447.htm'
						rel='noopener noreferrer'>
						medlineplus
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.everydayhealth.com/digestive-health/tips-for-better-digestive-health/ '
						rel='noopener noreferrer'>
						everydayhealth
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.healthline.com/nutrition/ways-to-improve-digestion#TOC_TITLE_HDR_2'
						rel='noopener noreferrer'>
						healthline
					</a>
				</p> */}
			</article>
		),
		descEN: (
			<article>
				
					<h4>Digestive Symptoms: How Does the Digestive System Signal Issues?</h4>
				

				
					<h5>Introduction</h5>
					<p>
						Gastrointestinal diseases affect your gastrointestinal (GI) tract, from mouth to
						anus. There are two types: functional and structural. Functional diseases are those
						in which the GI tract looks normal when examined, but doesn't move properly.
						Structural gastrointestinal diseases are those where your bowel looks abnormal upon
						examination and also doesn't work properly. Sometimes, the structural abnormality
						needs to be removed surgically.
					</p>
				

				
					<h5>Common Symptoms and Signs</h5>
					<p>
						The first sign of problems in the digestive tract often includes one or more of the
						following symptoms:
					</p>
					<ul>
						<li>Bleeding</li>
						<li>Bloating</li>
						<li>Constipation</li>
						<li>Diarrhea</li>
						<li>Heartburn</li>
						<li>Incontinence</li>
						<li>Nausea and vomiting</li>
						<li>Pain in the belly</li>
						<li>Swallowing problems</li>
						<li>Weight gain or loss</li>
					</ul>
				

				
					<h5>Expert Tips for Digestive Health</h5>
					<ol>
						<li>
							Eat on schedule: Consuming your meals and healthy snacks on a regular schedule
							can help keep your digestive system in top shape.
						</li>
						<li>
							Eat a high-fiber diet: A high-fiber diet helps to keep food moving through
							your digestive tract, making you less likely to get constipated.
						</li>
						<li>
							Exercise regularly: Staying active can also help you maintain a healthy
							weight, which is good for your digestive health.
						</li>
						<li>
							Stay hydrated: Insufficient fluid intake is a common cause of constipation.
							Increase your fluid intake by drinking water and non-caffeinated beverages and
							eating fruits and vegetables that have a high-water content.
						</li>
						<li>
							Consider lifestyle changes: Certain habits such as smoking, alcohol
							consumption, and eating late at night are associated with negative effects for
							your overall health. And, in fact, they may also be responsible for some
							common digestive issues.
						</li>
						<li>
							Manage your stress: Stress negatively impacts your digestion and has been
							linked to IBS, ulcers, constipation, and diarrhea. Reducing stress can improve
							digestive symptoms.
						</li>
					</ol>
				
				{/* <h5>references:</h5>
				<p>
					<a
						target='_blank'
						href='https://my.clevelandclinic.org/health/articles/7040-gastrointestinal-diseases'
						rel='noopener noreferrer'>
						clevelandclinic
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://medlineplus.gov/ency/article/007447.htm'
						rel='noopener noreferrer'>
						medlineplus
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.everydayhealth.com/digestive-health/tips-for-better-digestive-health/ '
						rel='noopener noreferrer'>
						everydayhealth
					</a>
				</p>
				<p>
					<a
						target='_blank'
						href='https://www.healthline.com/nutrition/ways-to-improve-digestion#TOC_TITLE_HDR_2'
						rel='noopener noreferrer'>
						healthline
					</a>
				</p> */}
			</article>
		),
	},
];
