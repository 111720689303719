export const TermsOfUseDataEn = [
	{
		id: 1,
		title: 'Registration File',
		desc: (
			<p>
				<h4> Normal Account:</h4>

				When registering an account, the user's full name, mobile phone number, date of birth, gender,
				city and region name, height, weight and blood type are required.
				<br />
				We retain this data for the necessity of future contact in case of a complaint, in addition to
				the features that require verification of the users identity in case of consultation, or booking
				an appointment, or in case of contacting a doctor or adding a doctor. <br />
				When adding a relative to your account we retain the entered information.
				<br />
				The information you enter is subject to the Privacy Policy and cannot be viewed by anyone
				without your approval.
				<br />
				You can change the privacy of any your information or medical records.
				<br />
				Your consultation is confidential, and no one can know your identity unless the consultation is
				approved by a doctor, then and only then, the doctor will be privy to your information.
				<br />
				The application allows the ability to add doctors to your profile so medical records can be
				added, and to be able to book according to the doctor's appointment schedule. <br /> Users will be
				notified of the approval of their request.
				<br />
				In case of booking the appointment more than once without canceling, the application has the
				right to block this feature for the user.
				<br />
				It is not possible to search for the same drug more than once at the same time and the server
				will prevent the user from searching.
				<br />
				In case of placing many orders, the application has the right to block the user.
				<br />

				<br />
				<h4>Business account</h4>
				When registering an account, the user's full name, mobile phone number, date of birth, gender,
				city and region name, as well as your medical certificates - if you are a doctor, pharmacist or
				healthcare provider are required.
				<br />
				Due to the sensitivity of your business, and since the app is highly reliable, the app asks to
				verify your account as a business account with your mobile phone number.
				<br />
				You can add your facility information or join an existing facility. <br /> Then, add the services you
				provide, and your schedule. <br />
				You can add your patients and add their medical records if the patients agree to the doctors
				request, and the patient can control the privacy of their medical record and choose the only me
				feature. <br /> If the patient does not approve the doctors request, the medical record will appear
				only to the doctor who added it.
				<br />
				Chat feature can be enabled or disabled.
				<br />
				You can respond to online consultations.
				<br />
				In case the account information of verification is not completed, the account will appear to the
				“Normal” account user as an “unreliable account”.
				<br />
				In case the consultation is taken and without responding within an hour, the application will
				withdraw the consultation and return it to the untaken consultations.
				<br />
				In case the user does not hold to approved appointments by doctor, the application can stop the
				booking feature for this user.
				<br />
				In case of facing an offense, doctor has the right to file a complaint and when it is confirmed,
				the offending user will be banned.
				<br />
				<br />
				<h4>Facility account</h4>

				When registering an account, the user's full name, mobile phone number, facility's city and
				region, as well as license certificate are required.
				<br />
				This account can add the employees of this facility and the services it provides, by approving
				the requests for employees to join, change settings for each employee, and activating the chat
				feature with the ability to assign the response and follow-up to a certain employee.
				<br />
				In case you are a warehouse owner, or the person responsible for it, you can approve pharmacy
				orders according to the quantities you have in stock, and the expected delivery time in addition
				to viewing previous orders.
				<br />
				In case you are the owner of a pharmacy or the person responsible for managing it, you can order
				medicines from warehouses, specify the quantities, view the order details, date of delivering,
				plus view previous orders.
				<br />
				The facility can add its own products.
				<br />
				The drug company or the warehouse can add its own drug leaflets and manage their information.
				<br />
				In case the pharmacy does not receive its order from the warehouse for more than 3 times, the
				application has the right to block this feature from the pharmacy account.
				<br />
				In case the warehouse does not deliver orders to pharmacies for more than 3 times, the
				application has the right to block this feature from the warehouse account.
				<br />
				In case of a mistake in more than one drug leaflet, the application has the right not to show
				this drug in the search results.
				<br />
			</p>
		),
	},
	{
		id: 2,
		title: 'Instructions for use',

		desc: (
			<p>
				<br />
				<h4>Important note:</h4>

				You accept these Terms by creating a DomeCare account, using the Services, or by continuing to
				use the Services. <br /> Please read, print and keep a copy of these Terms for your records as DomeCare
				will not keep a copy for you.
				<br />
				Thank you for using DomeCare application, services and technologies offered by Ayat Group.
				<br />
				In these Terms and Conditions hereinafter referred to as the “Terms” “we” refers to Ayat Group,
				a private limited liability company doing business commercial, software application development
				Registered in UAE under commercial license No. <br /> 18338, and its address is in Al-Malki -
				UAEtel Square <br />
				<br />

				<h4>Parties:</h4>

				<b>“Normal Account”</b> refers to anyone who uses the application and has an account registered with
				the application, except for healthcare providers.
				<br />
				<b>“Business Account”</b> refers to healthcare providers who have an account registered with the
				application and “Users” means both. <br /> The term "you" is used in these Terms to refer to Users.
				<br />
				<b>“Facility Account”</b> refers to healthcare providers or people who own facilities within the health
				sector and have an account registered in the application.
				<br />
				The term <b>“Services” </b>as used in DomeCares Terms of Use <b>“Terms”</b>  refers to the products, services,
				applications, content, databases, technologies and tools offered by DomeCare.
				<br />
				Understanding these terms is crucial, because in order to use our services, you must accept
				these terms. <br /> Your access to and use of this application means you accept these Terms of Use
				which are effective from the date of first use please go back to our Privacy Policy on How We
				handle Your Personal Data.
				<br />
				We may modify this Contract, our Privacy Policy, and our Cookie Policy from time to time. <br /> If we
				make changes, we will send you a notification via the application, or via other means, to
				provide you with the opportunity to review the changes before they become effective. <br /> We agree
				that changes cannot be retroactive. <br />
				If you object to any changes, you can delete your account. <br />
				Your continued use of our Services after we post or provide notification of changes, we have
				made to these Terms means that you implicitly agree to the updated Terms as of their effective
				date.
				<br />
				Do not use the Platform or the Services for emergency medical needs. <br /> If you experience a medical
				emergency, contact your local emergency services immediately.
				<br />
				<br />
				<h4>Members and visitors: </h4>
				When you register or join the DomeCare app, you become a member. <br /> If you choose not to register
				to use our services, you may access certain features as a “Visitor” only.
				<br />
				<br />
				<h4>Introduction to the application</h4>

				DomeCares mission you getting the best healthcare and provide solutions for all medical needs by
				connecting all healthcare providers within the medical sector in the world, and connecting the
				user with them via one application.
				<br />
				Our services are designed to inspire healthier communities by enabling millions of other medical
				care providers to connect, share services, find employees, work, organize and take time to
				perform tasks related to their business and allow them to be more positive and modern.
				<br />
				Plus ensure a better healthy life for the average user by archiving medical records of them and
				their family, booking appointments, and searching for medicine.
				<br />
				<br />
				<h4>Target Audience </h4>
				DomeCare targets all medical care service providers, facility owners, workers within the health
				sector, and people benefiting from their services at all ages including minors strictly under
				the supervision of their parents/guardians.
				<br />
				<br />
				<h4>Applications job what we do: </h4>

				The application mainly aims to provide medical care providers in the medical sector a “business
				account” through which they can access, monitor and record their appointments with their
				patients, and have the ability to add their information and medical certificates.
				<br />
				The application has high reliability by verifying accounts, confirming the validity of
				certificates, and confirming the phone numbers registered.
				<br />
				Both “Business Accounts" and "Facility Accounts" can manage the medical facility's work schedule
				that they own or work in, with the ability to add and view their patients' medical records when
				needed.
				<br />
				Only doctors with specialized medical degrees have the right to provide medical consultation.
				<br />
				DomeCare allows the business account Pharmacists to order medicines from licensed warehouses,
				and allows the warehouse to specify the quantities available or reject the request, and view
				previous orders.
				<br />
				The application allows the Normal account user to search for medicines in pharmacies within his
				geographical location.
				<br />
				The application allows users to search for medicines and view the medicines leaflet
				electronically.
				<br />
				The application allows the facility account to manage its information and the employees who
				join, add the services it provides, display the products and its offers, and link it to another
				facility, such as insurance companies or related facilities.
				<br />
				The application allows users to search for all medical facilities and doctors. <br />
				The application allows the Normal account user to add the doctor to their personal account with
				the ability to control the privacy of their information and files.
				<br />
				The Normal account user can add profiles of his relatives to manage their medical records.
				<br />
				The Normal account can alert the user of the time to take a medicine.
				<br />
				the business account and the facility account can enable or disable chat.
				<br />
			</p>
		),
	},
	{
		id: 3,
		title: 'Privacy Policy',

		desc: (
			<p>

				DomeCare respects, and is committed to protecting your privacy and personal information. <br />
				“Personal Information” means any information about an individual by which that individual can be
				identified. We act as an information controller with respect to any personal information you
				provide to us and will only handle and share your data in line with the requirements of
				applicable data protection laws as set forth in this Policy. <br /> We will take reasonable steps to
				ensure that the people with whom we legitimately share your data with are equally committed in
				their method of data protection.
				<br />
				<br />
				<h4>Information collecting and use</h4>
				Users can browse our site without providing any personal information. <br /> However, in order to use
				certain services available on our web application or to access certain areas of the application,
				personal information must be provided.
				<br />
				We may collect personal information about you — such as your name, phone number, address
				country, region and health information — in the following ways:
				<br />
				When you register or update your profile on our apps.
				<br />
				When you use certain interactive tools, applications, and services.
				<br />
				We will use the personal information for the purpose of maintaining user registration, managing
				and providing the content of the application.
				<br />
				You are responsible for the accuracy of the personal information you submit to DomeCare. <br />
				Inaccurate information will affect your experience when using the site and the tools and our
				ability to contact you as described in this Privacy Policy.
				<br />
				If you choose to provide us with the requested information, which is collected through using our
				application and services, you consent to the use, processing and storage of personal information
				in countries that may have data protection laws that are different from the laws in your own
				country.
				<br />
				We are committed to protect children's privacy.
				<br /> The Website and the App are not designed to
				attract children under the age of 18. <br /> The Website does not collect personal information from
				anyone under the age of 18 whom we have certain knowledge of. <br /> However, a parent or guardian may
				use the Site to create a minor's personal medical record. <br /> A parent or guardian is solely
				responsible for a minor's use of the application.  <br /> The parent or guardian is entirely responsible
				for ensuring that the registration information is kept secure, that the information provided to
				the application is accurate, and for the interpretation and use of any information or
				suggestions by the minor.
				<br />
				If you download the App from Apple App Store or Google Play Store and agree to provide your
				information to DomeCare, your information will be treated as registered user information under
				this Privacy Policy.
				<br />
				Personal health-related information provided by healthcare professionals during medical
				consultations, medical records and patient chat sessions are legally confidential and secured by
				our encrypted services.
				<br />
				However, we reserve the right to review the communication between parties that takes place on
				our site from time to time in accordance with our Terms of Use and Privacy Policy. <br />
				In case one
				of the parties breaches the privacy policy and usage.
				<br />
				If you choose to use our interactive text chat system either on your personal computer, mobile
				phone or other device, you agree to the collecting of any information transmitted through the
				chat system, including personal medical and health information whether disclosed in the form of
				a text, audio or any other format other file extensions.
				<br />
				We may use your contact information from time to time to provide you with information about our
				app and its services. <br /> Users will receive text messages for account registration upon
				registration, and all Users will receive emails about updates, changes to their accounts, and
				new features and policies; These communications are an integral part of the services on the
				application and you cannot opt out of them.
				<br />
				Customer Service and other departments may contact Users and healthcare provider in order to
				ensure your satisfaction with the Company and the application, to collect your feedback or to
				ask how we can improve our Services.
				<br />
				Normal Account If at any time you wish to update your personal information, you may do so by
				logging into your account to access your profile page.
				<br />
			</p>
		),
	},
	{
		id: 4,
		title: 'Acceptable use',
		desc: (
			<p>

				In this application, you will be asked to create an account, based on which you will create a
				username, password and other access information. <br /> We will apply standard confidentiality
				standards to secure this information, however, it is the user's responsibility to keep such
				information secured and not disclose it to third parties on the application or otherwise. <br />
				Therefore, we are not responsible and will not be held liable for the misuse of your account in
				any way due to your disclosure of your account details and access of information to third
				parties. <br /> In case of lost, stolen or disclosed access information, you must change your password
				through the password reset feature in our app or notify us immediately.
				<br />
				Users acknowledge and accept that their accounts on the application are not transferable or used
				by anyone other than the person who created the account.
				<br />
				Users agree not to do or be engaged in any of the following activities in connection with your
				use of the Services: Attempt to access or search the application or any content in it through
				the use of any engine, software, tool, agent, device, or mechanism including robots, data mining
				tools, or other tools other than through software publicly available through web browsers. <br />
				Impersonate or misrepresent your association with another person or entity.
				<br />
				Harvesting or collecting information about others, including the users' contact addresses
				e-mail….
				<br />
				Interfering with - or disrupting any of the Services, associated computer or technical delivery
				systems.
				<br />
				Interfering with - or attempting to interfere with the access of any user, host or network,
				including, but not limited to, sending a virus, overloading, sending spam…
				<br />
				Not respecting other users' privacy. <br /> This includes disclosing users' password, phone number,
				address, and/or other personally identifying information or using the application, Services, or
				Content in any way that is not permitted by these Terms.
				<br />
				Users agree to legal and acceptable use. You must access and use our Services only for lawful,
				authorized and acceptable purposes. <br /> You will not use or assist others to use our Services in
				ways that: a infringe on our users or others, including privacy, publicity, intellectual
				property or other proprietary rights; b is unlawful, obscene, defamatory, threatening,
				intimidating, harassing, hateful, racially or ethnically offensive, or incites or encourages
				conduct that may be illegal or indecent in any way, such as promoting violence, endangering or
				exploiting children or others, or coordinating harmc involve the dissemination of lies,
				misrepresentations or misleading statements;d impersonate someone;e involve the transmission of
				illegal or unauthorized communications such as group messages, automatic messages, automatic
				calling and like so; or f include any non-personal use of our Services unless otherwise
				permitted by us.
				<br />
				Harming Ayat Group and/or our users. <br /> You may not (or assist others to) directly or indirectly,
				through automated or other means, access, use, copy, adapt, modify, prepare derivative works, or
				publish information or materials on our application including online communication portals that
				is obscene, defamatory, threatening or malicious in nature, or information prohibited by law
				based on, distributing, licensing, sublicensing, transmitting, displaying, performing,
				exploiting or otherwise exploiting our Services in an unallowed or unauthorized manner, or in
				ways that burden, harm us, our services, systems, users, or others, including that you must not,
				directly or through automated means: (a) reverse engineer, alter, modify, create derivative
				works from, decompile, or extract our Services; (b) send, store or transmit viruses or other
				harmful computer code on or through our Services; (c) gain or attempt to gain unauthorized
				access to our Services or systems; (d) interfere with or disrupt the integrity, security,
				confidentiality, integrity, availability or performance of our Services; (e) create accounts for
				our Services through unauthorized or automated means; (f) collect information about or about our
				users in any way that is not permitted or unauthorized; (g) sell, resell, rent or charge our
				Services or data obtained from us or our Services in an unauthorized manner; (h) distribute or
				make our Services available over a network where they can be used by multiple devices at the
				same time, except as authorized by tools that we have expressly provided via our Services; (i)
				create software or APIs that function substantially like our Services and offer them for use by
				third parties in an unauthorized manner; Users acknowledge and accept that their accounts on the
				application are not transferable and may not be licensed. <br /> Users also agree not to use the site
				in any way that damages or impairs the performance of the application.
				<br />
				We own all copyrights, trademarks, domains, logos, trade dress, trade secrets, patents and other
				intellectual property rights associated with our Services.
				<br /> You may not use our copyrights, trademarks (or similar marks), domains, logos, trade
				dress, trade secrets, patents and other intellectual property rights unless you have our express
				permission and in accordance with our Brand Guidelines. <br /> You may use our affiliates' trademarks
				only with their permission. <br /> Report copyright, trademark and intellectual property infringement
				to DomeCare to a third party and not post any copyrighted material or material protected by any
				other intellectual property rights unless a legal right to use and publish such material is
				obtained and is in effect at the time of publication.
				<br />
				To report claims of copyright, trademark or other intellectual property infringement from a
				third party, please visit our Intellectual Property Policy.
				<br />
				We may take action with respect to your account, including disabling or suspending your account,
				if you have clearly, seriously or repeatedly infringed the intellectual property rights of
				others or when we are required to do so for legal reasons or when trying to circumvent any
				technical precautions associated with the Services, in accordance with the "Termination" section
				below.
				<br />
				We are committed to protecting children's privacy. <br /> You should be aware that this app is not
				intended or designed to attract children under the age of 18. <br /> We do not knowingly collect
				personally identifiable information from anyone who we know is a child under the age of 18. <br /> If
				they are talking to a patient under the age of 18 without parental or legal guardian
				supervision, they must notify us immediately using the email address: info@ayat-group.com. <br /> If a
				healthcare professional fails to provide us with such notice, both the healthcare professional
				and the patient will be deemed to be in breach of our terms.
				<br />
				Users must ensure the accuracy and correctness of any and all information they provide to us
				through the application.
				<br />
				Users are responsible for the quality of any information provided to us in any form, whether
				text, audio or image. <br /> We will not be held responsible for any misinterpretation of information
				due to images, unclear audio, ambiguously worded text or all kinds of files.
				<br />
				All interactions between users must be conducted in a responsible, ethical, professional and
				legal manner.
				<br />
				We may (but are not obligated) to do any or all of the following without notice:
				<br />
				Investigate your use of the Services as we deem appropriate to comply with any applicable law,
				regulation, governmental request or legal process.
				<br />
				Remove any Content that we believe it does not comply with these Terms of Use.
				<br />
				Terminate your access to the application based on our determination that you have violated these
				Terms of Use.
				<br />
			</p>
		),
	},
	{
		id: 5,
		title: 'Safety',
		desc: (
			<p>

				We adopt data collection and storage standards, processing practices and security measures to
				protect against unauthorized access, modification, disclosure or destruction of user personal
				information, username, password, transaction information and data stored on our application.
				<br />
				We do not sell, trade, or rent Users personal identification information to others. <br /> However, we
				may share general aggregated demographic information, not linked to any personally identifiable
				information, with our business partners and trusted affiliates for the purposes described above.
				<br />
				We strive to do our best to keep your information safe. <br /> Our top priority is guarding your data,
				especially in case of a breach of our security occurs beyond our reasonable control.
				<br />
				We will keep your personal information for as long as your account is active. <br /> You may at any
				time delete your account or instruct us to delete it, but you should be aware that it is not
				technically possible to remove every record of information you have provided to DomeCare from
				our servers. <br /> DomeCare allows you to correct, update or revise the information you have provided
				by returning to the selected tool, logging in, and making the required changes. <br /> We will also
				retain your personal information as necessary to comply with legal obligations, resolve disputes
				and enforce our agreements. <br />

				We may disclose personal information to third parties only:
				<br />
				To comply with legal requirements such as a law, regulation, search warrant, subpoena, or court
				order.
				<br />
				When we believe in good faith that disclosure is necessary to protect our rights, protect your
				safety or the safety of others, investigate fraud or respond to a government request, or in
				special cases, such as in response to a physical threat to you or others, to protect property or
				defend or assert legal rights.
				<br />
				We may share non-personally identifiable information we have about you, including information
				about your browsing activity on the application, they may use this information to help DomeCare
				to serve advertisements on the application.
				<br />
				In case of a change in control of the Company as a result of, for example, a sale or merger with
				another entity, or in case of a sale of assets or bankruptcy, DomeCare reserves the right to
				transfer your personal information to the new controlling or acquiring party of the assets. <br /> In
				the event of such a change, your personal information will continue to be treated in accordance
				with this Privacy Policy unless any changes are made to the Privacy Policy.
				<br />
				<br />
				<h4>Changes to this Privacy Policy</h4>

				The application's privacy policies and terms and conditions may be changed or updated from time
				to time to meet the latest regulatory requirements and standards without prior notice. <br /> By
				continuing to use our site, users agree to accept our privacy policies and terms and conditions,
				as amended from time to time.
				<br /> Therefore, users are encouraged to visit these sections frequently in order to be updated
				about changes on the website.
				<br />
				<br />

				<h4>Accept these terms</h4>

				By using this application, users indicate their acceptance of this policy. <br /> We reserve the right
				to change and/or modify any of the provisions set forth herein without prior notice to any user. <br />
				Continued use of the application after any modifications, shall be deemed the users' acceptance
				of such changes.
				<br />
			</p>
		),
	},
	{
		id: 6,
		title: 'Availability and termination of our services',

		desc: (
			<p>
				<br />
				<h4>Availability of our services:</h4>

				We always try to improve our services. <br /> This means that we may extend, add or remove our
				services, features, functionality, and support for certain devices and platforms. <br /> Our services
				may be interrupted, including maintenance, repair, upgrades, or network or equipment failures. <br />
				We may discontinue some or all of our Services, at any time. <br /> Events outside our control may
				affect our Services, such as events in nature and other force majeure events.
				<br />
				We may modify, suspend, or terminate your access to or use of our Services at any time for any
				reason, for example if you violate a provision or provision of our Terms or cause harm, risk, or
				potential legal exposure to us, our users, or others. <br /> We may also disable or delete your account
				if it no longer becomes active after the account is registered or if it has remained inactive
				for an extended period of time. <br />
				We may modify or update these Terms. <br /> We will provide you with notice of substantial
				modifications to our terms, as applicable, and update the "last modified" date at the top of our
				terms. <br /> Your continued use of our Services confirms your acceptance of our Terms, as amended. <br /> We
				hope that you will continue to use our Services, but if you do not agree to our Terms, as
				amended, you must stop using our Services by deleting your account.
				<br />
				Nothing in our Terms prevents us from complying with the law.
				<br />
				Except as mentioned herein, our Terms do not confer any third-party beneficiary rights.
				<br />
				If we fail to enforce any of our terms, it will not be considered a waiver.
				<br />
				If any provision of these Terms is found to be illegal, void or for any reason unenforceable,
				then that provision shall be in effect.
				<br />
				<br />

				<h4>Contact us</h4>

				If you have any questions about this Privacy Policy, or the practices or dealings with this our
				application, please contact us at +963-993130032
				<br />
			</p>
		),
	},
];
export const TermsOfUseDataAr = [
	{
		id: 1,
		title: 'Registration File',
		desc: (
			<p>
				<h4>حقوق المستخدم ومسؤولياته وآليات الحماية والخصوصيّة لمعلوماته</h4>
				<br />
				<h5>المستخدم العام </h5>

				عند تسجيل حساب يُطلب الاسم الكامل للمستخدم ورقم الهاتف المحمول وتاريخ الميلاد والجنس واسم
				المدينة والمنطقة الخاص بها بالإضافة الى معلومات الطول والوزن وفصيلة الدم. <br />
				نحتفظ بهذه المعلومات لضرورة الاتصال في المستقبل في حال تقديم شكوى , بالإضافة الى الميزات التي
				تلزم التأكد من هوية المستخدم في حال تقديم الاستشارة , أو حجز موعد أو , في حال الاتصال بالطبيب
				اوعند إضافة طبيب.
				<br />
				عند إضافة قريب إلى ملفك الشخصي نحتفظ بالمعلومات المدخلة.
				<br />

				المعلومات التي قمت بإدخالها تتبع لسياسة الخصوصية ولا يمكن لأي شخص الاطلاع عليها إلا بموافقتك.
				<br />

				يمكنك تغيير خصوصية أي معلومة أو سجل طبي خاص بك.
				<br />

				الاستشارة الخاصة بك سرية ولا يمكن لأي شخص معرفة هويتك إلا في حال تمت الموافقة على الاستشارة من
				قبل طبيب , عندها سيصبح الطبيب على إطلاع على معلوماتك.
				<br />
				يتيح التطبيق إمكانية إضافة الأطباء إلى ملفك الشخصي ليتمكنوا من إضافة السجلات الطبية الخاصة بك
				ولتتمكن من الحجز حسب جدول مواعيد الطبيب ويتم إعلام المستخدم عند الموافقة على طلبه.
				<br />
				في حال حجز الموعد أكثر من مرة وعدم إلغائه يحق للتطبيق حظر هذه الميزة عن المستخدم.
				<br />
				لا يمكن البحث عن الدواء ذاته أكثر من مرة في نفس الوقت وسيمنع المخدم المستخدم من البحث.
				<br />

				في حال إرسال الكثير من الطلبات يحق للتطبيق حظر المستخدم.
				<br />
				<br />


				<h5>المستخدم المهني</h5>

				عند تسجيل حساب يُطلب الاسم الكامل للمستخدم ورقم الهاتف المحمول وتاريخ الميلاد والجنس واسم
				المدينة والمنطقة الخاص بها بالإضافة الى شهادتك الطبية في حال كنت طبيب أو صيدلي أو مزود خدمة.
				<br />

				يطلب التطبيق توثيق حسابك كمستخدم مهني عن طريق رقم هاتفك المحمول نظراً لحساسية عملك ولأن التطبيق
				يتمتع بموثوقية عالية.
				<br />
				يمكنك إضافة معلومات المنشأة التي تمتلكها أو الانضمام إلى منشأة موجودة مع إضافة الخدمات التي
				تقدمها وإضافة جدول المواعيد الخاص بك.
				<br />

				يمكنك إضافة المرضى الخاصين بك وإضافة سجلاتهم الطبية في حال موافقة المريض على طلب الطبيب ويستطيع
				المريض التحكم بخصوصية سجله الطبي واختيار خاصية (انا فقط)، وفي حال عدم موافقة المريض على طلب
				الطبيب سيظهر السجل الطبي عند الطبيب الذي قام بإضافته فقط.
				<br />

				يمكن تفعيل أو إيقاف خاصية الدردشة.
				<br />

				يمكنك البحث عن الأدوية وعرض الدواء مع النشرة الدوائية الكترونية.
				<br />

				يمكنك الرد على الاستشارة الاون لاين.
				<br />

				في حال عدم استكمال معلومات توثيق الحساب يظهر الحساب لدى المستخدم العام على أنه "غير موثوق"
				بالنسبة للتطبيق.
				<br />

				في حال حجز الاستشارة وعدم الرد خلال ساعة سيقوم التطبيق بسحب الاستشارة واعادتها إلى الاستشارات
				غير المحجوزة.
				<br />

				في حال عدم التزام المستخدم بالحجوزات الموافق عليها من قبل الطبيب يمكن للتطبيق إيقاف ميزه
				الحجزللمستخدم.
				<br />

				يحق للطبيب عند التعرض للإساءة تقديم شكوى وعند التأكد منها يتم حظر المستخدم المسيء.
				<br />
				<br />

				<h5>المستخدم صاحب المنشأة</h5>

				عند تسجيل حساب يُطلب الاسم الكامل للمستخدم ورقم الهاتف المحمول واسم مدينة المنشأة والمنطقة
				الخاصة بها بالإضافة الى شهادة الترخيص.
				<br />

				يمكن للحساب إضافة العاملين في هذه المنشأة والخدمات التي تقدمها من خلال الموافقة على طلبات انضمام
				العاملين إليها ووضع صلاحيات خاصة بكل موظف وتفعيل خاصية الدردشة مع إمكانية إسناد الرد والمتابعة
				عند موظف خاص.
				<br />

				في حال كنت مالك مستودع أو الشخص المسؤول عن إدارته، يمكنك الموافقة على طلبات الصيدليات مع تحديد
				الكميات التي تمتلكها وتحديد الوقت المتوقع للتسليم بالإضافة إلى رؤية الطلبات السابقة.
				<br />

				في حال كنت مالك صيدلية أو الشخص المسؤول عن إدارتها يمكنك طلب أدوية من المستودعات وتحديد الكميات
				التي تريدها مع رؤية جميع تفاصيل الطلب وتاريخ الاستلام ورؤية الطلبات السابقة.
				<br />

				يمكن إضافة المنتجات الخاصة بالمنشأة.
				<br />

				يمكن للمستودع أو الشركة الدوائية إضافة النشرة الدوائية الخاصة بها وإدارة معلوماتها.
				<br />

				في حال عدم استلام الصيدلية طلبها من المستودعات لأكثر من 3 مرات يحق للتطبيق حظر هذه الميزة عن
				حساب الصيدلية.
				<br />

				في حال عدم تسليم المستودع الطلبات للصيدليات لأكثر من 3 مرات يحق للتطبيق حظر هذه الميزة عن حساب
				المستودع.
				<br />

				في حال الخطأ بإدخال أكثر من نشرة دوائية يحق للتطبيق عدم إظهار هذا الدواء ضمن البحث. <br />
				<br />


				<h4>حقوق الجهة المسؤولة عن التطبيق وواجباتها</h4>

				يحق للشركة في حال نشر أكثر من معلومة طبية خاطئة من قبل الطبيب إلى أي مستخدم، إيقاف حساب هذا
				الطبيب وحظر جميع السجلات الطبية التي قام برفعها مع إرسال تنبيه إلى جميع المستخدمين الذين قام هذا
				الطبيب بإرفاق سجلات طبية لهم.
				<br />

				يحق للتطبيق في حال حجز الطبيب لاستشارة أون لاين وعدم الرد عليها خلال مدة ساعة حظر هذه الميزة
				وتحويلها الى "الاستشارات الجديدة".
				<br />

				تتعهد الشركة المسؤولة بسرية جميع المعلومات المحفوظة وعدم الاطلاع على أي معلومة إلا بموافقة الشخص
				من خلال خصوصية كل معلومة.
				<br />
				<br />
				<h4>تعديل سياسة الاستخدام</h4>

				يحق للتطبيق تعديل سياسة استخدام التطبيق أو ميزة موجودة بحسب المعطيات التي تتطلب التعديل.
				<br />

			</p>
		),
	},
	{
		id: 2,
		title: 'Instructions for use',

		desc: (
			<p>
				تعليمات الاستخدام ملاحظة هامة أنت تقبل هذه الشروط من خلال إنشاء حساب DomeCare أو باستخدام
				الخدمات أو من خلال الاستمرار في استخدام الخدمات. <br /> يرجى قراءة وطباعة وحفظ نسخة من هذه الشروط في
				سجلاتك لأن DomeCare لن يحفظ نسخة لك.
				<br />
				شكرًا لك على استخدام خدمات DomeCare التي تقدمها Ayat Group.
				<br />
				في هذه الشروط والأحكام (المشار إليها فيما يلي باسم "الشروط") تشير كلمة "نحن" إلى شركة Ayat
				Group، وهي شركة خاصة محدودة المسؤولية تمارس الأعمال (التجارية، تطوير التطبيقات البرمجية مسجلة في
				(الإمارات بموجب الترخيص التجاري رقم 18338، وعنوانها في (المالكي – ساحة سيرياتل

				<br />
				<br />
				<h4>الأطراف</h4>

				يشير "الحساب العام" إلى أي شخص يستخدم التطبيق ولديه حساب مسجل في التطبيق، باستثناء مقدمي الرعا
				الصحية.
				<br />

				يشير "حساب الأعمال" إلى مقدمي الرعاية الصحية الذين لديهم حساب مسجل في التطبيق ويشير مصطلح
				"المستخدمون" إلى كليهما معًا. يُستخدم مصطلح "أنت" في هذه الشروط للإشارة إلى المستخدمين.
				<br />

				يشير "حساب المنشاة" الى مقدمي الرعاية الصحية او الاشخاص الذين يملكون منشآت ضمن القطاع الصحي
				ولديهم حساب مسجل في التطبيق  <br />

				يشير مصطلح "الخدمات" كما هو مستخدم في شروط استخدام DomeCare ("الشروط") إلى المنتجات والخدمات
				والتطبيقات والمحتوى وقواعد البيانات والتقنيات والأدوات التي يقدمها DomeCare.
				<br />
				يعد فهم هذه الشروط أمرًا مهمًا لأنه للاستفادة من خدماتنا يجب عليك قبول هذه الشروط. ووصولك إلى
				هذا التطبيق واستخدامه يشكل قبولًا منك لشروط الاستخدام هذه التي تسري من تاريخ الاستخدام الأول
				(يرجى الرجوع إلى سياسة الخصوصية الخاصة بنا في كيفية معالجتنا لبياناتك الشخصية).
				<br />
				يجوز لنا تعديل هذا العقد وسياسة الخصوصية وسياسة ملفات تعريف الارتباط الخاصة بنا من وقت لآخر. إذا
				أجرينا تغييرات عليها، وسنرسل لك إشعارًا من خلال التطبيق، أو بوسائل أخرى، لنوفر لك الفرصة لمراجعة
				التغييرات قبل أن تصبح سارية المفعول. نحن نتفق على أن التغييرات لا يمكن أن تكون بأثر رجعي. إذا
				كنت تعترض على أي تغييرات، فيمكنك إغلاق حسابك. إن استمرارك في الاستفادة خدماتنا بعد أن ننشر أو
				نرسل إشعارًا بشأن التغييرات التي أجريناها على هذه الشروط يعني ضمناً أنك توافق على الشروط المحدثة
				اعتبارًا من تاريخ نفاذها.
				<br />

				لا تستخدم المنصة أو الخدمات للاحتياجات الطبية الطارئة. إذا واجهت حالة طبية طارئة، فاتصل على
				الفور بخدمات الطوارئ المحلية.
				<br />
				<br />
				<h4>الأعضاء والزوار</h4>
				عندما تقوم بالتسجيل أو الانضمام إلى تطبيق DomeCare، فإنك تصبح عضوًا. إذا اخترت عدم التسجيل
				للاستفادة من خدماتنا، يمكنك الوصول إلى ميزات معينة كـ "زائر" فقط.
				<br />
				<br />
				<h4>تعريف بالتطبيق  </h4>

				مهمة DomeCare حصولك على أفضل رعاية صحية وتقديم حلول لجميع الاحتياجات الطبية من خلال ربط جميع
				مقدمي الرعاية الطبية ضمن القطاع الطبي في العالم وربط المستخدم العام بهم ضمن تطبيق واحد.
				<br />

				تم تصميم خدماتنا لإلهام مجتمعات أكثر صحية من خلال تمكين ملايين من مقدمي الرعاية الطبية الآخرين
				من التواصل وتبادل الخدمات والعثور على الموظفين والعمل وتنظيم واختزال الوقت لأداء المهام المتعلقة
				بأعمالهم والسماح لهم ليكونوا أكثر ايجابية وتقدما. ولضمان حياة صحية أفضل للمستخدم العام من خلال
				أرشفة سجلاته الطبية وسجلات عائلته وحجز المواعيد والبحث عن دواء.  <br />
				<br />
				<h4>	الفئات المستهدفة</h4>

				يستهدف DomeCare جميع مقدمي خدمات الرعاية الطبية وأصحاب المنشآت والعاملين ضمن القطاع الصحي
				والأشخاص المستفيدين من هذه الخدمات بمختلف الفئات العمرية (القصر تحت رعاية الأبوين او الوصي
				الشرعي).

				<br />
				<br />
				<h4>عمل التطبيق (ما الذي نفعله)</h4>
				يهدف التطبيق بشكل رئيسي إلى امتلاك مقدمي الرعاية الطبية في القطاع الطبي "حساب أعمال" يتمكنون من
				خلاله من الوصول إلى مرضاهم ومراقبتهم وتسجيل مواعيدهم مع امكانيه اضافه معلوماتهم وشهادتهم
				الطبية.
				<br />

				يمتلك التطبيق موثوقية عالية من خلال توثيق الحسابات وتأكيد صحة الشهادات وتأكيد الأرقام المسجلة
				فيه.
				<br />

				يستطيع "حساب الأعمال" و "حساب المنشأة" إدارة جدول دوام المنشآت الطبية التي يمتلكها أو التي يعمل
				بها مع إمكانية إضافة وعرض السجلات الطبية الخاصة بمرضاه عند الحاجة إليها.
				<br />

				يحق فقط للأطباء الحاصلين على شهادات علمية مختصة تقديم خدمة الاستشارة الطبية.
				<br />

				يتيح Dome Care لحساب الأعمال (للصيادلة) طلب الأدوية من المستودعات الحاصلة على ترخيص ويتيح
				للمستودع تحديد الكمية المتوفرة لديه أو رفض الطلب وعرض الطلبات القديمة.
				<br />

				يتيح التطبيق للحساب العام البحث عن الأدوية في الصيدليات ضمن موقعه الجغرافي.
				<br />

				يتيح التطبيق للمستخدمين البحث عن الدواء وعرض النشرة الدوائية بشكل الكتروني.
				<br />

				يتيح التطبيق لحساب المنشآت إدارة المعلومات الخاصة بها والموظفين المنضمين لها وإضافة الخدمات التي
				تقدمها وعرض المنتجات والعروض الخاصة بها وارتباطها بمنشأة ثانية مثل شركات التأمين أو المنشآت
				المتعلقة بها.
				<br />

				يتيح للمستخدمين البحث عن جميع المنشآت الطبية والأطباء.
				<br />

				يتيح للحساب العام إضافة الطبيب إلى حسابه الشخصي مع إمكانية التحكم بخصوصية معلوماته وملفاته.
				<br />

				يمكن للحساب العام إضافة ملفات شخصية لأقاربه لإدارة سجلاتهم الطبية.
				<br />

				يمكن للحساب العام التنبيه بمواعيد الدواء الخاصة بالمستخدم.
				<br />

				يمكن لحساب الأعمال أو حساب المنشاة تفعيل أو إيقاف الدردشة.

				<br />
			</p>
		),
	},
	{
		id: 3,
		title: 'Privacy Policy',

		desc: (
			<p>
				دوم كير يحترم ويلتزم بحماية خصوصيتك ومعلوماتك الشخصية.  <br />

				"المعلومات الشخصية" تعني أي معلومات عن فرد ما يمكن من خلالها التعرف على هذا الشخص.  <br />

				نحن نعمل كمراقب للمعلومات فيما يتعلق بأي معلومات شخصية تقدمها لنا، ولن نقوم بمعالجة ومشاركة
				بياناتك إلا بما يتماشى مع متطلبات قوانين حماية البيانات المعمول بها على النحو المنصوص عليه في
				هذه السياسة.
				<br />

				سنتخذ خطوات معقولة لضمان أن الأشخاص الذين نشارك معهم بياناتك بشكل شرعي يتمتعون بنفس القدر من
				القوة في نهجهم لحماية البيانات.
				<br />
				<br />


				<h4>جمع المعلومات واستخدامها</h4>
				يمكن للمستخدمين استخدام خدماتنا دون تقديم أي معلومات شخصية، ومع ذلك، من أجل استخدام خدمات معينة
				متاحة على تطبيق الويب أو للوصول إلى مناطق معينة من التطبيق، يجب توفير المعلومات الشخصية.
				<br />

				قد نجمع معلومات شخصية عنك - مثل اسمك ورقم هاتفك وعنوانك (البلد، المنطقة) ومعلوماتك الصحية -
				بالطرق التالية:
				<br />

				عندما تقوم بالتسجيل أو تحديث ملف الشخصي الموجود على تطبيقاتنا.
				<br />

				عند استخدام أدوات وتطبيقات وخدمات تفاعلية معينة.
				<br />

				سنستخدم المعلومات الشخصية لغرض الحفاظ على تسجيل المستخدم وإدارة محتوى التطبيق وتقديمه.
				<br />
				أنت مسؤول عن دقة المعلومات الشخصية التي ترسلها إلى دوم كير. ستؤثر المعلومات غير الدقيقة على
				تجربتك عند استخدام الموقع والأدوات وقدرتنا على الاتصال بك كما هو موضح في سياسة الخصوصية هذه.
				<br />
				إذا اخترت تزويدنا بالمعلومات المطلوبة، التي تم جمعها من خلال استخدام تطبيقنا وخدماته، فإنك توافق
				على استخدام ومعالجة وتخزين المعلومات الشخصية في البلدان التي قد يكون لديها قوانين حماية البيانات
				التي تختلف عن القوانين في بلدك.
				<br />

				نحن ملتزمون بحماية خصوصية الأطفال. لم يتم تصميم موقع الويب والتطبيق بغرض منه جذب الأطفال الذين
				تقل أعمارهم عن 18 عامًا. لا نقوم بجمع معلومات شخصية من أي شخص لدينا معرفة معينة به دون سن 18
				عامًا. ومع ذلك، قد يستخدم أحد الوالدين أو الوصي الموقع لإنشاء السجل الصحي الشخصي للقاصر. أحد
				الوالدين أو الوصي هو المسؤول الوحيد عن استخدام القاصر للتطبيق. يتحمل الوالدين أو الوصي المسؤولية
				الكاملة عن ضمان الحفاظ على أمان معلومات التسجيل وعن أن المعلومات المقدمة للتطبيق دقيقة وعن تفسير
				واستخدام أي معلومات أو اقتراحات من قبل القاصر.
				<br />
				إذا قمت بتنزيل تطبيق من Apple App Store أو متجر Google Play وتوافق على تقديم معلوماتك إلى تطبيق
				DomeCare، سيتم التعامل مع معلوماتك مثل معلومات المستخدم المسجل بموجب سياسة الخصوصية هذه.
				<br />
				تعتبر المعلومات الشخصية المتعلقة بالصحة الطبية التي يقدمها مختصي الرعاية الصحية أثناء استشارات
				الطبية والسجلات الصحية وجلسات الدردشة مع المرضى سرية من الناحية القانونية ويتم تأمينها بواسطة
				خدماتنا المشفرة. ومع ذلك، فإننا نحتفظ بالحق في مراجعة التواصل بين الأطراف التي تتم على موقعنا من
				وقت لآخر وفقًا لشروط الاستخدام وسياسة الخصوصية الخاصة بنا. في حال تم اختراق أحد الطرفين لسياسة
				وخصوصية الاستخدام.
				<br />
				إذا اخترت استخدام نظام الدردشة النصية التفاعلية الخاص بنا إما على جهاز الكمبيوتر الشخصي أو
				الهاتف المحمول أو أي جهاز آخر، فأنت توافق على جمع وتجميع أي معلومات يتم إرسالها عبر نظام
				الدردشة، بما في ذلك المعلومات الطبية والصحية الشخصية سواء تم الكشف عنها في النموذج من النص أو
				الصوت أو أي شكل آخر (ملحق ملفات اخرى).
				<br />
				قد نستخدم معلومات الاتصال الخاصة بك من وقت لآخر لإعلامك بمعلومات حول تطبيقنا وخدماته. يتلقى
				المستخدمون رسائل نصية لتسجيل الحساب عند التسجيل، وسيتلقى جميع المستخدمين رسائل عبر البريد
				الالكتروني عن التحديثات والتغييرات التي تم إجراؤها على حساباتهم والميزات والسياسات الجديدة؛
				تعتبر هذه الاتصالات جزءًا لا يتجزأ من الخدمات الموجودة على موقع الويب ولا يمكنك إلغاء الاشتراك
				فيها.
				<br />
				قد تتصل خدمة العملاء والإدارات الأخرى بالمستخدمين ومقدمي الرعاية من أجل ضمان رضاك عن الشركة
				والتطبيق، وجمع ملاحظاتك أو للسؤال عن كيفية تحسين خدماتنا.
				<br />
				(حساب عام) إذا كنت ترغب في أي وقت في تحديث معلوماتك الشخصية، يمكنك القيام بذلك عن طريق تسجيل
				الدخول إلى حسابك للوصول إلى صفحة ملفك لشخصي.  <br />
			</p>
		),
	},
	{
		id: 4,
		title: 'Acceptable use',
		desc: (
			<p>
				<br />
				<h5>الاستخدام المقبول</h5>

				في هذا التطبيق، سيُطلب منك إنشاء حساب، وبناءً عليه ستنشئ اسم مستخدم وكلمة مرور وغيرها من معلومات
				الوصول. سنطبق معايير السرية القياسية لتأمين هذه المعلومات، ومع ذلك، تقع على عاتق المستخدم
				مسؤولية الحفاظ على أمان هذه المعلومات وعدم الكشف عنها لأطراف ثالثة على الموقع الإلكتروني أو غير
				ذلك. لذلك نحن لا نتحمل المسؤولية ولن نتحمل المسؤولية عن إساءة استخدام حسابك بأي شكل من الأشكال
				بسبب إفصاحك عن تفاصيل حسابك ووصول معلومات إلى أطراف ثالثة.
				<br />
				في حال فقدان معلومات الوصول إلى حسابك أو سرقتها أو الكشف عنها بأي طريقة، يجب عليك تغيير كلمة
				المرور من خلال ميزة إعادة تعيين كلمة مرور عبر تطبيقنا أو إخطارنا على الفور.
				<br />
				يقر المستخدمون ويقبلون أن حساباتهم على التطبيق غير قابلة للتحويل أو استخدامها من قبل أي شخص آخر
				غير الشخص الذي أنشأ الحساب.
				<br />
				يوافق المستخدمون على عدم استخدام والمشاركة في أي من الأنشطة التالية فيما يتعلق باستخدامك
				للخدمات:
				<br />
				محاولة الوصول إلى التطبيق أو البحث فيه أو أي محتوى وارد فيه من خلال استخدام أي محرك أو برنامج أو
				أداة أو وكيل أو جهاز أو آلية (بما في ذلك الروبوتات أو أدوات استخراج البيانات أو ما شابه) بخلاف
				من خلال البرامج المتاحة بشكل عام من خلال متصفحات الويب.
				<br />
				انتحال شخصية أو تحريف ارتباطك بشخص أو كيان آخر.
				<br />
				حصاد أو جمع معلومات عن الآخرين، بما في ذلك عناوين التواصل الخاص بالمستخدم (البريد الالكتروني…).
				<br />
				التدخل في أو تعطيل أي من الخدمات أو الكمبيوتر المرتبط أو أنظمة التسليم الفنية. <br />
				التدخل في أو محاولة التدخل في وصول أي مستخدم أو مضيف أو شبكة، بما في ذلك، على سبيل المثال لا
				الحصر، إرسال فيروس أو تحميل زائد أو إرسال بريد عشوائي … <br />
				عدم احترام خصوصية مستخدم آخر. يتضمن ذلك الكشف عن كلمة مرور مستخدم آخر ورقم هاتفه أو العنوان أو
				أي معلومات تعريف شخصية أخرى أو استخدم التطبيق أو الخدمات أو المحتوى بأي طريقة لا تسمح بها هذه
				الشروط.
				<br />
				يوافق المستخدمون على الاستخدام القانوني والمقبول. يجب عليك الوصول إلى خدماتنا واستخدامها للأغراض
				القانونية والمصرح بها والمقبولة فقط. لن تستخدم (أو تساعد الآخرين في استخدام) خدماتنا بطرق: (أ)
				تنتهك مستخدمينا أو غيرهم ، بما في ذلك الخصوصية أو الدعاية أو الملكية الفكرية أو حقوق الملكية
				الأخرى ؛ (ب) غير قانوني ، أو فاحش ، أو تشهيري ، أو تهديد ، أو تخويف ، أو مضايقة ، أو بغيض ، أو
				مسيء عنصريًا أو عرقيًا ، أو يحرض أو يشجع على سلوك قد يكون غير قانوني أو غير لائق بأي شكل من
				الأشكال ، مثل الترويج لجرائم العنف ، أو تعريض الأطفال أو غيرهم للخطر أو استغلالهم ، أو تنسيق
				الضرر (ج) تنطوي على نشر أكاذيب أو تحريفات أو بيانات مضللة ؛ (د) انتحال شخصية شخص ما ؛ (هـ) تنطوي
				على إرسال اتصالات غير قانونية أو غير مسموح بها مثل الرسائل الجماعية والرسائل التلقائية والاتصال
				التلقائي وما شابه ذلك ؛ أو (و) تتضمن أي استخدام غير شخصي لخدماتنا ما لم نسمح بخلاف ذلك من قبلنا.
				<br />
				الاضرار بـ Ayat Group و\أو مستخدمينا. لا يجوز لك (أو مساعدة الآخرين على) بشكل مباشر أو غير مباشر
				، من خلال وسائل آلية أو غيرها ، أو الوصول أو الاستخدام أو النسخ أو التكييف أو التعديل أو إعداد
				الأعمال المشتقة او نشر معلومات او مواد على تطبيقنا بما في ذلك بوابات الاتصال على الانترنت تكون
				فاحشة او تشهيرية او مهددة او خبيثة بطبيعتها او معلومات محظورة بموجب القانون بناءً على أو توزيع
				أو ترخيص أو ترخيص من الباطن أو نقل أو عرض أو أداء أو استغلال أو استغلال خدماتنا بأسلوب غير مسموح
				به أو غير مصرح به ، أو بطرق تؤدي إلى العبء أو الإضرار بنا أو إلحاق الضرر بنا أو خدماتنا أو
				أنظمتنا أو مستخدمينا أو غيرهم ، بما في ذلك أنه لا يجب عليك بشكل مباشر أو من خلال وسائل آلية: (أ)
				الهندسة العكسية أو التغيير أو تعديل أو إنشاء أعمال مشتقة من خدماتنا أو إلغاء ترجمتها أو
				استخراجها ؛ (ب) إرسال أو تخزين أو نقل فيروسات أو أكواد كمبيوتر ضارة أخرى من خلال خدماتنا أو
				عبرها ؛ (ج) اكتساب أو محاولة الوصول غير المصرح به إلى خدماتنا أو أنظمتنا ؛ (د) التدخل في أو
				تعطيل سلامة خدماتنا أو أمنها أو سريتها أو تكاملها أو توفرها أو أدائها ؛ (هـ) إنشاء حسابات
				لخدماتنا من خلال وسائل غير مصرح بها أو آلية ؛ (و) جمع معلومات عن مستخدمينا أو عنهم بأي طريقة غير
				مسموح بها أو غير مصرح بها ؛ (ز) بيع أو إعادة بيع أو تأجير أو فرض رسوم على خدماتنا أو البيانات
				التي تم الحصول عليها منا أو من خدماتنا بطريقة غير مصرح بها ؛ (ح) توزيع أو إتاحة خدماتنا عبر شبكة
				حيث يمكن استخدامها بواسطة أجهزة متعددة في نفس الوقت ، باستثناء ما هو مصرح به من خلال الأدوات
				التي قدمناها صراحةً عبر خدماتنا ؛ (ط) إنشاء برامج أو واجهات برمجة التطبيقات التي تعمل إلى حد
				كبير مثل خدماتنا وعرضها للاستخدام من قبل أطراف ثالثة بطريقة غير مصرح بها ؛ أو (ي) إساءة استخدام
				أي من قنوات الإبلاغ ، مثل إرسال تقارير أو طعون احتيالية أو لا أساس لها. كما يقر المستخدمون
				ويقبلون بان حساباتهم على التطبيق غير قابلة للتحويل ولا يجوز ترخيصها كما يوافق المستخدمون على عدم
				استخدام الموقع باي طريقة تسبب في اتلاف او إعاقة أداء التطبيق.
				<br />
				نحن نمتلك جميع حقوق النشر والعلامات التجارية والمجالات والشعارات والمظهر التجاري والأسرار
				التجارية وبراءات الاختراع وحقوق الملكية الفكرية الأخرى المرتبطة بخدماتنا. لا يجوز لك استخدام
				حقوق النشر والعلامات التجارية (أو أي علامات مماثلة) والمجالات والشعارات والمظهر التجاري والأسرار
				التجارية وبراءات الاختراع وحقوق الملكية الفكرية الأخرى الخاصة بنا ما لم تحصل على إذن صريح منا
				وبما يتوافق مع إرشادات علامتنا التجارية. لا يجوز لك استخدام العلامات التجارية للشركات التابعة
				لنا إلا بإذن منها، بما في ذلك ما هو مصرح به في أي إرشادات خاص بالعلامة التجارية المنشورة.
				<br />
				الإبلاغ عن انتهاك حقوق الطبع والنشر والعلامة التجارية والملكية الفكرية DomeCare لطرف ثالث وعدم
				نشر أي مواد محمية بحقوق الطبع والنشر او مواد محمية بأي حقوق ملكية فكرية أخرى ما لم يتم الحصول
				على حق قانوني لاستخدام هذه المواد ونشرها ويكون ساريا في وقت النشر. للإبلاغ عن مطالبات انتهاك
				حقوق الطبع والنشر أو العلامات التجارية أو أي انتهاك آخر للملكية الفكرية من جهة خارجية، يرجى
				زيارة سياسة الملكية الفكرية الخاصة بنا. قد نتخذ إجراءً فيما يتعلق بحسابك، بما في ذلك تعطيل حسابك
				أو تعليقه، إذا انتهكت حقوق الملكية الفكرية للآخرين بشكل واضح أو خطير أو متكرر أو عندما يُطلب منا
				القيام بذلك لأسباب قانونية او عند محاولة التحايل على أي اجراء تقني وقائي مرتبط بالخدمات سيتم
				تعطيل أو تعليق حسابك وفقًا لقسم "الإنهاء" أدناه.
				<br />
				نحن ملتزمون بحماية خصوصية الأطفال. يجب أن تدرك أن هذا التطبيق ليس مخصصًا أو مصممًا لجذب الأطفال
				الذين تقل أعمارهم عن 18 عامًا. نحن لا نجمع معلومات التعريف الشخصية من أي شخص نعرف أنه طفل دون سن
				18 عامًا. في حال التحدث مع مريض يقل عمره عن 18 عامًا دون إشراف الوالدين أو الوصي القانوني، يجب
				عليهم إبلاغنا بذلك على الفور باستخدام عنوان البريد الإلكتروني: info@ayat-group.com . إذا كان
				أخصائي الرعاية الصحية مقصورًا في تزويدنا بهذا الإشعار فسيتم اعتبار كل من أخصائي الرعاية الصحية
				والمريض منتهكين لشروطنا.
				<br />
				يجب على المستخدمين التأكد من دقة وصحة أي وجميع المعلومات التي يقدمونها لنا من خلال التطبيق.
				<br />
				يتحمل المستخدمين المسؤولية عن جودة أي معلومات مقدمة إلينا بأي شكل، سواء كانت نصية أو صوتية أو
				كصور. لن نتحمل المسؤولية عن أي سوء تفسير للمعلومات بسبب الصور أو الصوت غير الواضح أو النص المصاغ
				بشكل غامض او جميع أنواع الملحقات.
				<br />
				يجب أن تتم جميع التفاعلات بين المستخدمين بطريقة مسؤولة وأخلاقية ومهنية وقانونية.
				<br />
				يجوز لنا (لكننا لسنا ملزمين) القيام بأي مما يلي أو كله دون إشعار:
				<br />
				التحقيق في استخدامك للخدمات بالشكل الذي نراه مناسبًا للامتثال لأي قانون أو لائحة أو طلب حكومي أو
				إجراء قانوني معمول به.
				<br />
				إزالة أي محتوى مستخدم نعتقد أنه لا يتوافق مع شروط الاستخدام هذه.
				<br />
				إنهاء وصولك إلى التطبيق بناءً على قرارنا بأنك انتهكت شروط الاستخدام هذه. <br />
			</p>
		),
	},
	{
		id: 5,
		title: 'Safety',
		desc: (
			<p>
				نعتمد معايير جمع البيانات وتخزينها وممارسات المعالجة والتدابير الأمنية للحماية من الوصول غير
				المصرح به أو التعديل أو الكشف أو إتلاف المعلومات الشخصية للمستخدم واسم المستخدم وكلمة المرور
				ومعلومات المعاملات والبيانات المخزنة على موقعنا.
				<br />
				نحن لا نبيع أو نتاجر أو نؤجر معلومات الهوية الشخصية للمستخدمين للآخرين. ومع ذلك، يجوز لنا مشاركة
				المعلومات الديموغرافية المجمعة العامة، غير المرتبطة بأي معلومات تعريف شخصية، مع شركائنا في العمل
				والشركات التابعة الموثوقة للأغراض الموضحة أعلاه.
				<br />
				نحن نسعى جاهدين لبذل قصارى جهدنا للحفاظ على أمان معلوماتك. فأمان بياناتك هو أولويتنا الأولى
				خصوصا في حالة حدوث خرق خارج عن سيطرتنا المعقولة.
				<br />
				سنحتفظ بمعلوماتك الشخصية طالما أن حسابك نشط. يمكنك في أي وقت حذف حسابك أو توجيهنا لحذفه، ولكن
				يجب أن تدرك أنه ليس من الممكن تقنيًا إزالة كل سجل من المعلومات التي قدمتها إلى تطبيق DomeCare من
				خوادمنا. تطبيق DomeCare تتيح لك تصحيح المعلومات التي قدمتها أو تحديثها أو مراجعتها من خلال
				الرجوع إلى الأداة المحددة وتسجيل الدخول وإجراء التغييرات المطلوبة. سنحتفظ أيضًا بمعلوماتك
				الشخصية عند الضرورة للامتثال للالتزامات القانونية وحل النزاعات وإنفاذ اتفاقياتنا.
				<br />
				يجوز لنا الإفصاح عن المعلومات الشخصية لأطراف ثالثة فقط في الحالات الآتية:
				<br />
				للامتثال للمتطلبات القانونية مثل قانون أو لائحة أو أمر تفتيش أو أمر استدعاء أو أمر محكمة.
				<br />
				عندما نعتقد بحسن نية أن الإفصاح ضروري لحماية حقوقنا أو حماية سلامتك أو سلامة الآخرين أو التحقيق
				في الاحتيال أو الاستجابة لطلب حكومي، أوفي حالات خاصة، مثل الرد على تهديد جسدي لك أو للآخرين،
				لحماية الممتلكات أو الدفاع عن الحقوق القانونية أو تأكيدها.
				<br />
				قد نشارك المعلومات غير الشخصية التي لدينا عنك، بما في ذلك معلومات حول نشاط التصفح الخاص بك على
				موقع الويب، قد يستخدمون هذه المعلومات لمساعدة تطبيق DomeCare لتقديم الإعلانات على التطبيق
				<br />
				في حالة حدوث تغيير في ملكية الشركة نتيجة، على سبيل المثال، البيع أو الاندماج مع كيان آخر، أو في
				حالة بيع الأصول أو الإفلاس، فإن دوم كير تحتفظ لنفسها بالحق في نقل معلوماتك الشخصية إلى الطرف
				الجديد المسيطر أو الطرف المستحوذ على الأصول. في حالة حدوث مثل هذا التغيير، سيستمر التعامل مع
				معلوماتك الشخصية وفقًا لسياسة الخصوصية هذه ما لم يتم إجراء أي تغييرات على سياسة الخصوصية.
				<br />
				<br />
				<h4>التغييرات في سياسة الخصوصية هذه</h4>

				قد يتم تغيير سياسات الخصوصية والشروط والأحكام الخاصة بموقع الويب أو تحديثها من حين لآخر لتلبية
				أحدث المتطلبات والمعايير التنظيمية دون إشعار مسبق. من خلال الاستخدام المستمر لموقعنا، يوافق
				المستخدمون على قبول سياسات الخصوصية والشروط والأحكام الخاصة بنا، بصيغتها المعدلة من وقت لآخر.
				لذلك، يتم تشجيع المستخدمين على زيارة هذه الأقسام بشكل متكرر حتى يتم تحديثهم بشأن التغييرات على
				موقع الويب.
				<br />
				<br />
				<h4>				قبول هذه الشروط
				</h4>

				باستخدام هذا الموقع، يشير المستخدمون إلى قبولهم لهذه السياسة. نحن نحتفظ بالحق في تغيير و / أو
				تعديل و / أو تنسيق أي من الأحكام المنصوص عليها هنا دون إشعار مسبق لأي مستخدم. يعتبر استمرار
				استخدام موقع الويب بعد أي تعديلات و / أو تعديلات و / أو تعديلات بمثابة موافقة المستخدمين على هذه
				التغييرات.
				<br />
			</p>
		),
	},
	{
		id: 6,
		title: 'Availability and termination of our services',

		desc: (
			<p>
				<br />
				<h4>				توافر خدماتنا:
				</h4>
				نحاول دائماُ تحسين خدماتنا. هذا يعني أنه يجوز لنا توسيع أو إضافة أو إزالة خدماتنا
				وميزاتنا ووظائفنا ودعم بعض الأجهزة والأنظمة الأساسية. قد تنقطع خدماتنا، بما في ذلك الصيانة أو
				الإصلاح أو الترقيات أو أعطال الشبكة أو المعدات. يجوز لنا إيقاف بعض أو كل خدماتنا، في أي وقت. قد
				تؤثر الأحداث الخارجة عن سيطرتنا على خدماتنا، مثل الأحداث في الطبيعة وغيرها من أحداث القوة
				القاهرة.
				<br />
				يجوز لنا تعديل أو تعليق أو إنهاء وصولك إلى خدماتنا أو استخدامها في أي وقت ولأي سبب، على سبيل
				المثال إذا انتهكت نصًا أو بندا من شروطنا أو تسببت في ضرر أو مخاطرة أو تعرض قانوني محتمل لنا أو
				لمستخدمينا أو الآخرين. يجوز لنا أيضًا تعطيل حسابك أو حذفه إذا لم يصبح نشطًا بعد تسجيل الحساب أو
				إذا ظل غير نشط لفترة طويلة من الوقت. ستظل البنود التالية سارية بعد إنهاء علاقتك ب.
				<br />
				DomeCare يجوز لنا تعديل أو تحديث هذه الشروط. سنقدم لك إشعاراً بالتعديلات الجوهرية على شروطنا،
				حسب الاقتضاء، ونقوم بتحديث تاريخ "آخر تعديل" في الجزء العلوي من شروطنا. إن استمرارك في استخدام
				خدماتنا يؤكد موافقتك على شروطنا، بصيغتها المعدلة.
				<br />
				نأمل أن تستمر في استخدام خدماتنا، ولكن إذا كنت لا توافق على شروطنا، بصيغتها المعدلة، فيجب عليك
				التوقف عن استخدام خدماتنا عن طريق حذف حسابك.
				<br />
				جميع حقوقنا والتزاماتنا بموجب شروطنا قابلة للتنازل عنها بحرية إلى أي من الشركات التابعة لنا أو
				فيما يتعلق بدمج الأصول أو الاستحواذ عليها أو إعادة هيكلتها أو بيعها أو بموجب القانون أو غير ذلك،
				وقد ننقل معلوماتك إلى أي من الشركات التابعة أو الكيانات الخلف أو المالك الجديد.
				<br />
				في حالة هذا التنازل، ستستمر هذه الشروط في تنظيم علاقتك مع هذا الطرف الثالث. نأمل أن تستمر في
				استخدام خدماتنا، ولكن إذا كنت لا توافق على هذا التعيين، فيجب عليك التوقف عن استخدام خدماتنا عن
				طريق حذف حسابك بعد إخطارك بالتنازل.
				<br />
				لا يوجد في شروطنا ما يمنعنا من الامتثال للقانون.
				<br />
				باستثناء ما هو منصوص عليه هنا، لا تمنح شروطنا أي حقوق مستفيدة لطرف ثالث.
				<br />
				إذا فشلنا في تنفيذ أي من شروطنا المنصوصة، فلن يعتبر ذلك تنازلاً عما هو مذكور من حقوق.
				<br />
				إذا تبين أن أي بند من هذه الشروط غير قانوني أو باطل أو غير قابل للتنفيذ لأي سبب من الأسباب،
				فعندئذٍ يكون هذا الشرط ساريا.
				<br />
				<br />
				<h4>الاتصال بنا</h4>

				إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه، أو ممارسات أو تعاملات مع هذا الموقع أو التطبيق،
				يرجى الاتصال بنا على الرقم +963-993130032 <br />
			</p>
		),
	},
];
export const TermsOfUsePanelTabs = [
	{
		id: 1,
		title: 'Registration File',
		icon: 'assets/RegistrationFile.svg',
	},
	{
		id: 2,
		title: 'Instructions for use',
		icon: 'assets/Instructionsforuse.svg',
	},
	{
		id: 3,
		title: 'Privacy Policy',
		icon: 'assets/PrivacyPolicy.svg',
	},
	{
		id: 4,
		title: 'Acceptable use',
		icon: 'assets/Acceptableuse.svg',
	},
	{
		id: 5,
		title: 'Safety',
		icon: 'assets/Safety.svg',
	},
	{
		id: 6,
		title: 'Availability and termination of our services',
		icon: 'assets/Availability.svg',
	},
];
