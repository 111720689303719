import React from "react";
import "./OurApp.scss";
import OurAppServices from "../../sections/OurAppServices/OurAppServices";
import LayoutFooter from "../../layout/LayoutFooter/LayoutFooter";
import Scroller from "../../components/Scroller/Scroller";

const OurApp = (props) => {
  return (
    <section className="our-app-section">
      <Scroller />
      <OurAppServices />
      <LayoutFooter />
    </section>
  );
};

export default OurApp;
