import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./i18n";
import { ContextInfoProvider } from "./context/ContextInfoProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <ContextInfoProvider>
          <App />
        </ContextInfoProvider>
      </BrowserRouter>
  </React.StrictMode>
);
