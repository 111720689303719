import React from "react";
import "./Home.scss";
import BriefAbout from "../../sections/BriefAbout/BriefAbout";
import HomeArticles from "../../sections/HomeArticles/HomeArticles";
import LayoutFooter from "../../layout/LayoutFooter/LayoutFooter";
import Scroller from "../../components/Scroller/Scroller";
const Home = () => {
  return (
    <section className="home-section">
      <Scroller />
      <BriefAbout />
      <HomeArticles />
      <LayoutFooter />
    </section>
  );
};

export default Home;
