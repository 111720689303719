export const AboutUsTabsList = [
  {
    id: 1,
    title: "WhoWeAre",
  },
  {
    id: 2,
    title: "Mission",
  },
  {
    id: 3,
    title: "Vision",
  },
  {
    id: 4,
    title: "OurValue",
  },

];
export const AboutUsSectionDataList = [
  {
    id: 1,
    title: "WhoWeAre",
    desc: "WhoWeAre",
    imgEN: "assets/AboutImg1.webp",
    imgAR: "assets/AboutImg1AR.webp",

  },
  {
    id: 2,
    title: "Mission",
    desc: "Mission",
    imgEN: "assets/AboutImg2.webp",
    imgAR: "assets/AboutImg2AR.webp",
  },
  {
    id: 3,
    title: "Vision",
    desc: "Vision",
    imgEN: "assets/AboutImg3.webp",
    imgAR: "assets/AboutImg3AR.webp",
  },
  {
    id: 4,
    title: "OurValue",
    desc: "OurValue",
    imgEN: "assets/AboutImg4.webp",
    imgAR: "assets/AboutImg4AR.webp",
  },
];
