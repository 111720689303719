import React, { useState, useEffect } from "react";
import "./Ads.scss";
import { AdsDataEn } from "../../Data/AdsData";
import { AdsDataAr } from "../../Data/AdsData";
import { useTranslation } from "react-i18next";
const Ads = (props) =>
{
  const [ currentAdIndex, setCurrentAdIndex ] = useState(0);
  const { i18n: { language } } = useTranslation("locales");

  const adsData = language === "ar" ? AdsDataAr : AdsDataEn;

  useEffect(() =>
  {
    const changeAd = () =>
    {
      const nextAdIndex = (currentAdIndex + 1) % adsData.length;
      setCurrentAdIndex(nextAdIndex);
    };
    const interval = setInterval(changeAd, 3000);

    return () => clearInterval(interval);
  }, [ currentAdIndex ]);
  useEffect(() =>
  {
    setCurrentAdIndex(Math.floor(Math.random() * adsData.length));
  }, []);
  return (
    <div
      className={`${"ads-container"
        }`}>
      <img
        className="ad-img"
        src={`assets/AddsLong/${adsData[ currentAdIndex ]}.webp`}
        alt="ad"
      />
    </div>
  );
};

export default Ads;
