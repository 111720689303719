import { useState } from "react";
import { createContext } from "react";
const Context = createContext();

const ContextInfoProvider = ({ children }) => {
  const [chosenArticle, setChosenArticle] = useState(null);
  const [isReachedTheBottomState, setIsReachedTheBottomState] = useState(false);
  const [isReachedTheTopState, setIsReachedTheTopState] = useState(false);
  const [showBlurBackGround, setShowBlurBackGround] = useState(false);
  // const [scrollMode, setScrollMode] = useState("");
  const [VerticalSwiperContainerHeight, setVerticalSwiperContainerHeight] =
    useState(false);
  const scrollableFlag= true;
  const ContextValue = {
    chosenArticle,
    setChosenArticle,
    setShowBlurBackGround,
    showBlurBackGround,
    scrollableFlag,
    isReachedTheBottomState,
    setIsReachedTheBottomState,
    isReachedTheTopState,
    setIsReachedTheTopState,
    setVerticalSwiperContainerHeight,
    VerticalSwiperContainerHeight,
  };
  return <Context.Provider value={ContextValue}>{children}</Context.Provider>;
};
export { Context, ContextInfoProvider };
