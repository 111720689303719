import React from "react";
import "./AboutUsTabDetail.scss";
import { useTranslation } from "react-i18next";
const AboutUsTabDetail = (props) => {
  const { t,i18n } = useTranslation("common");

  return (
    <article
      className="about-us-tab-detail"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <div className="about-us-tab-detail-info">
        <h2>{t(`Pages.AboutUs.AboutUsSection.Menu.${props.title}`)}</h2>
        <p className="r-p">{t(`Pages.AboutUs.AboutUsSection.MenuItemDesc.${props.desc}`)}</p>
      </div>
      <div className="about-us-tab-detail-img">
        <img src={i18n.language === "ar" ? props.imgAR : props.imgEN  } alt={props.title} id={props.title} />
      </div>
    </article>
  );
};

export default AboutUsTabDetail;
