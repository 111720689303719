import React from "react";
import "./Footer.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleGoToDomeCare } from "../../functions/functions";
const Footer = () => {
  const { t } = useTranslation("common");
  const Year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer-links-container">
        <ul className="footer-list">
          <li>
            <NavLink className={"r-p"} to="/faq">
              {t(`Layout.FooterLayout.Footer.Menu.FAQ`)}
            </NavLink>
          </li>
          <li>
            <NavLink className={"r-p"} to="/terms-of-use">
              {t(`Layout.FooterLayout.Footer.Menu.TermsOfUse`)}
            </NavLink>
          </li>

          <li>
            <div className={"r-p"} onClick={handleGoToDomeCare}>
              {t(`Layout.FooterLayout.Footer.Menu.Careers`)}
            </div>
          </li>
        </ul>
      </div>
      <div className="footer-copyright d-inline-block">
        © {Year} {t(`Layout.FooterLayout.Footer.CopyRight`)}
      </div>
    </footer>
  );
};

export default Footer;
