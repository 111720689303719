import React from "react";
import "./HomeArticleHeadLineCard.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../context/ContextInfoProvider";
import MoreButton from "../MoreButton/MoreButton";
const ArticleHeadLineCard = ({
  imgAR,
  imgEN,
  descAR,
  descEN,
  isActive,
  index,
}) => {
  const { i18n } = useTranslation("common");
  const navigate = useNavigate();
  const { setChosenArticle } = useContext(Context);
  const handleChosenArticle = () => {
    setChosenArticle({ imgAR, imgEN, descAR, descEN, isActive, index });
    navigate("/learn-more");
  };

  return (
    <article
      className={`article-headline-card 
      `}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      onClick={handleChosenArticle}
    >
      <img
        src={i18n.language === "ar" ? imgAR : imgEN}
        alt="IMG"
        id="cardImg"
      />
      <div className="article-headline-card-info">
        <p className="article-headline-desc">
          {i18n.language === "ar" ? descAR : descEN}
        </p>
        <MoreButton />
      </div>
    </article>
  );
};

export default ArticleHeadLineCard;
