import React from 'react'
import { useTranslation } from "react-i18next";
const MoreButton = () => {

    const { t, i18n } = useTranslation("common");

  return (
    <p className="article-headline-more-btn-container">
    <span>{t(`Pages.Home.HomeArticles.HomeArticleHeadLineCard`)}</span>
    <div>
      <img
        src={
          i18n.language === "ar"
          ? "assets/arrowLeftBlue.svg"
          : "assets/ArrowRight.svg"
        }
        alt="ToRightArrow"
      />
    </div>
  </p>
  )
}

export default MoreButton