import React from "react";
import "./MobileAppCard.scss";

const MobileAppCard = (props) => {
  const handleNavigateToStore = () => {
    window.open(props.card.downloadLink);
  };
  return (
    <article
      className="mobile-app-card"
      onClick={() => handleNavigateToStore()}
    >
      <img src={props.card.img} alt={props.card.title} />
      <div className="mobile-app-card-info">
        <p className="mobile-app-card-desc r-s-p">{props.card.desc}</p>
        <p className="mobile-app-card-title r-s-p">{props.card.title}</p>
      </div>
    </article>
  );
};

export default MobileAppCard;
