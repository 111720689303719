import React, { useContext } from "react";
import "./LearnMoreArticleHeadLineCard.scss";
import { useTranslation } from "react-i18next";
import { Context } from "../../context/ContextInfoProvider";
const LearnMoreArticleHeadLineCard = ({ descEN, descAR  }) =>
{
  const { scrollableFlag } = useContext(Context);
  const { t, i18n } = useTranslation("common");


  return (
    <article
      className={`learn-more-article-headline-card-container  ${scrollableFlag && "scrollable"
        }`}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
  
    >
      {i18n.language === "ar" ? descAR : descEN}

      <div className="learn-more-article-headline-card-footer">
        <span>
          {t(`Pages.LearnMore.LearnMoreArticles.LearnMoreArticleCardNote`)}
        </span>

        <img
          src={
            i18n.language === "ar"
              ? "assets/arrowLeftBlue.svg"
              : "assets/ArrowRight.svg"
          }
          alt="Icon"
        />
      </div>
    </article>
  );
};

export default LearnMoreArticleHeadLineCard;
