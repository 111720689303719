import React from "react";
import "./TermsOfUseContentCard.scss";
import { useRef } from "react";
import { useContext } from "react";
import { Context } from "../../../context/ContextInfoProvider";
import { useTranslation } from "react-i18next";

const TermsOfUseContentCard = ({ term }) => {
  const { t } = useTranslation("common");
  const TermsOfUseContentCardRef = useRef(null);
  const { scrollableFlag } = useContext(Context);
  const handleArticlesContentCardsDivWheel = (e) => {
    const div = TermsOfUseContentCardRef.current;
    const delta = e.deltaY;
    const scrollSpeed = 4;
    div.scrollBy({
      top: delta * scrollSpeed,
      behavior: "smooth",
    });
  };
  return (
    <article
      className={`terms-of-use-content-card-wrapper  ${
        scrollableFlag && "scrollable"
      }`}
    >
      <div
        className="terms-of-use-content-card"
        ref={TermsOfUseContentCardRef}
        onWheel={handleArticlesContentCardsDivWheel}
      >
        <div className="terms-of-use-content-card-header" key={term?.id}>
          <p className="terms-of-use-content-card-title">
            {t(`Pages.TermsOfUse.TermsOfUseItemsTitles.${term?.title}`)}
          </p>
          <p
            className={`terms-of-use-content-card-desc r-p ${
              scrollableFlag && "scrollable"
            }`}
          >
            {term.desc}
            {/* {t(`Pages.TermsOfUse.TermsOfUseListItems.${term?.title}`)} */}
          </p>
        </div>
      </div>
      <img
        className="gradient-effect"
        src={"assets/gradient.webp"}
        alt="gradient"
      />
    </article>
  );
};

export default TermsOfUseContentCard;
