import React, { useContext } from "react";
import "./FAQQuestionsSection.scss";
import { Accordion } from "react-bootstrap";
import Title from "../../components/Title/Title";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { faqDataArray } from "../../Data/FAQData";
import { Context } from "../../context/ContextInfoProvider";
import Ads from "../../components/Ads/Ads";
import { useMediaQuery } from "react-responsive";
import Pagination from "../../components/Pagination/Pagination";
import { useEffect } from "react";
const FAQAccordionSection = () => {
  const [generalFAQDataState] = useState(faqDataArray);
  const [evenDataState, setEvenDataState] = useState([]);
  const [oddDataState, setOddDataState] = useState([]);

  useEffect(() => {
    const evenData = generalFAQDataState.filter((item) => item.id % 2 === 0);
    const oddData = generalFAQDataState.filter((item) => item.id % 2 !== 0);
    setEvenDataState(evenData);
    setOddDataState(oddData);
  }, [generalFAQDataState]);

  const { showBlurBackGround } = useContext(Context);
  const isSmallLabScreen = useMediaQuery({ query: "(max-width: 1280px)" });
  const isTabletScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobileScreen = useMediaQuery({ query: "(max-width: 575px)" });
  const { t, i18n } = useTranslation("common");
  //? ================================Pagination=========================
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(4);
  //   const cardsPerPage = 4;
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setActiveKey(null);
  };
  const [activeKey, setActiveKey] = useState(null);
  const handleAccordionToggle = (cardKey) => {
    setActiveKey((prevActiveKey) =>
      prevActiveKey === cardKey ? null : cardKey
    );
  };
  useEffect(() => {
    if (isSmallLabScreen) {
      setCardsPerPage(4);
    }
    if (isTabletScreen) {
      setCardsPerPage(6);
    }
  }, [isSmallLabScreen, isTabletScreen]);
  return (
    <section
      className="faq-questions-section"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      id="section-1"
    >
      {showBlurBackGround && <div className="blurEffectCard" />}
      <div className="faq-questions-section-content-wrapper">
        <div className="faq-section-title">
          <Title title={t(`Pages.FAQ.FAQAccordionSection.Title`)} size="big" />
        </div>
        <div className="faq-pagination-section">
          <Pagination
            handlePageChange={handlePageChange}
            readMode={false}
            currentPage={currentPage}
            cardsPerPage={cardsPerPage}
            articles={oddDataState}
          />
        </div>

        <div className="faq-questions-section-content">
          <div className="accordion-1">
            <Accordion
              activeKey={activeKey}
              className="faq-accordion"
              onSelect={handleAccordionToggle}
            >
              <div className="faq-card-wrapper">
                {oddDataState
                  .slice(
                    (currentPage - 1) * cardsPerPage,
                    currentPage * cardsPerPage
                  )
                  .map((question, index) => {
                    return (
                      <Accordion.Item
                        eventKey={question.id}
                        className="faq-accordion-card"
                        key={index}
                      >
                        <Accordion.Header className="faq-accordion-card-header">
                          <img
                            src="assets/QuestiomMark.svg"
                            alt="QuestiomMark"
                            id="QuestiomMark"
                          />
                          <span className="r-p">
                            {t(
                              `Pages.FAQ.FAQ-Questions-List.Question-${question.id}.Title`
                            )}
                          </span>
                          <span className="faq-accordion-card-open ">
                            {activeKey === question.id ? (
                              <img src="assets/minus.svg" alt="minus" />
                            ) : (
                              <img src="assets/plus.svg" alt="plus" />
                            )}
                          </span>
                        </Accordion.Header>
                        <Accordion.Body className="r-p">
                          {t(
                            `Pages.FAQ.FAQ-Questions-List.Question-${question.id}.Answer`
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </div>
            </Accordion>
          </div>
          <div className="accordion-2">
            <Accordion
              activeKey={activeKey}
              className="faq-accordion"
              onSelect={handleAccordionToggle}
            >
              <div className="faq-card-wrapper">
                {evenDataState
                  .slice(
                    (currentPage - 1) * cardsPerPage,
                    currentPage * cardsPerPage
                  )
                  .map((question, index) => {
                    return (
                      <Accordion.Item
                        eventKey={question.id}
                        className="faq-accordion-card"
                        key={index}
                      >
                        <Accordion.Header className="faq-accordion-card-header ">
                          <img
                            src="assets/QuestiomMark.svg"
                            alt="QuestiomMark"
                            id="QuestiomMark"
                          />
                          <span className="r-p">
                            {t(
                              `Pages.FAQ.FAQ-Questions-List.Question-${question.id}.Title`
                            )}
                          </span>
                          <span className="faq-accordion-card-open">
                            {activeKey === question.id ? (
                              <img src="assets/minus.svg" alt="minus" />
                            ) : (
                              <img src="assets/plus.svg" alt="plus" />
                            )}
                          </span>
                        </Accordion.Header>
                        <Accordion.Body className="r-p">
                          {t(
                            `Pages.FAQ.FAQ-Questions-List.Question-${question.id}.Answer`
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </div>
            </Accordion>
          </div>
        </div>
      </div>
      {!isMobileScreen && !isTabletScreen && (
        <div className="faq-ads-cards-wrapper">
          <Ads  />
          
        </div>
      )}
    </section>
  );
};

export default FAQAccordionSection;
