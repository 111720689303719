import React, { useContext, useEffect, useState, useRef } from "react";
import "./BriefAbout.scss";
import HomeServiceCard from "../../components/HomeServiceCard/HomeServiceCard";
import { BriefCardsData } from "../../Data/BriefCards";

import "swiper/css";
import "swiper/css/pagination";

import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { Context } from "../../context/ContextInfoProvider";
const BriefAbout = () => {
  const { t, i18n } = useTranslation("common");
  const { showBlurBackGround } = useContext(Context);
  const [briefServices, setBriefServices] = useState(BriefCardsData);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1200px)" });
  const [activeArrowNext, setActiveArrowNext] = useState(false);
  const [activeArrowPrev, setActiveArrowPrev] = useState(false);
  const ServicesCardsContainerRef = useRef(null);

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng")
  );
  const handleScrollPrev = () => {
    setActiveArrowNext(true);
    const divElement = ServicesCardsContainerRef.current;
    if (divElement) {
      const scrollPosition = divElement.scrollLeft;
      //   const totalScrollableWidth = divElement.scrollWidth - divElement.clientWidth;
      currentLanguage === "en"
        ? (divElement.scrollLeft -= 200)
        : (divElement.scrollLeft += 200);
      if (scrollPosition - 200 <= 0) {
        setActiveArrowPrev(false);
      }
    }
  };

  const handleScrollNext = () => {
    setActiveArrowPrev(true);
    const divElement = ServicesCardsContainerRef.current;
    if (divElement) {
      const scrollPosition = Math.abs(divElement.scrollLeft);
      const totalScrollableWidth =
        divElement.scrollWidth - divElement.clientWidth;
      currentLanguage === "en"
        ? (divElement.scrollLeft += 200)
        : (divElement.scrollLeft -= 200);

      if (scrollPosition + 200 >= totalScrollableWidth) {
        setActiveArrowNext(false);
      }
    }
  };

  const handleCardsScrolling = () => {
    const divElement = ServicesCardsContainerRef.current;
    if (divElement) {
      const scrollPosition = Math.abs(divElement.scrollLeft);
      const totalScrollableWidth =
        divElement.scrollWidth - divElement.clientWidth;
      if (scrollPosition === 0) {
        setActiveArrowPrev(false);
        setActiveArrowNext(true);
      } else if (scrollPosition === totalScrollableWidth) {
        setActiveArrowNext(false);
        setActiveArrowPrev(true);
      } else {
        setActiveArrowNext(true);
        setActiveArrowPrev(true);
      }
    }
  };

  useEffect(() => {
    const divElement = ServicesCardsContainerRef.current;
    if (divElement) {
      setActiveArrowNext(divElement.scrollWidth > divElement.clientWidth);
    }
  }, []);

  return (
    <section
      className={`brief-section ${i18n.language}`}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      id="section-1"
    >
      <section className="brief-about-section">
        {showBlurBackGround && <div className="blurEffectCard" />}

        <div className="brief-about-section-info">
          <div className="brief-about-section-info-title">
            <h2
              className={`brief-about-section-info-title-label ${
                isSmallScreen && "small-screen"
              }`}
            >
              {t(`Pages.Home.BriefAbout.BriefTitle`)}{" "}
            </h2>

            <p className={`brief-about-section-info-desc r-p ${i18n.language}`}>
              {t(`Pages.Home.BriefAbout.BriefDesc`)}
            </p>
          </div>

          <img
            className="brief-about-section-img"
            src="assets/DoctorAvatarImg.webp"
            alt="DoctorImg"
          />
        </div>
        <div className="services-section">
          <div
            className={`title-with-pagination ${
              isSmallScreen && "small-screen"
            }`}
          >
            <h2 className={`centered-title ${isSmallScreen && "small-screen"}`}>
              {t("Pages.Home.BriefAbout.ServicesTitle")}
            </h2>
            {isSmallScreen && (
              <div className="pagination-arrows">
                <div
                  className={`pagination-arrow-left arrow left-arrow ${
                    activeArrowPrev === false && "disabled"
                  }`}
                  onClick={() => handleScrollPrev()}
                >
                  {activeArrowPrev ? (
                    <img src="/assets/ArrowLeft.svg" alt="ToRightArrow" />
                  ) : (
                    <img
                      src="/assets/ArrowunactiveLeft.svg"
                      alt="ToRightArrow"
                    />
                  )}
                </div>
                <div
                  className={`pagination-arrow-right learn arrow right-arrow ${
                    activeArrowNext === false && "disabled"
                  }`}
                  onClick={() => handleScrollNext()}
                >
                  {activeArrowNext ? (
                    <img src="/assets/ArrowRight-1.svg" alt="ToRightArrow" />
                  ) : (
                    <img
                      src="/assets/ArrowunactiveRight.svg"
                      alt="ToRightArrow"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            ref={ServicesCardsContainerRef}
            onScroll={handleCardsScrolling}
            className={`our-services-cards-container ${
              isSmallScreen && "small-screen"
            }  `}
          >
            {briefServices.map((service, index) => {
              return <HomeServiceCard key={index} service={service} />;
            })}
          </div>
        </div>
      </section>
    </section>
  );
};

export default BriefAbout;
