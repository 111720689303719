import React, { useContext } from "react";
import "./OurAppServices.scss";
import OurAppServicesTabPanel from "../../components/OurAppServicesTabPanel/OurAppServicesTabPanel";
import { Context } from "../../context/ContextInfoProvider";
// import Ads from "../../components/Ads/Ads";
// import { useMediaQuery } from "react-responsive";
const OurAppServices = () => {
  const { showBlurBackGround } = useContext(Context);
  const styleDirection = localStorage.getItem('i18nextLng');

  // const isMobileScreen = useMediaQuery({ maxWidth: "575px" });
  return (
    <section className={`our-app-servies-section ${styleDirection}`} id="section-1">
      {showBlurBackGround && <div className="blurEffectCard" />}
      <OurAppServicesTabPanel />
      {/* {!isMobileScreen && <Ads />} */}
    </section>
  );
};

export default OurAppServices;
