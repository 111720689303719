import { isIOS, isAndroid, isMobile } from 'react-device-detect';
import * as Constant from '../Shared/Services/constant';

export const get_absolute_lenght_string = (_string, count) => {
  if (_string != null) {
    let res = _string;
    if (_string.length > count) {
      res = _string.substr(0, count) + "...";
    }
    return res;
  } else {
    return "";
  }
};
export const breakpoints = {
  320: {
    slidesPerView: 1,
  },
  480: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 2,
  },
  1024: {
    slidesPerView: 3,
  },
  1280: {
    slidesPerView: 4,
  },
  1920: {
    slidesPerView: 4,
  },
};

export const handleGoToDomeCare = () => {
	if (isMobile) {
		if (isIOS) {
			window.open(Constant.appStoreApplicationLink, "_blank");
		}
		else if (isAndroid) {
			window.open(Constant.googlePlayApplicationLink, "_blank");
		}
	} else {
		window.open(Constant.webApplicationLink, "_blank");
	}
};