export const tutorialsSData = [
  {
    titleAr: "كيف أقوم بإضافة دواء جديد على تطبيق دوم كير؟",
    titleEn: "How to add a new drug on DomeCare app?",
    urlEn: "https://youtu.be/BJhgmwakCRs?si=RpO8UT_SO0BQzzvQ",
    urlAr: "https://youtu.be/SSX2oNihAzE?si=yKaGfgLXVR06kTEM",
  },
  {
    titleAr: "كيف أقوم بالتسجيل كحساب منشأة على تطبيق دوم كير ؟",
    titleEn: "How to register as facility on DomeCare app?",
    urlEn: "https://youtu.be/yH4jIDY0dJc?si=Yzvlx8vRz6AcSf3K",
    urlAr: "https://youtu.be/NA0eH-UFIzw?si=KIoSJWMio-AnziLc",
  },
  {
    titleAr: "كيف أقوم بالتسجيل كحساب أعمال على تطبيق دوم كير ؟",
    titleEn: "How to register as business account on DomeCare app?",
    urlEn: "https://youtu.be/AOjOi2B_xOY?si=IvhkeVvmrFTrLNdL",
    urlAr: "https://youtu.be/R5EnWsXDJn8?si=ooiWW6Fuxe3vPLSC",
  },
  {
    titleAr: "كيف أقوم بإنشاء وإدارة حساب ﻷقربائي على تطبيق دوم كير؟",
    titleEn: "How to create and manage my relatives account on DomeCare app?",
    urlEn: "https://youtu.be/m4yqYL8_8BI?si=qsFTugIwXXUHPoEb",
    urlAr: "https://youtu.be/o580Mikfgzs?si=SPWXN7yQJp7kBYfG",
  },
  {
    titleAr: "كيف أقوم بإنشاء سجل صحي جديد على تطبيق دوم كير؟",
    titleEn: "How to create a new medical record on DomeCare app?",
    urlEn: "https://youtu.be/jvf0o0tDWrg?si=ykVwh8f8e9q6NlDP",
    urlAr: "https://youtu.be/57yBt5QwsYc?si=KOjDMePtqO9lL5YK",
  },
  {
    titleAr: "كيف أقوم بالتسجيل كحساب عام على تطبيق دوم كير؟",
    titleEn: "How to register as normal on DomeCare app?",
    urlEn: "https://youtu.be/DGpo37I_FjE?si=6t8Hva7S4XbSrDH4",
    urlAr: "https://youtu.be/pa0nYBLO6qY?si=HADNpkUobt3HAPiW",
  },
  {
    titleAr: "كيف أتواصل مع الطبيب على تطبيق دوم كير؟",
    titleEn: "How to add your schedule on DomeCare app?",
    urlEn: "https://youtu.be/2vjmlL2Wtpk?si=r-_BYo4dEW-e7xK7",
    urlAr: "https://youtu.be/yjQM8hYCxmE?si=5Yr-lTnyCgLIc_-k",
  },
  {
    titleAr: "كيف أبحث عن الدواء على تطبيق دوم كير؟",
    titleEn: "How to appointment reservation on DomeCare app?",
    urlEn: "https://youtu.be/uRFtxTol1_k?si=ABOTvdppvHI2cTdK",
    urlAr: "https://youtu.be/SxrnG6Z9HZg?si=xtwXqz3y50w5Run8",
  },
  {
    titleAr: "كيف أقوم بإضافة مراجعة على السجل المرضي في تطبيق دوم كير؟",
    titleEn: "How to add your workplace on DomeCare app?",
    urlEn: "https://youtu.be/BvgXtuSRP-M?si=m-u7Yw6TniJZczUy",
    urlAr: "https://youtu.be/2crjyZ8Le6g?si=HC0tiamzJLpBDx9y",
  },
  {
    titleAr: "كيف أقوم بإضافة مكان عملي على تطبيق دوم كير؟",
    titleEn: "How to add a new service to your facility on DomeCare app?",
    urlEn: "https://youtu.be/HS29RJZ4I6w?si=pNGjq0YsnNEDk3LY",
    urlAr: "https://youtu.be/oargtAxoNBo?si=-2_F7qqxjbDqgfz-",
  },
  {
    titleAr: "كيف أقوم بإضافة موعد جديد للمرضى على تطبيق دوم كير؟",
    titleEn: "How to add review to the medical record on DomeCare app?",
    urlEn: "https://youtu.be/S5FKgCqTtIg?si=VmODenvu1lpgfcM8",
    urlAr: "https://youtu.be/dr-LgNaXjJ0?si=giIy5kFNlIcKPeHi",
  },
  {
    titleAr: "كيف أقوم بإضافة جدول المهام الخاص بي على تطبيق دوم كير؟",
    titleEn: "How to search for drug on DomeCare app?",
    urlEn: "https://youtu.be/Cna4P8qYpp0?si=zdbLz4uZDRtZI8zZ",
    urlAr: "https://youtu.be/gLRXgJFcoOQ?si=mWWPrCZ0FSkAmOqP",
  },
  {
    titleAr: "كيف أقوم بحجز موعد لدى طبيب في أحد مراكز التجميل على تطبيق دوم كير؟",
    titleEn: "How to book an appointment with a doctor at a beauty center on DomeCare app?",
    urlEn: "https://youtu.be/W-VCTdPtyTI?si=mD6kWqK5fNQ5WkVC",
    urlAr: "https://youtu.be/BlIvpoG_oow?si=-IV0HFgU4Va9ivXG",
  },
  {
    titleAr: "كيف أقوم بإضافة خدمة جديدة للمنشأة الخاصة بي على تطبيق دوم كير؟",
    titleEn: "How can I contact the doctor on DomeCare app?",
    urlEn: "https://youtu.be/HQCU_sirzoc?si=qsNijHFU7dN5vP18",
    urlAr: "https://youtu.be/51-VBH5BTIQ?si=qG7jckG8Gh6YO5n7",
  }]