export const BriefCardsData = [
  {
    Id: 1,
    icon: "assets/Digitalhealthcareguide-1.svg",
    title: "Digitalhealthcareguide",
  
  },
  {
    Id: 2,
    icon: "assets/Onlineconsultations-1.svg",
    title: "Onlineconsultations",
  
  },
  {
    Id: 3,
    icon: "assets/SearchforDrugs-1.svg",
    title: "SearchforDrugs",
  },
  {
    Id: 4,
    icon: "assets/UnifiedHealthFile-1.svg",
    title: "UnifiedHealthFile",
  },
  {
    Id: 5,
    icon: "assets/healthcareprovider-1.svg",
    title: "Healthcareproviderrequest", 
  },
];
