import React from "react";
import "./Error404.scss";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
const Error404 = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/");
  };
  return (
    <section className="error-page-section">
      <div className="error-page-wrapper">
        <div className="error-page-wrapper-section1">
          <img src="assets/1.webp" alt="ErrorPageImg" />
          <Button
            title="Go Back"
            type="button"
            Func={() => goHome()}
            className="primary"
          />
        </div>
        <div className="error-page-wrapper-section2">
          <img src="assets/2.webp" alt="ErrorPageImg" />
        </div>
      </div>
    </section>
  );
};

export default Error404;
