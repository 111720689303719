import { Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Layout from "./layout/Layout";
import Home from "./pages/Home/Home";
import OurApp from "./pages/OurApp/OurApp";
import LearnMore from "./pages/LearnMore/LearnMore";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Error404 from "./pages/Error404/Error404";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FAQ from "./pages/FAQ/FAQ";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import { Context } from "./context/ContextInfoProvider";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import SearchResult from "./sections/SearchResult/SearchResult";
import * as Api from "./Shared/Services/api.js";
import * as Constant from "./Shared/Services/constant.js";
function App()
{
  const { pathname } = useLocation();
  const [ isRouteChanged, setIsRouteChanged ] = useState(false);
  const { setIsReachedTheBottomState, setIsReachedTheTopState } =
    useContext(Context);
  const { i18n } = useTranslation("locales");
  const isMobileScreen = useMediaQuery({ maxWidth: "992px" });
  const handleScroll = () =>
  {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;
    if (scrollTop === 0) {
      setIsReachedTheTopState(true);
    } else {
      setIsReachedTheTopState(false);
    }
    if (!isRouteChanged) {
      if (scrollTop + windowHeight >= documentHeight - 100) {
        setIsReachedTheBottomState(true);
      } else {
        setIsReachedTheBottomState(false);
      }
    } else {
      setIsReachedTheBottomState(false);
    }
  };
  useEffect(() =>
  {
    document.dir = i18n.language === "ar" ? "rtl" : "ltr";
    window.addEventListener("scroll", handleScroll);

    return () =>
    {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ i18n.language, isRouteChanged, setIsReachedTheBottomState ]);

  const ScrollToTop = () =>
  {
    if (isRouteChanged) {
      setIsRouteChanged(false);
      window.scrollTo(0, 0);
      setIsReachedTheTopState(true);
    }
    return null;
  };
  useEffect(() =>
  {
    setIsRouteChanged(true);
    setIsReachedTheTopState(true);
  }, [ pathname ]);
  useEffect(() =>
  {
    const values = {
      CountryId: 1,
    };
   Constant.websiteSearch &&  Api.getToken(values, function (response) {
      localStorage.setItem("Token", response.Data);
    });
  }, []);
  useEffect(() =>
  {
    const handleWheel = (e) =>
    {
      if (!isMobileScreen) {
        e.preventDefault();
      }
    };
    window.addEventListener("wheel", handleWheel, { passive: false });
    return () =>
    {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);
  return (
    <>
      {isRouteChanged && <ScrollToTop />}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/our-app" element={<OurApp />} />
          <Route path="/learn-more" element={<LearnMore />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/search-result" element={<SearchResult />} />
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default App;
