import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "../sections/Header/Header";
import { useTranslation } from "react-i18next";

const Layout = () => {
  const { i18n } = useTranslation("common");
  const styleDirection = localStorage.getItem("i18nextLng");

  return (
    <main
      className={`position-relative App ${styleDirection}`}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <Container fluid className="p-0">
        <Header />
        <Outlet />
      </Container>
    </main>
  );
};

export default Layout;
