import React from 'react';
import './Pagination.scss';
import { useTranslation } from 'react-i18next';
const Pagination = ({ currentPage, articles, cardsPerPage, handlePageChange, readMode, articlesLength }) => {
	const { t } = useTranslation('common');

	return (
		<>
			{!readMode ? (
				<div className='pagination-section'>
					<p>
						{t(`Pages.LearnMore.LearnMoreArticles.Pagination.page`)} {currentPage}{' '}
						{t(`Pages.LearnMore.LearnMoreArticles.Pagination.from`)}{' '}
						{Math.ceil((articles?.length || articlesLength) / cardsPerPage)}
					</p>
					{
						<div className='pagination-arrow-container'>
							{currentPage === 1 ? (
								<img
									src='assets/ArrowunactiveLeft.svg'
									className='h-pagination-arrow'
									alt='Icon'
								/>
							) : (
								<img
									src='assets/ArrowLeft.svg'
									alt='Icon'
									className='h-pagination-arrow'
									onClick={() => {
										handlePageChange(currentPage - 1);
									}}
								/>
							)}

							{currentPage === Math.ceil((articles?.length || articlesLength) / cardsPerPage) ? (
								<img
									src='assets/ArrowunactiveRight.svg'
									className='h-pagination-arrow'
									alt='Icon'
								/>
							) : (
								<img
									className='h-pagination-arrow'
									src='assets/ArrowRight-1.svg'
									onClick={() => handlePageChange(currentPage + 1)}
									alt='Icon'
								/>
							)}
						</div>
					}
				</div>
			) : (
				<div className='pagination-section-read-mode'>
					<div className='pagination-section-read-mode-note'>
						<p>{t('Pages.LearnMore.LearnMoreArticles.other')}</p>
					</div>

					<div className='pagination-arrow-container'>
						{currentPage === 1 ? (
							<img className='h-pagination-arrow' src='assets/UpArrowD.svg' alt='Icon' />
						) : (
							<img
								className='h-pagination-arrow'
								onClick={() => {
									handlePageChange(currentPage - 1);
								}}
								src='assets/UpArrowA.svg'
								alt='Icon'
							/>
						)}
						{currentPage === Math.ceil((articles?.length || articlesLength) / cardsPerPage) ? (
							<img className='h-pagination-arrow' src='assets/DownArrowD.svg' alt='Icon' />
						) : (
							<img
								className='h-pagination-arrow'
								onClick={() => handlePageChange(currentPage + 1)}
								src='assets/DownArrowA.svg'
								alt='Icon'
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Pagination;
