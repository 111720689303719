import React from 'react';
import './ContactForm.scss';
import { Form } from 'react-bootstrap';
import Button from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMediaQuery } from 'react-responsive';
import MobileAppCard from '../MobileAppCard/MobileAppCard';
import * as Constant from '../../Shared/Services/constant';
const ContactForm = () =>
{
	


	const { t, i18n } = useTranslation('common');
	const [captchaValue, setCaptchaValue] = useState(false);
	const [MobileCardData, setMobileCardData] = useState([
		{
			id: 1,
			desc: t(`Pages.ContactUs.ContactInfo.AppleCard.Desc`),
			title: t(`Pages.ContactUs.ContactInfo.AppleCard.Title`),
			img: 'assets/applestore.svg',
			downloadLink: Constant.appStoreApplicationLink,
		},
		{
			id: 2,
			desc: t(`Pages.ContactUs.ContactInfo.GoogleCard.Desc`),
			title: t(`Pages.ContactUs.ContactInfo.GoogleCard.Title`),
			img: 'assets/playstore.svg',
			downloadLink: Constant.googlePlayApplicationLink,
		},
	]);
	const [formValues, setFormValues] = useState({ email: '', message: '' });
	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
	};
	const isMobileScreen = useMediaQuery({ maxWidth: '575px' });
	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (captchaValue) {
			// Your form submission logic here
		} else {
			alert('Please complete the CAPTCHA.');
		}
	};

	return (
		<>
			<div className='contact-form' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
				<div className='contact-form-container'>
					<Form className='form' onSubmit={handleFormSubmit}>
						<div className='contact-form-inputs'>
							<Form.Group controlId='formBasicEmail'>
								<Form.Control
									type='email'
									placeholder={t(
										`Pages.ContactUs.ContactForm.EmailInputPlaceholder`
									)}
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group
								controlId='exampleForm.ControlTextarea1'
								className='textarea-wrapper'>
								<Form.Control
									as='textarea'
									placeholder={t(
										`Pages.ContactUs.ContactForm.MessageInputPlaceholder`
									)}
									rows={3}
									onChange={handleChange}
								/>
							</Form.Group>
						</div>

						{/* Add reCAPTCHA component */}
						<ReCAPTCHA
							sitekey={Constant.reCaptchaSiteKey}
							hl={i18n.language}
							onChange={(value) => setCaptchaValue(value)}
						/>

						<Button
							title={t(`Pages.ContactUs.ContactForm.SendBtn`)}
							className='primary'
							type='submit'
						/>
					</Form>
				</div>
			</div>
			{isMobileScreen && (
				<>
					<div className='contact-info-msg'>
						<p className='r-p'>{t(`Pages.ContactUs.ContactInfo.Msg`)}</p>
					</div>
					<div className='contact-info-app-cards-container-mobile'>
						{MobileCardData.map((card, index) => {
							return <MobileAppCard key={index} card={card} />;
						})}
					</div>
				</>
			)}
		</>
	);
};

export default ContactForm;
